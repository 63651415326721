import styled from '@emotion/styled';
import { Flex, Grid, Td, Tr } from '@chakra-ui/react';
import {
  themeColorPicker,
  themeValue,
} from '../../shared/utils/themeSelectors';

export const StyledActionsRow = styled(Flex)`
  align-items: center;
  grid-gap: 0.5rem;
  justify-content: flex-end;
`;

export const StyledTr = styled(Tr)<{ isDeleted: boolean }>`
  opacity: ${({ isDeleted }) => (isDeleted && 0.5) || 1};
`;

export const StyledRowWithDescription = styled(Td)`
  max-width: 15rem;

  p:last-of-type {
    font-size: ${themeValue('fontSizes')('xs')};
    color: ${themeColorPicker('gray05')};
  }
`;

export const StyledFiltersWrapper = styled(Grid)`
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr auto 1fr auto;

  @media (max-width: ${themeValue('media')('m')}) {
    grid-template-columns: 1fr;

    > div:nth-of-type(2) {
      display: none;
    }
  }
`;

export const StyledNamesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  @media (max-width: ${themeValue('media')('m')}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledSwitchesWrapper = styled.div`
  display: flex;
  grid-gap: 2rem;
  margin-top: 1rem;
`;

export const StyledLimitFormsWrapper = styled.div`
  margin-top: 1rem;
  border: 1px solid ${themeColorPicker('gray04')};
  border-radius: ${themeValue('radii')('sm')};
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`;

export const StyledLimitsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  align-items: center;
`;
