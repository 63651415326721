import React from 'react';
import { useParams } from 'react-router-dom';
import { companiesApi } from '../../store/services/CompaniesService';
import MainLayout from '../../shared/components/MainLayout';
import CompanyAccordion from './CompanyAccordion';
import CompanyData from './CompanyData';
import { Flex, Spinner } from '@chakra-ui/react';
import { deliveriesApi } from '../../store/services/DeliveriesService';
import { DESC } from '../../shared/constants';
import CompanyBilling from './CompanyBilling';
import CompanyTariffPlan from './CompanyTariffPlan';
import CompanyAttachedRoles from './CompanyAttachedRoles';

const Company: React.FC = () => {
  const { companyId } = useParams();
  const { data } = companiesApi.useGetCompanyByIdQuery({
    companyId: companyId || '',
  });
  const { data: deliveriesData } = deliveriesApi.useGetAllDeliveriesQuery({
    sorting: 'id',
    order: DESC,
    limit: 0,
    offset: 0,
    companyId,
  });

  return (
    <MainLayout>
      {(data?.company && (
        <>
          <CompanyData company={data?.company} />
          <CompanyBilling company={data.company} />
          <CompanyTariffPlan company={data.company} />
          <CompanyAttachedRoles company={data.company} />
          <CompanyAccordion
            companyInvitations={data?.company?.companyInvitations || []}
            companyProfiles={data?.company?.companyProfiles || []}
            companyDeliveries={deliveriesData?.deliveries || []}
          />
        </>
      )) || (
        <Flex w={'100%'} justifyContent={'center'}>
          <Spinner size="xl" />
        </Flex>
      )}
    </MainLayout>
  );
};

export default Company;
