export enum TariffPlanLimitType {
  PROJECTS = 1,
  COMPANY_PROFILES = 2,
  DELIVERIES = 3,
  MATERIALS_AMOUNT = 4,
  ATTACHMENTS_AMOUNT = 5,
  MEGABYTES_TOTAL = 6,
  PDF_DELIVERIES = 7,
  LEGACY_DELIVERIES = 8,
  DASHBOARD = 9,
  RIGHTS_EXPIRATION_REMINDER = 10,
  ATTACHMENTS_MEGABYTES = 11,
  SHARED_DRAFT_COLLABORATION = 12,
  DELIVERY_TEMPLATES = 13,
  PRIORITIZED_CUSTOMER_SUPPORT = 14,
  PRIORITIZED_FEATURE_REQUESTS = 15,
}

export const SORTING_CREATED_AT = 'creation_date';

export const defaultPlans = [
  {
    type: TariffPlanLimitType.PROJECTS,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.COMPANY_PROFILES,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.DELIVERIES,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.MATERIALS_AMOUNT,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.ATTACHMENTS_AMOUNT,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.MEGABYTES_TOTAL,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.PDF_DELIVERIES,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.LEGACY_DELIVERIES,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.DASHBOARD,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.RIGHTS_EXPIRATION_REMINDER,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.ATTACHMENTS_MEGABYTES,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.SHARED_DRAFT_COLLABORATION,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.DELIVERY_TEMPLATES,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.PRIORITIZED_CUSTOMER_SUPPORT,
    amount: 1,
    isEnabled: true,
  },
  {
    type: TariffPlanLimitType.PRIORITIZED_FEATURE_REQUESTS,
    amount: 1,
    isEnabled: true,
  },
];
