import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { profilesApi } from '../services/ProfilesService';
import { GetProfileByIdResponse } from '../responseTypes/profile';

interface IProfilesSlice {
  currentLimit: number | null;
  currentOffset: number | null;
  totalEntities: number | null;
  profiles: GetProfileByIdResponse[];
}

const initialState: IProfilesSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  profiles: [],
};

const slice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        profilesApi.endpoints.getAllProfiles.matchFulfilled,
        (state: IProfilesSlice, { payload, meta }) => {
          const args = meta.arg.originalArgs;
          const { offset } = args;
          if (offset === 0) return { ...payload, profiles: payload.profiles };
          return {
            ...payload,
            profiles: [...state.profiles, ...payload.profiles],
          };
        },
      )
      .addMatcher(
        profilesApi.endpoints.updateProfileById.matchFulfilled,
        (state: IProfilesSlice, { payload }) => {
          return {
            ...state,
            profiles: state.profiles.map(
              (profComp) =>
                (payload.profile.id === profComp.profile.id && {
                  ...profComp,
                  profile: payload.profile,
                }) ||
                profComp,
            ),
          };
        },
      );
  },
});

export default slice.reducer;

export const selectProfiles = (state: RootState) => state.profiles.profiles;
export const selectProfilesTotalEntities = (state: RootState) =>
  state.profiles.totalEntities;
export const selectProfilesHasMore = (state: RootState) => {
  const total = state.profiles.totalEntities;
  return (
    total === null || (total > 0 && state.profiles.profiles.length < total)
  );
};
