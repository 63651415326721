import React from 'react';
import { TemplateImage } from '../../../store/responseTypes/templates';
import { StyledIconsSelector, StyledIconWrapper } from '../styled';

const IconSelector = ({
  images,
  selectedIcon,
  setSelectedIcon,
}: {
  images: TemplateImage[];
  selectedIcon: string;
  setSelectedIcon: Function;
}) => {
  const selectIcon = (icon: string) => () => {
    setSelectedIcon(icon);
  };

  return (
    <StyledIconsSelector>
      {images.map((image, i) => (
        <StyledIconWrapper
          key={`${image.iconUrl}-${i}`}
          onClick={selectIcon(image.iconUrl)}
          selected={selectedIcon === image.iconUrl}
        >
          <img src={image.iconUrl} />
        </StyledIconWrapper>
      ))}
    </StyledIconsSelector>
  );
};

export default IconSelector;
