import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { textLabelsApi } from '../services/TextLabelsService';
import { TextLabelResponse } from '../responseTypes/textLabel';

interface ITextLabelSlice {
  labels: TextLabelResponse[];
}

const initialState: ITextLabelSlice = {
  labels: [],
};

const slice = createSlice({
  name: 'textLabel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      textLabelsApi.endpoints.getLabels.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          labels: payload.textLabels,
        };
      },
    );
  },
});

export default slice.reducer;

export const selectTextLabels = (state: RootState) => state.textLabel.labels;
