import React from 'react';
import { deliveryTypeOptions } from '../helpers';
import { Select } from '@chakra-ui/react';
import { DeliveryType } from '../../../shared/types/template';

type DeliveryTypeSelectorProps = {
  deliveryType: DeliveryType;
  setSelectedDeliveryType: (deliveryType: DeliveryType) => void;
};

const DeliveryTypeSelector = ({
  deliveryType,
  setSelectedDeliveryType,
}: DeliveryTypeSelectorProps) => {
  return (
    <Select
      onChange={(e) => setSelectedDeliveryType(e.target.value as DeliveryType)}
    >
      {deliveryTypeOptions.map((option) => (
        <option
          key={option.value}
          value={option.value}
          selected={option.value === deliveryType}
        >
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default DeliveryTypeSelector;
