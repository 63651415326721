import React from 'react';
import { Flex, Grid, Input, Button } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IAuthForm } from '../../store/responseTypes/auth';
import { authApi } from '../../store/services/AuthService';
import { useRedirectOnLogin } from '../../shared/hooks/useRedirectOnLogin';

const Auth: React.FC = () => {
  const { register, handleSubmit } = useForm<IAuthForm>();
  const [login, { isLoading }] = authApi.useLoginMutation();
  useRedirectOnLogin();

  const onSubmit: SubmitHandler<IAuthForm> = async (data) => {
    await login(data);
  };

  return (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
      w={'100%'}
      height={'100vh'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid rowGap={'1rem'} w={'20rem'} gridTemplateColumns={'1fr'}>
          <Input {...register('email')} placeholder={'Email'} />
          <Input
            {...register('password')}
            placeholder={'Password'}
            type={'password'}
          />
          <Button colorScheme="blue" type={'submit'} isLoading={isLoading}>
            Login
          </Button>
        </Grid>
      </form>
    </Flex>
  );
};

export default Auth;
