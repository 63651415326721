import React, { useEffect } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useDisclosure,
  Textarea,
  Alert, Grid, Spacer, Divider,
} from '@chakra-ui/react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { StyledNamesWrapper } from './styled';
import { userRolesApi } from '../../store/services/UserRolesService';
import { UserRole } from '../../shared/types/userRole';
import FormInput from '../../shared/components/FormInput';

type INotificationModal = {
  role?: UserRole | null;
  activator: React.ReactNode;
};

type GlobalAttribute = { key: string; value: string };

type FormDataType = {
  name: string;
  description: string;
  globalAttributes: GlobalAttribute[];
};

const UserRoleModal = ({ role = null, activator }: INotificationModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updateRole, { isLoading, isSuccess, isError }] =
    userRolesApi.useUpdateUserRoleMutation();
  const [
    createRole,
    { isLoading: isCreating, isSuccess: isCreated, isError: isCreateError },
  ] = userRolesApi.useCreateUserRoleMutation();

  const { register, reset, handleSubmit, control } = useForm<FormDataType>();

  const {
    fields: attributes,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'globalAttributes',
  });

  useEffect(() => {
    if (isOpen) {
      reset({
        name: role?.name || '',
        description: role?.description || '',
        globalAttributes: Object.keys(role?.globalAttributes ?? []).map((key) => ({
          key,
          value: role?.globalAttributes[key],
        })),
      });
    }
  }, [isOpen, role]);

  const onSubmit: SubmitHandler<FormDataType> = (args) => {
    const globalAttributes: Record<string, string> = {};
    args.globalAttributes.forEach(({ key, value }) => {
      globalAttributes[key] = value;
    });

    const data = {
      name: args.name,
      description: args.description,
      globalAttributes,
    };
    if (role) updateRole({ roleId: role.id, data });
    else createRole({ data });
  };

  const addAttribute = () => {
    append({ key: '', value: '' });
  };

  const removeAttribute = (index: number) => () => {
    remove(index);
  };

  useEffect(() => {
    if (isCreated || isSuccess) {
      onClose();
    }
  }, [isCreated, isSuccess]);

  return (
    <>
      <div role={'button'} onClick={onOpen}>
        {activator}
      </div>

      <Modal size={'xl'} {...{ onClose, isOpen }}>
        <ModalOverlay />
        <ModalContent mx={'1rem'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              {(!!role && 'Edit role') || 'Create role'}
            </ModalHeader>
            <ModalBody>
              <StyledNamesWrapper>
                <label>Name</label>
                <Input
                  placeholder={'Enter role name'}
                  mb={'0.5rem'}
                  isRequired
                  {...register('name')}
                />

                <label>Description</label>
                <Textarea
                  placeholder={'Enter role description'}
                  mb={'0.5rem'}
                  isRequired
                  {...register('description')}
                />

                {!!attributes.length && (
                  <Grid gap={'2rem'} my={'1rem'} w={'100%'}>
                    {/* @ts-ignore */}
                    {attributes.map((attr, index) => (
                      <Grid
                        key={index}
                        gap={'0.5rem'}
                        gridTemplateColumns={'1fr'}
                      >
                        <FormInput
                          register={register}
                          ph={'Key'}
                          name={`globalAttributes.${index}.key`}
                        />
                        <FormInput
                          register={register}
                          ph={'Value'}
                          name={`globalAttributes.${index}.value`}
                        />
                        <Flex gap={'1.5rem'} alignItems={'flex-end'}>
                          <Divider />
                          <Button
                            colorScheme="red"
                            type={'button'}
                            size={'sm'}
                            w={'max-content'}
                            onClick={removeAttribute(index)}
                          >
                            Delete
                          </Button>
                        </Flex>
                      </Grid>
                    ))}
                  </Grid>
                )}

                {(isError || isCreateError) && (
                  <Alert colorScheme={'red'} borderRadius={'0.5rem'}>
                    Saving error
                  </Alert>
                )}
              </StyledNamesWrapper>
            </ModalBody>
            <ModalFooter>
              <Flex gridGap={'0.5rem'} w={'100%'}>
                <Button
                  type={'button'}
                  w={'max-content'}
                  onClick={addAttribute}
                >
                  Add attribute
                </Button>
                <Spacer />
                <Button type={'button'} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme={'blue'}
                  type={'submit'}
                  isLoading={isLoading || isCreating}
                >
                  Save
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserRoleModal;
