import { SortOptions } from '../../shared/types/mixed';
import {
  DeletionRequestStatus,
  DeletionRequestTypes,
} from '../../shared/types/deletionRequests';
import {
  MdOutlineAccessTime,
  MdOutlineAvTimer,
  MdOutlineDone,
} from 'react-icons/md';

export const typeOptions: SortOptions = [
  { value: 'all', label: 'Show all' },
  { value: DeletionRequestTypes.profile, label: 'Profile deletion' },
  { value: DeletionRequestTypes.company, label: 'Company deletion' },
];

export const typeToLabel = (type: DeletionRequestTypes) =>
  ({
    [DeletionRequestTypes.company]: 'Company',
    [DeletionRequestTypes.profile]: 'Profile',
  }[type]);

export const statusToLabel = (status: DeletionRequestStatus) =>
  ({
    [DeletionRequestStatus.PENDING]: 'Pending',
    [DeletionRequestStatus.PROCESSING]: 'Processing',
    [DeletionRequestStatus.PROCESSED]: 'Processed',
  }[status]);

export const statusToIcon = (status: DeletionRequestStatus) =>
  ({
    [DeletionRequestStatus.PENDING]: MdOutlineAccessTime,
    [DeletionRequestStatus.PROCESSING]: MdOutlineAvTimer,
    [DeletionRequestStatus.PROCESSED]: MdOutlineDone,
  }[status]);
