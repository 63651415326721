import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { EmailNotification } from "../../shared/types/emailNotification";
import { emailNotificationsApi } from "../services/EmailNotificationsService";

interface IEmailNotificationsSlice {
  currentLimit: number | null;
  currentOffset: number | null;
  totalEntities: number | null;
  emailNotifications: EmailNotification[];
}

const initialState: IEmailNotificationsSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  emailNotifications: [],
};

const slice = createSlice({
  name: 'emailNotifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      emailNotificationsApi.endpoints.getNotifications.matchFulfilled,
      (state: IEmailNotificationsSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { offset } = args;
        if (offset === 0)
          return { ...state, ...payload, emailNotifications: payload.emailNotifications };
        return {
          ...state,
          ...payload,
          emailNotifications: [...state.emailNotifications, ...payload.emailNotifications],
        };
      },
    );
    builder.addMatcher(
      emailNotificationsApi.endpoints.createNotification.matchFulfilled,
      (state: IEmailNotificationsSlice, { payload: { emailNotification } }) => {
        return {
          ...state,
          emailNotifications: [...state.emailNotifications, emailNotification],
        };
      },
    );
    builder.addMatcher(
      emailNotificationsApi.endpoints.updateNotificationById.matchFulfilled,
      (state: IEmailNotificationsSlice, { payload: { emailNotification } }) => {
        return {
          ...state,
          emailNotifications: state?.emailNotifications?.map(
            (notification) => (notification.id === emailNotification.id && emailNotification) || notification,
          ),
        };
      },
    );
    builder.addMatcher(
      emailNotificationsApi.endpoints.deleteNotificationById.matchFulfilled,
      (state: IEmailNotificationsSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { emailNotificationId } = args;
        return {
          ...state,
          emailNotifications: state?.emailNotifications?.filter(
            (notification) => notification.id !== emailNotificationId,
          ),
        };
      },
    );
  },
});

export default slice.reducer;

export const selectEmailNotifications = (state: RootState) => state.emailNotifications.emailNotifications;
export const selectEmailNotificationsTotalEntities = (state: RootState) =>
  state.emailNotifications.totalEntities;
export const selectEmailNotificationsHasMore = (state: RootState) => {
  const total = state.emailNotifications.totalEntities
  return total === null || (total > 0 && state.emailNotifications.emailNotifications.length < total)
};
