import React, { useEffect, useState } from 'react';
import { StyledContentContainer, StyledMainLayout } from './styled';
import Sidebar from "./Sidebar";
import Header from "./custom/Header/Header";
import { AppContextProvider, defaultState } from "../context/AppContext";
import { isMobile } from "../utils/utils";

interface IDashboard {
  children: React.ReactNode | null;
}

const Dashboard: React.FC<IDashboard> = ({ children }) => {
  const [sidebar, setSidebar] = useState(defaultState.sidebar)

  useEffect(() => {
    document.body.style.overflow = isMobile() && sidebar && 'hidden' || 'unset';
  }, [sidebar])

  return (
    <AppContextProvider value={{ sidebar, setSidebar }}>
      <StyledMainLayout>
        <Sidebar />
        <div>
          <Header />
          <StyledContentContainer>
            {children}
          </StyledContentContainer>
        </div>
      </StyledMainLayout>
    </AppContextProvider>
  );
};

export default Dashboard;
