import { baseApi } from './BaseService';
import {
  GetAllUsersResponse,
  GetProfileByIdResponse,
  UpdateProfileByIdRequest,
} from '../responseTypes/profile';
import { DESC, PROFILES_SORTING_CREATION_DATE } from '../../shared/constants';

const baseUrl = 'admin/profiles';

export const profilesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllProfiles: build.query<
      GetAllUsersResponse,
      {
        limit: number;
        offset: number;
        sorting: string;
        order: string;
        query?: string;
      }
    >({
      query: ({
        limit = 20,
        offset = 0,
        sorting = PROFILES_SORTING_CREATION_DATE,
        order = DESC,
        query = '',
      }) => ({
        url: `${baseUrl}/paginated`,
        params: { limit, offset, sorting, order, query },
      }),
    }),
    getProfileById: build.query<GetProfileByIdResponse, { profileId: string }>({
      query: ({ profileId }) => ({
        url: `${baseUrl}/id/${profileId}`,
      }),
      providesTags: ['Profile'],
    }),
    getCurrentUserProfile: build.query<GetProfileByIdResponse, void>({
      query: () => ({
        url: `${baseUrl}/myself`,
      }),
      providesTags: ['Profile'],
    }),
    updateProfileById: build.mutation<
      GetProfileByIdResponse,
      { profileId: string; data: UpdateProfileByIdRequest }
    >({
      query: ({ profileId, data }) => ({
        url: `${baseUrl}/${profileId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Profile'],
    }),
    inviteUser: build.mutation<void, { email: string; tariffPlanId: string }>({
      query: (data) => ({
        url: baseUrl,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});
