import React from 'react';
import { StyledInfiniteScroll } from "./styled";

type IInfiniteScroll = {
  dataLength: number;
  next: () => void;
  hasMore: boolean,
  children: React.ReactNode
}

const InfiniteScroll = ({ dataLength, next, hasMore, children }: IInfiniteScroll) => {
  return (
    <StyledInfiniteScroll
      dataLength={dataLength}
      next={next}
      hasMore={hasMore}
      loader={<h4 style={{ textAlign: 'center', marginTop: '1rem' }}>Loading...</h4>}
      endMessage={
        <p style={{ textAlign: 'center', marginTop: '1rem' }}>
          <b>No more items</b>
        </p>
      }
    >
      {children}
    </StyledInfiniteScroll>
  );
};

export default InfiniteScroll;
