import React from 'react';
import { Profile } from '../../shared/types/profile';
import ProfileForm from './ProfileForm';
import ProfileInfo from './ProfileInfo';
import Wrapper from '../../shared/components/Wrapper';
import { useEditMode } from '../../shared/hooks/useEditMode';
import EditModeSwitcher from '../../shared/components/EditModeSwitcher';
import { Company } from "../../shared/types/company";

interface IProfileData {
  profile: Profile;
  company: Company;
}

const ProfileData: React.FC<IProfileData> = ({ profile, company }) => {
  const { edit, toggleEditMode, setEdit } = useEditMode();

  return (
    <Wrapper>
      <>
        <EditModeSwitcher toggleEditMode={toggleEditMode} edit={edit} />
        {(edit && <ProfileForm profile={profile} setEdit={setEdit} />) || (
          <ProfileInfo profile={profile} company={company} />
        )}
      </>
    </Wrapper>
  );
};

export default ProfileData;
