import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { Paths } from "../../router/routes";
import { useIsAuthed } from "./useIsAuthed";

export const useRedirectOnLogin = () => {
  const navigate = useNavigate();
  const { isAuthed } = useIsAuthed();

  useEffect(() => {
    if (isAuthed) navigate(Paths.home)
  }, [isAuthed])
}