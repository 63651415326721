import React, { useEffect, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { Button, Flex, Icon, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import { TagCreation, TagWithCompany } from '../../shared/types/tag';
import { useAppSelector } from '../../store/hooks';
import { useListFilters } from '../../shared/hooks/useListFilters';
import { selectTags, selectTagsHasMore } from '../../store/slices/tagsSlice';
import Card from '../../shared/components/ui/Card/Card';
import { TAGS_SORTING_CREATION_DATE } from '../../shared/constants';
import SortableTh from '../../shared/components/table/SortableTH';
import { toPrettyDate } from '../../shared/utils/utils';
import SortBlock from '../../shared/components/SortBlock';
import { creationOptions, labelTypeOptions } from './helpers';
import ResponsiveTable from '../../shared/components/table/ResponsiveTable';
import InfiniteScroll from '../../shared/components/custom/InfiniteScroll/InfiniteScroll';
import { tagsApi } from '../../store/services/TagsService';
import AddTagModal from './AddTagModal';
import AlertDialog from '../../shared/components/custom/AlertDialog/AlertDialog';
import { AiOutlineClose } from 'react-icons/ai';

const Tags = () => {
  const [limit] = useState(20);
  const tags = useAppSelector(selectTags);
  const hasMore = useAppSelector(selectTagsHasMore);
  const [creation, setCreation] = useState('');
  const [labelType, setLabelType] = useState('');
  const { sorting, sortBy, order, orderBy } = useListFilters();

  const [trigger] = tagsApi.useLazyGetAllTagsQuery();
  const [deleteTag] = tagsApi.useDeleteTagByIdMutation();

  const loadMore = (reload: boolean = false) => {
    trigger({
      limit,
      offset: (!reload && tags.length) || 0,
      sorting,
      order,
      creation,
      labelType,
    });
  };

  useEffect(() => loadMore(true), [creation, labelType, sorting, order]);

  const onDelete = (tagId: string) => () => {
    deleteTag(tagId);
  };

  return (
    <MainLayout>
      <Flex mb={4} gridGap={'1rem'}>
        <SortBlock
          value={creation}
          onChange={setCreation}
          options={creationOptions}
        />
        <SortBlock
          value={labelType}
          onChange={setLabelType}
          options={labelTypeOptions}
        />
        <AddTagModal activator={<Button colorScheme="blue">Add new</Button>} />
      </Flex>

      <InfiniteScroll
        dataLength={tags.length}
        next={loadMore}
        hasMore={hasMore}
      >
        <Card noPadding>
          <ResponsiveTable>
            <Thead>
              <Tr>
                <Th w={'10%'}>Creation</Th>
                <Th w={'10%'}>Type</Th>
                <Th w={'20%'}>Translation key</Th>
                <Th w={'20%'}>Value</Th>
                <SortableTh
                  w={'20%'}
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={TAGS_SORTING_CREATION_DATE}
                >
                  Created
                </SortableTh>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {tags.length > 0 &&
                tags.map((tag: TagWithCompany) => (
                  <Tr key={tag.id}>
                    <Td w={'10%'}>{tag.creation}</Td>
                    <Td w={'10%'}>{tag.labelType}</Td>
                    <Td w={'20%'} wordBreak={'break-word'}>
                      {tag.label}
                    </Td>
                    <Td w={'20%'} wordBreak={'break-word'}>
                      {tag.value}
                    </Td>
                    <Td w={'20%'} wordBreak={'break-word'}>
                      {toPrettyDate(tag.createdAt)}
                    </Td>
                    <Td>
                      <Flex justifyContent={'flex-end'} gridGap={'1rem'}>
                        {tag.creation === TagCreation.CUSTOM && (
                          <ButtonLink
                            color={'blue'}
                            to={`${Paths.companies}/${tag.companyId}`}
                          >
                            Company
                          </ButtonLink>
                        )}
                        <ButtonLink
                          colorScheme={'blue'}
                          to={`${Paths.tags}/${tag.id}`}
                        >
                          Open
                        </ButtonLink>
                        <AlertDialog
                          title={`Are you sure you want to DELETE tag "${tag.value}"`}
                          confirmLabel={'Delete'}
                          confirmColorScheme={'red'}
                          onConfirm={onDelete(tag.id)}
                          activator={
                            <Button colorScheme={'gray'} title={'Delete'}>
                              <Icon as={AiOutlineClose} />
                            </Button>
                          }
                        />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </ResponsiveTable>
        </Card>
      </InfiniteScroll>
    </MainLayout>
  );
};

export default Tags;
