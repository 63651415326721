import { baseApi } from './BaseService';
import {
  CreatePricingPlanRequest,
  GetPricingPlanByIdResponse,
  GetPricingPlansResponse,
  UpdatePricingPlanByIdRequest,
} from '../responseTypes/pricingPlans';

const baseUrl = 'admin/tariff_plans';

export const pricingPlansApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPlans: build.query<
      GetPricingPlansResponse,
      {
        sorting: string;
        order: string;
        limit: number;
        offset: number;
        query?: string;
      }
    >({
      query: (params) => ({
        url: baseUrl,
        params,
      }),
      providesTags: ['PricingPlan'],
    }),
    getPlanById: build.query<GetPricingPlanByIdResponse, { planId: string }>({
      query: ({ planId }) => ({
        url: `${baseUrl}/${planId}`,
      }),
      providesTags: ['PricingPlan'],
    }),
    createPlan: build.mutation<
      GetPricingPlanByIdResponse,
      { data: CreatePricingPlanRequest }
    >({
      query: ({ data }) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['PricingPlan'],
    }),
    updatePlanById: build.mutation<
      GetPricingPlanByIdResponse,
      { planId: string; data: UpdatePricingPlanByIdRequest }
    >({
      query: ({ planId, data }) => ({
        url: `${baseUrl}/${planId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['PricingPlan'],
    }),
    makePlanDefault: build.mutation<
      GetPricingPlanByIdResponse,
      { planId: string }
    >({
      query: ({ planId }) => ({
        url: `${baseUrl}/${planId}/make_default`,
        method: 'POST',
      }),
      invalidatesTags: ['PricingPlan'],
    }),
    assignBillingAccount: build.mutation<
      GetPricingPlanByIdResponse,
      { planId: string; billingAccountId: string }
    >({
      query: ({ planId, billingAccountId }) => ({
        url: `${baseUrl}/${planId}/assign`,
        body: { billingAccountId },
        method: 'POST',
      }),
      invalidatesTags: ['Company'],
    }),
    deletePlanById: build.mutation<string, { planId: string }>({
      query: ({ planId }) => ({
        url: `${baseUrl}/${planId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PricingPlan'],
    }),
  }),
});
