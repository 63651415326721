import React from 'react';
import { StyledCard } from './styled';

interface ICard {
  noPadding?: boolean
}

const Card: React.FC<ICard> = ({ children, noPadding = false }) => {
  return <StyledCard noPadding={noPadding}>{children}</StyledCard>;
};

export default Card;
