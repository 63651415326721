import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import {
  DeliveryProfile,
  DeliveryProfileRole,
} from '../../shared/types/deliveryProfile';
import { EMPTY_VALUE } from '../../shared/constants';

interface IDeliveryProfiles {
  deliveryProfiles: DeliveryProfile[];
}

const DeliveryProfiles: React.FC<IDeliveryProfiles> = ({
  deliveryProfiles,
}) => {
  return (
    <Accordion
      backgroundColor={'white'}
      mt={4}
      allowMultiple
      boxShadow="md"
      borderRadius={'md'}>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Delivery Profiles
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Table>
            <Thead>
              <Tr>
                <Th>Type</Th>
                <Th>Name</Th>
                <Th>Company</Th>
                <Th>Email</Th>
                <Th>Status</Th>
                <Th>Role</Th>
                <Th>IP</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {deliveryProfiles?.length &&
                deliveryProfiles.map((profile) => (
                  <Tr key={profile.id}>
                    <Td>{profile.role}</Td>
                    <Td>{profile.fullName}</Td>
                    <Td>{profile.companyName}</Td>
                    <Td>{profile.email}</Td>
                    <Td>{profile.status}</Td>
                    <Td>{profile?.roleInAgreement[0]?.value || EMPTY_VALUE}</Td>
                    <Td>{profile.ipAddress}</Td>
                    <Td>
                      {profile.role !== DeliveryProfileRole.OTHER && (
                        <ButtonLink
                          color={'blue'}
                          to={`${Paths.profiles}/${profile.profileId}`}>
                          Open
                        </ButtonLink>
                      )}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default DeliveryProfiles;
