import React from 'react';
import { Flex, NumberInput, NumberInputField, Text } from '@chakra-ui/react';

interface IFormInputNumber {
  name: string;
  register: Function;
  ph?: string;
  onBlur?: Function;
  step?: string;
}

const FormInputNumber: React.FC<IFormInputNumber> = ({
  register,
  name,
  ph = '',
  onBlur = () => {},
  step = '1',
}) => {
  return (
    <Flex alignItems={'center'}>
      {ph && (
        <Text w={'15rem'} mr={4}>
          {ph}:
        </Text>
      )}
      <NumberInput w={'100%'}>
        <NumberInputField
          {...register(name)}
          placeholder={ph}
          onBlur={onBlur}
          step={step}
        />
      </NumberInput>
    </Flex>
  );
};

export default FormInputNumber;
