export enum DeliveryTermsClausesType {
  CORIGHT = 'CORIGHT',
  OWN = 'OWN',
  DO_NOT_INCLUDE = 'DO_NOT_INCLUDE',
}

export type DeliveryTerms = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdById: string;
  updatedById: string;
  createdByFullName: string;
  updatedByFullName: string;
  clausesType: DeliveryTermsClausesType;
  uploadedClausesLink: string;
  deliveryId: string;
  text?: string
};
