import styled from '@emotion/styled';
import { Flex, Grid } from '@chakra-ui/react';
import { themeValue } from "../../shared/utils/themeSelectors";

export const StyledFiltersWrapper = styled(Grid)`
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: auto auto 1fr 1fr;

  @media (max-width: ${themeValue('media')('m')}) {
    grid-template-columns: 1fr 1fr;
    > div:nth-of-type(3) {
      display: none;
    }
    
    > input:last-of-type {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;

export const StyledActionsRow = styled(Flex)`
  align-items: center;
  grid-gap: 0.5rem;
`;