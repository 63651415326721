import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { PricingPlanLimit } from '../../../shared/types/pricingPlan';
import { StyledLimitsWrapper } from '../styled';
import FormInputNumber from '../../../shared/components/FormInputNumber';
import { tariffPlanToName } from '../helpers';
import { Switch } from '@chakra-ui/react';

const LimitTypeForm = ({
  limit,
  onChange,
}: {
  limit: PricingPlanLimit;
  onChange: Function;
}) => {
  const { register, handleSubmit } = useForm<PricingPlanLimit>({
    defaultValues: { ...limit },
  });

  const onSubmit: SubmitHandler<PricingPlanLimit> = (args) => {
    onChange({ ...args, amount: parseInt(args.amount.toString()) });
  };

  return (
    <StyledLimitsWrapper>
      <div>
        <FormInputNumber
          name={'amount'}
          register={register}
          ph={tariffPlanToName(limit.type)}
          onBlur={handleSubmit(onSubmit)}
        />
      </div>
      <div>
        <Switch
          mr={'1rem'}
          {...register('isEnabled')}
          onInput={handleSubmit(onSubmit)}
        />
        <label>Enabled</label>
      </div>
    </StyledLimitsWrapper>
  );
};

export default LimitTypeForm;
