import React from 'react';
import { DESC } from '../../constants';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { StyledThSortable } from './styled';

interface ISortableTh {
  sortBy: Function;
  orderBy: Function;
  sorting: string;
  order: string;
  field: string;
  w?: string;
}

const SortableTh: React.FC<ISortableTh> = ({
  sortBy,
  orderBy,
  sorting,
  order,
  field,
  children,
  w,
}) => {
  const sortByField = () => {
    sortBy(field);
  };

  const orderByAsc = () => {
    orderBy(DESC);
  };

  const orderByDesc = () => {
    orderBy(DESC);
  };

  return (
    <StyledThSortable w={w} onClick={sortByField}>
      {children}
      {sorting === field &&
        ((order === DESC && <ArrowDownIcon onClick={orderByAsc} />) || (
          <ArrowUpIcon onClick={orderByDesc} />
        ))}
    </StyledThSortable>
  );
};

export default SortableTh;
