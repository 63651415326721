export enum Paths {
  home = '/',
  login = '/login',
  profiles = '/profiles',
  signupRequests = '/signup-requests',
  companies = '/companies',
  tags = '/tags',
  deliveries = '/deliveries',
  usages = '/usages',
  templates = '/templates',
  pricingPlans = '/pricing-plans',
  emailNotifications = '/email-notifications',
  userRoles = '/user-roles',
  deletionRequests = '/deletion-requests',
  settings = '/settings',
}
