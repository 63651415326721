import React from 'react';
import { Company } from '../../shared/types/company';
import CompanyForm from './CompanyForm';
import CompanyInfo from './CompanyInfo';
import Wrapper from '../../shared/components/Wrapper';
import { useEditMode } from '../../shared/hooks/useEditMode';
import EditModeSwitcher from '../../shared/components/EditModeSwitcher';

interface ICompanyData {
  company: Company;
}

const CompanyData: React.FC<ICompanyData> = ({ company }) => {
  const { edit, toggleEditMode, setEdit } = useEditMode();

  return (
    <Wrapper>
      <>
        <EditModeSwitcher toggleEditMode={toggleEditMode} edit={edit} />
        {(edit && <CompanyForm company={company} setEdit={setEdit} />) || (
          <CompanyInfo company={company} />
        )}
      </>
    </Wrapper>
  );
};

export default CompanyData;
