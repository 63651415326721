import React from 'react';
import { Link } from 'react-router-dom';
import { StyledButton } from './styled';

interface IButtonLink {
  to: string;
  children: string;
  color?: string;
  colorScheme?: string;
  disabled?: boolean;
}

const ButtonLink: React.FC<IButtonLink> = ({
  to,
  children,
  color,
  colorScheme,
  disabled,
}) => {
  return (
    <Link to={to}>
      <StyledButton color={color} colorScheme={colorScheme} disabled={disabled}>
        {children}
      </StyledButton>
    </Link>
  );
};

export default ButtonLink;
