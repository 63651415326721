import React from 'react';
import MainLayout from './MainLayout';
import { Flex } from '@chakra-ui/react';

const NotFound: React.FC = () => {
  return (
    <MainLayout>
      <Flex justifyContent={'center'} alignItems={'center'} h={'100%'}>
        404: page not found
      </Flex>
    </MainLayout>
  );
};

export default NotFound;
