import React, { useEffect, useMemo } from 'react';
import { Company } from '../../shared/types/company';
import Wrapper from '../../shared/components/Wrapper';
import { companiesApi } from '../../store/services/CompaniesService';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BillingAccountRequest } from '../../store/responseTypes/billingAccounts';
import { Button, Grid, Icon } from '@chakra-ui/react';
import FormInput from '../../shared/components/FormInput';
import { StyledInputRow } from './styled';
import { BiPaste } from 'react-icons/bi';

const AddCompanyValueButton = ({
  onClick,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
  <Button
    colorScheme="orange"
    w={'max-content'}
    type={'button'}
    onClick={onClick}
    title={'Use company value'}
  >
    <Icon as={BiPaste} />
  </Button>
);

const CompanyBilling = ({ company }: { company: Company }) => {
  const billingAccount = useMemo(() => company?.billingAccount, [company]);

  const [createBillingAccount, { isLoading: isSaving }] =
    companiesApi.useCreateBillingAccountMutation();
  const [updateBillingAccount, { isLoading: isUpdating }] =
    companiesApi.useUpdateBillingAccountByIdMutation();

  const { register, handleSubmit, reset, getValues } =
    useForm<BillingAccountRequest>();

  const onSubmit: SubmitHandler<BillingAccountRequest> = (args) => {
    const data = { ...args, companyId: company.id };
    if (billingAccount?.id) {
      updateBillingAccount({ accountId: billingAccount?.id, data });
    } else {
      createBillingAccount({ data });
    }
  };

  useEffect(() => {
    const { name, address, country, city, phone, zipCode } =
      billingAccount ?? {};
    reset({ name, address, country, city, phone, zipCode });
  }, [billingAccount]);

  const addCompanyValue =
    (key: keyof BillingAccountRequest, companyKey: keyof Company) => () => {
      reset({ ...getValues(), [key]: company[companyKey] });
    };

  return (
    <Wrapper>
      {(!company && <p>Loading billing info...</p>) || (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid rowGap={'1rem'} gridTemplateColumns={'1fr'}>
            <b>Company billing account</b>
            <StyledInputRow>
              <FormInput name={'name'} ph={'Name'} register={register} />
              <AddCompanyValueButton
                onClick={addCompanyValue('name', 'name')}
              />
            </StyledInputRow>
            <StyledInputRow>
              <FormInput name={'address'} ph={'Address'} register={register} />
              <AddCompanyValueButton
                onClick={addCompanyValue('address', 'address')}
              />
            </StyledInputRow>
            <StyledInputRow>
              <FormInput
                name={'zipCode'}
                ph={'Postal Code'}
                register={register}
              />
              <AddCompanyValueButton
                onClick={addCompanyValue('zipCode', 'postalCode')}
              />
            </StyledInputRow>
            <StyledInputRow>
              <FormInput name={'city'} ph={'City'} register={register} />
              <AddCompanyValueButton
                onClick={addCompanyValue('city', 'city')}
              />
            </StyledInputRow>
            <StyledInputRow>
              <FormInput name={'country'} ph={'Country'} register={register} />
              <AddCompanyValueButton
                onClick={addCompanyValue('country', 'country')}
              />
            </StyledInputRow>
            <StyledInputRow>
              <FormInput
                name={'phone'}
                ph={'Phone Number'}
                register={register}
              />
              <AddCompanyValueButton
                onClick={addCompanyValue('phone', 'phoneNumber')}
              />
            </StyledInputRow>
            <Button
              colorScheme="orange"
              type={'submit'}
              isLoading={!company}
              w={'max-content'}
            >
              {(!!billingAccount?.id && 'Update account') || 'Create account'}
            </Button>
          </Grid>
        </form>
      )}
    </Wrapper>
  );
};

export default CompanyBilling;
