import React, { useEffect } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useDisclosure,
  Textarea, Select, Alert,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { StyledNamesWrapper } from './styled';
import { EmailNotification, EmailNotificationTypes } from "../../shared/types/emailNotification";
import { emailNotificationsApi } from "../../store/services/EmailNotificationsService";
import { parseISO } from "date-fns";
import { toPrettyDate } from "../../shared/utils/utils";

type INotificationModal = {
  notification?: EmailNotification | null;
  activator: React.ReactNode;
};

type FormDataType = {
  type: EmailNotificationTypes;
  email: string;
  sentDate: string;
  sentTime: string;
  data: string;
  templateId: string;
};

const EditNotificationModal = ({ notification = null, activator }: INotificationModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updateNotificationById, { isLoading, isSuccess, isError }] =
    emailNotificationsApi.useUpdateNotificationByIdMutation();
  const [createNotification, { isLoading: isCreating, isSuccess: isCreated, isError: isCreateError }] =
    emailNotificationsApi.useCreateNotificationMutation();

  const { register, reset, handleSubmit } = useForm<FormDataType>();

  useEffect(() => {
    if (isOpen) {
      reset({
        type: notification?.type || EmailNotificationTypes.DELIVERY_APPROVED,
        email: notification?.email || '',
        data: notification?.data || '',
        sentDate: toPrettyDate(notification?.sentDate, 'yyyy-MM-dd') || '',
        sentTime: toPrettyDate(notification?.sentDate, 'hh:mm') || '',
        templateId: notification?.templateId || '',
      });
    }
  }, [isOpen, notification]);

  const onSubmit: SubmitHandler<FormDataType> = (args) => {
    const data = {
      type: args.type,
      email: args.email,
      data: args.data,
      templateId: args.templateId,
      sentDate: parseISO(`${args.sentDate} ${args.sentTime}`).toISOString()
    };
    if (notification) updateNotificationById({ emailNotificationId: notification.id, data });
    else createNotification({ data });
  };

  useEffect(() => {
    if (isCreated || isSuccess) {
      onClose();
    }
  }, [isCreated, isSuccess]);

  return (
    <>
      <div role={'button'} onClick={onOpen}>
        {activator}
      </div>

      <Modal size={'xl'} {...{ onClose, isOpen }}>
        <ModalOverlay/>
        <ModalContent mx={'1rem'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              {(!!notification && 'Edit notification') || 'Create notification'}
            </ModalHeader>
            <ModalBody>
              <StyledNamesWrapper>
                <label>Email</label>
                <Input
                  placeholder={'Enter receiver name'}
                  mb={'0.5rem'}
                  isRequired
                  {...register('email')}
                />

                <label>Type</label>
                <Select
                  mb={'0.5rem'}
                  isRequired
                  {...register('type')}
                >
                  {Object.values(EmailNotificationTypes).map((type) => (
                    <option value={type} key={type}>
                      {type}
                    </option>
                  ))}
                </Select>

                <label>Sent date</label>
                <Flex gridGap={'1rem'}>
                  <Input
                    placeholder={'Enter date'}
                    mb={'0.5rem'}
                    type={'date'}
                    isRequired
                    {...register('sentDate')}
                  />
                  <Input
                    placeholder={'Enter time'}
                    mb={'0.5rem'}
                    type={'time'}
                    isRequired
                    {...register('sentTime')}
                  />
                </Flex>

                <label>Template ID</label>
                <Input
                  placeholder={'Enter notification template ID'}
                  mb={'0.5rem'}
                  isRequired
                  {...register('templateId')}
                />

                <label>Data</label>
                <Textarea
                  placeholder={'Enter notification data (optional)'}
                  mb={'0.5rem'}
                  {...register('data')}
                />

                {(isError || isCreateError) && (
                  <Alert colorScheme={'red'} borderRadius={'0.5rem'}>
                    Saving error
                  </Alert>
                )}
              </StyledNamesWrapper>
            </ModalBody>
            <ModalFooter>
              <Flex gridGap={'0.5rem'} justifyContent={'flex-end'}>
                <Button type={'button'} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme={'blue'}
                  type={'submit'}
                  isLoading={isLoading || isCreating}
                >
                  Save
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditNotificationModal;
