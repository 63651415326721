import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Template } from '../../shared/types/template';
import { templatesApi } from '../services/TemplatesService';
import { TemplateImage } from '../responseTypes/templates';
import { unique } from '../../shared/utils/utils';

interface ITemplatesSlice {
  currentLimit: number | null;
  currentOffset: number | null;
  totalEntities: number | null;
  templates: Template[];
  sections: string[];
  images: TemplateImage[];
}

const initialState: ITemplatesSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  templates: [],
  sections: [],
  images: [],
};

const slice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      templatesApi.endpoints.getTemplates.matchFulfilled,
      (state: ITemplatesSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { offset } = args;
        if (offset === 0)
          return { ...state, ...payload, templates: payload.templates };
        return {
          ...state,
          ...payload,
          templates: [...state.templates, ...payload.templates],
        };
      },
    );
    builder.addMatcher(
      templatesApi.endpoints.createTemplate.matchFulfilled,
      (state: ITemplatesSlice, { payload: { template } }) => {
        return {
          ...state,
          templates: [...state.templates, template],
        };
      },
    );
    builder.addMatcher(
      templatesApi.endpoints.updateTemplateById.matchFulfilled,
      (state: ITemplatesSlice, { payload: { template } }) => {
        return {
          ...state,
          templates: state?.templates?.map(
            (tmpl) => (tmpl.id === template.id && template) || tmpl,
          ),
        };
      },
    );
    builder.addMatcher(
      templatesApi.endpoints.deleteTemplateById.matchFulfilled,
      (state: ITemplatesSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { templateId } = args;
        return {
          ...state,
          templates: state?.templates?.filter(
            (template) => template.id !== templateId,
          ),
        };
      },
    );
    builder.addMatcher(
      templatesApi.endpoints.getSections.matchFulfilled,
      (state: ITemplatesSlice, { payload: { availableSections } }) => {
        return { ...state, sections: availableSections };
      },
    );
    builder.addMatcher(
      templatesApi.endpoints.getImages.matchFulfilled,
      (state: ITemplatesSlice, { payload: { images } }) => {
        const uniqueImages = images.filter(unique);
        return { ...state, images: uniqueImages };
      },
    );
  },
});

export default slice.reducer;

export const selectSections = (state: RootState) => state.templates.sections;
export const selectImages = (state: RootState) => state.templates.images;
export const selectTemplates = (state: RootState) => state.templates.templates;
export const selectTemplatesTotalEntities = (state: RootState) =>
  state.templates.totalEntities;
export const selectTemplatesHasMore = (state: RootState) => {
  // const total = state.templates.totalEntities;
  // return (
  //   total === null || (total > 0 && state.templates.templates.length < total)
  // );
  return false;
};
