import { baseApi } from './BaseService';
import {
  GetSignupRequestByIdResponse,
  GetSignupRequestsResponse,
} from '../responseTypes/signupRequests';

const baseUrl = 'admin/signup_requests';

export const signupRequestsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRequests: build.query<
      GetSignupRequestsResponse,
      {
        sorting: string;
        order: string;
        limit: number;
        offset: number;
        query?: string;
        status?: string;
      }
    >({
      query: (params) => ({
        url: `${baseUrl}/paginated`,
        params,
      }),
      providesTags: ['SignupRequest'],
    }),
    getSignupRequestById: build.query<
      GetSignupRequestByIdResponse,
      { requestId: string }
    >({
      query: ({ requestId }) => ({
        url: `${baseUrl}/${requestId}`,
      }),
      providesTags: ['SignupRequest'],
    }),
    approveSignupRequest: build.mutation<
      GetSignupRequestByIdResponse,
      { requestId: string }
    >({
      query: ({ requestId }) => ({
        url: `${baseUrl}/${requestId}/approve`,
        method: 'POST',
      }),
      invalidatesTags: ['SignupRequest'],
    }),
    rejectSignupRequest: build.mutation<
      GetSignupRequestByIdResponse,
      { requestId: string }
    >({
      query: ({ requestId }) => ({
        url: `${baseUrl}/${requestId}/reject`,
        method: 'POST',
      }),
      invalidatesTags: ['SignupRequest'],
    }),
    deleteSignupRequest: build.mutation<
      GetSignupRequestByIdResponse,
      { requestId: string }
    >({
      query: ({ requestId }) => ({
        url: `${baseUrl}/${requestId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SignupRequest'],
    }),
  }),
});
