import React, { useEffect, useMemo } from 'react';
import { Company } from '../../shared/types/company';
import Wrapper from '../../shared/components/Wrapper';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Grid, Text } from '@chakra-ui/react';
import FormSelect from '../../shared/components/FormSelect';
import { pricingPlansApi } from '../../store/services/PricingPlansService';
import { useSelector } from 'react-redux';
import { selectPricingPlans } from '../../store/slices/pricingPlansSlice';
import { SORTING_CREATED_AT } from '../pricingPlans/constants';
import { ASC } from '../../shared/constants';

const CompanyTariffPlan = ({ company }: { company: Company }) => {
  const billingAccount = useMemo(() => company?.billingAccount, [company]);

  const [trigger] = pricingPlansApi.useLazyGetPlansQuery();
  const plans = useSelector(selectPricingPlans);

  const [assignTariffPlan, { isLoading: isAssigning }] =
    pricingPlansApi.useAssignBillingAccountMutation();

  useEffect(() => {
    trigger({
      limit: 20,
      offset: 0,
      sorting: SORTING_CREATED_AT,
      order: ASC,
      query: '',
    });
  }, []);

  const defaultPlan = useMemo(
    () => plans.find((plan) => plan.isDefault),
    [plans],
  );

  useEffect(() => {
    reset({
      tariff_plan_id: billingAccount?.tariffPlanId || defaultPlan?.id || '',
    });
  }, [defaultPlan, billingAccount]);

  const plansOptions = useMemo(() => {
    return plans
      .filter((plan) => !plan.isDeleted && plan.isActive)
      .map((plan) => ({ value: plan.id, label: plan.name }));
  }, [plans]);

  const { register, handleSubmit, reset } = useForm<{
    tariff_plan_id: string;
  }>();

  const onSubmit: SubmitHandler<{ tariff_plan_id: string }> = (args) => {
    assignTariffPlan({
      billingAccountId: billingAccount?.id,
      planId: args.tariff_plan_id,
    });
  };

  return (
    <Wrapper>
      {(!company && <p>Loading billing info...</p>) || (
        <>
          <Text mb={'1rem'}>
            <b>Company tariff plan</b>
          </Text>
          {(!!billingAccount?.id && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid rowGap={'1rem'} gridTemplateColumns={'1fr'}>
                <FormSelect
                  register={register}
                  ph={'Tariff plan'}
                  name={'tariff_plan_id'}
                  labeledOptions={plansOptions}
                />
                <Button
                  colorScheme="orange"
                  type={'submit'}
                  isLoading={!company}
                  w={'max-content'}
                >
                  Select plan
                </Button>
              </Grid>
            </form>
          )) || <p>Create billing account first</p>}
        </>
      )}
    </Wrapper>
  );
};

export default CompanyTariffPlan;
