import React from 'react';
import InfoValue from '../../shared/components/InfoValue';
import { toPrettyDate } from '../../shared/utils/utils';
import { DeliveryUsage } from '../../shared/types/deliveryUsage';

interface IUsageInfo {
  usage: DeliveryUsage;
}

const UsageInfo: React.FC<IUsageInfo> = ({ usage }) => {
  return (
    <>
      <InfoValue value={usage.id} label={'Id'} />
      <InfoValue value={usage.createdByFullName} label={'Created by'} />
      <InfoValue value={usage.updatedByFullName} label={'Updated by'} />
      <InfoValue value={toPrettyDate(usage.createdAt)} label={'Created'} />
      <InfoValue value={toPrettyDate(usage.updatedAt)} label={'Updated'} />
      <InfoValue value={usage.expirationType} label={'Type'} />
      <InfoValue value={usage.status} label={'Status'} />
      <InfoValue value={toPrettyDate(usage.expiryDate)} label={'Expiry Date'} />
      <InfoValue
        value={toPrettyDate(usage.estimatedPublishDate)}
        label={'Estimated PD'}
      />
      <InfoValue
        value={toPrettyDate(usage.agreedPublishDate)}
        label={'Agreed PD'}
      />
      <InfoValue
        value={toPrettyDate(usage.actualPublishDate)}
        label={'Actual PD'}
      />
      <InfoValue value={usage.expirationUnit} label={'Unit'} />
      <InfoValue value={usage.expirationLength} label={'Length (in units)'} />
      <InfoValue
        value={(usage.appliesToAll && 'yes') || 'no'}
        label={'Applies to all'}
      />
      <InfoValue
        value={(usage.isMediaChannelsActive && 'yes') || 'no'}
        label={'MC active'}
      />
      <InfoValue
        value={(usage.isAreaActive && 'yes') || 'no'}
        label={'Area active'}
      />
      <InfoValue value={usage.description} label={'Description'} />
    </>
  );
};

export default UsageInfo;
