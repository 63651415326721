import {
  ExpirationType,
  UsageStatus,
} from '../../shared/types/deliveryUsage';
import { SortOptions } from '../../shared/types/mixed';

export const statusOptions: SortOptions = [
  { value: '', label: 'Status (all)' },
  { value: UsageStatus.EXPIRED, label: 'Show expired' },
  { value: UsageStatus.PENDING, label: 'Show pending' },
  { value: UsageStatus.IN_POWER, label: 'Show in power' },
];
export const expirationTypeOptions: SortOptions = [
  { value: '', label: 'Expiration type (all)' },
  { value: ExpirationType.ACTUAL_PUBLISH_DATE, label: 'Show Actual PD' },
  { value: ExpirationType.ESTIMATED_PUBLISH_DATE, label: 'Show Estimated PD' },
  { value: ExpirationType.UNKNOWN_PUBLISH_DATE, label: 'Show Unknown PD' },
  { value: ExpirationType.UNLIMITED, label: 'Show Unlimited' },
];

export const getDateByType = ({
  expirationType,
  estimatedPublishDate,
  actualPublishDate,
}: {
  expirationType: ExpirationType;
  estimatedPublishDate: string;
  actualPublishDate: string;
}) =>
  ({
    [ExpirationType.ESTIMATED_PUBLISH_DATE]: estimatedPublishDate,
    [ExpirationType.ACTUAL_PUBLISH_DATE]: actualPublishDate,
    [ExpirationType.UNLIMITED]: undefined,
    [ExpirationType.UNKNOWN_PUBLISH_DATE]: undefined,
  }[expirationType]);
