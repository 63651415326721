import {
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Badge,
  Checkbox,
  Button,
  Text,
  Icon,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { profilesApi } from '../../store/services/ProfilesService';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import InviteUser from './InviteUser';
import { toPrettyDate } from '../../shared/utils/utils';
import { useListFilters } from '../../shared/hooks/useListFilters';
import {
  PROFILES_SORTING_CREATION_DATE,
  PROFILES_SORTING_EMAIL,
  PROFILES_SORTING_FULL_NAME,
} from '../../shared/constants';
import SortableTh from '../../shared/components/table/SortableTH';
import { useAppSelector } from '../../store/hooks';
import {
  selectProfiles,
  selectProfilesHasMore,
} from '../../store/slices/profilesSlice';
import ResponsiveTable from '../../shared/components/table/ResponsiveTable';
import Card from '../../shared/components/ui/Card/Card';
import { ResponseProfile } from '../../store/responseTypes/profile';
import { Company } from '../../shared/types/company';
import InfiniteScroll from '../../shared/components/custom/InfiniteScroll/InfiniteScroll';
import { useDebounce } from '../../shared/hooks/useDebounce';
import {
  StyledFiltersWrapper,
  StyledNameWrapper,
  StyledSelectedActionsWrapper,
  StyledSelectedInfo,
} from './styled';
import Input from '../../shared/components/ui/Input/Input';
import useItemsSelect from '../../shared/hooks/useItemsSelect';
import { FaCog } from 'react-icons/fa';

const Profiles: React.FC = () => {
  const [limit] = useState(20);
  const profiles = useAppSelector(selectProfiles);
  const hasMore = useAppSelector(selectProfilesHasMore);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const { sorting, sortBy, order, orderBy } = useListFilters();
  const [trigger] = profilesApi.useLazyGetAllProfilesQuery();

  const [updateProfileById, { isLoading, isSuccess }] =
    profilesApi.useUpdateProfileByIdMutation();

  const ids = useMemo(
    () => profiles.map(({ profile }) => profile.id),
    [profiles],
  );
  const {
    selectItem,
    selectedItems,
    selectAll,
    isSelected,
    isAllSelected,
    isIndeterminate,
  } = useItemsSelect(ids);

  const loadMore = (reload: boolean = false) => {
    trigger({
      limit,
      offset: (!reload && profiles.length) || 0,
      sorting,
      order,
      query: debouncedQuery,
    });
  };

  useEffect(() => loadMore(true), [debouncedQuery, sorting, order]);

  const changeStatusMultiple = (isActive: boolean) => async () => {
    for (const { profile } of profiles) {
      if (selectedItems.includes(profile.id)) {
        const {
          id,
          companyName,
          fullName,
          firstName,
          lastName,
          profession,
          language,
        } = profile;
        await updateProfileById({
          profileId: id,
          data: {
            firstName,
            lastName,
            companyName,
            fullName,
            profession,
            language,
            isActive,
          },
        });
      }
    }
  };

  return (
    <MainLayout>
      {(!!selectedItems.length && (
        <StyledSelectedActionsWrapper>
          <Button
            colorScheme={'red'}
            onClick={changeStatusMultiple(false)}
            isLoading={isLoading}
          >
            Suspend
          </Button>
          <Button
            colorScheme={'green'}
            onClick={changeStatusMultiple(true)}
            isLoading={isLoading}
          >
            Activate
          </Button>
          <div />
          <StyledSelectedInfo>
            <p>Profiles selected: {selectedItems.length}</p>
          </StyledSelectedInfo>
        </StyledSelectedActionsWrapper>
      )) || (
        <StyledFiltersWrapper>
          <InviteUser />
          <div />
          <Input name={'query'} placeholder={'Search...'} onChange={setQuery} />
        </StyledFiltersWrapper>
      )}

      <InfiniteScroll
        dataLength={profiles.length}
        next={loadMore}
        hasMore={hasMore}
      >
        <Card noPadding>
          <ResponsiveTable>
            <Thead>
              <Tr>
                <Th>
                  <Checkbox
                    isIndeterminate={isIndeterminate}
                    isChecked={isAllSelected}
                    onChange={selectAll}
                  />
                </Th>
                <Th>Company</Th>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={PROFILES_SORTING_FULL_NAME}
                >
                  Name
                </SortableTh>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={PROFILES_SORTING_EMAIL}
                >
                  Email
                </SortableTh>
                <Th>Status</Th>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={PROFILES_SORTING_CREATION_DATE}
                >
                  Created
                </SortableTh>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {profiles.map(
                ({
                  profile,
                  company,
                }: {
                  profile: ResponseProfile;
                  company: Company;
                }) => (
                  <Tr key={profile.id}>
                    <Td>
                      <Checkbox
                        onChange={selectItem(profile.id)}
                        isChecked={isSelected(profile.id)}
                      />
                    </Td>
                    <Td>
                      <Text noOfLines={2} title={company?.name}>
                        {company?.name}
                      </Text>
                    </Td>
                    <Td>
                      <StyledNameWrapper>
                        {profile.isTechnical ||
                          (!company?.name && (
                            <Icon title={'Technical'} as={FaCog} />
                          ))}
                        <Text noOfLines={2} title={profile.fullName}>
                          {profile.fullName}
                        </Text>
                      </StyledNameWrapper>
                    </Td>
                    <Td>{profile.email}</Td>
                    <Td>
                      {(profile.isActive && (
                        <Badge colorScheme="green">Active</Badge>
                      )) || <Badge colorScheme="red">Pending</Badge>}
                    </Td>
                    <Td>{toPrettyDate(profile.createdAt)}</Td>
                    <Td>
                      <ButtonLink
                        color={'blue'}
                        to={`${Paths.profiles}/${profile.id}`}
                      >
                        Open
                      </ButtonLink>
                    </Td>
                  </Tr>
                ),
              )}
            </Tbody>
          </ResponsiveTable>
        </Card>
      </InfiniteScroll>
    </MainLayout>
  );
};

export default Profiles;
