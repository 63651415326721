import { Roles } from './profile';
import { BillingAccount } from './billingAccount';
import { AttachedUserRole } from './userRole';

export enum CompanyInvitationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

export type CompanyInvitation = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdById: string;
  updatedById: string;
  createdByFullName: string;
  updatedByFullName: string;
  email: string;
  role: Roles;
  status: CompanyInvitationStatus;
  sentDate: string;
  requestExpiryDate: string;
  actionDate: string;
  companyProfileId: string;
  inviterId: string;
  inviterFullName: string;
  companyId: string;
  inviterProfileId?: string;
};

export type CompanyProfile = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdById: string;
  updatedById: string;
  createdByFullName: string;
  updatedByFullName: string;
  role: Roles;
  profileId: string;
  companyId: string;
  email: string;
  fullName: string;
};

export type Company = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdById: string;
  updatedById: string;
  createdByFullName: string;
  updatedByFullName: string;
  name: string;
  organizationalNumber: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  phoneNumber: string;
  website: string;
  logoLink: string;
  employeesCount: number;
  companyProfiles?: CompanyProfile[];
  companyInvitations?: CompanyInvitation[];
  billingAccount: BillingAccount;
  attachedRoles: AttachedUserRole[];
};
