import { useEffect, useMemo, useState } from 'react';

const useItemsSelect = (items: string[]) => {
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const toggleShift = (e: KeyboardEvent) => {
    setIsShiftPressed(e.shiftKey);
  };

  useEffect(() => {
    document.addEventListener('keydown', toggleShift, true);
    document.addEventListener('keyup', toggleShift, true);
    return () => {
      document.removeEventListener('keydown', toggleShift, true);
      document.removeEventListener('keyup', toggleShift, true);
    };
  }, []);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const isSelected = (id: string) => {
    return selectedItems.indexOf(id) > -1;
  };

  const getItemsInBetween = (currentId: string) => {
    const lastItem = selectedItems[selectedItems.length - 1];
    if (!lastItem) return [];
    const lastItemIndex = items.indexOf(lastItem);

    const currentItemIndex = items.indexOf(currentId);

    return items.filter((item, index) => {
      if (lastItemIndex < currentItemIndex) {
        return index > lastItemIndex && index < currentItemIndex;
      } else {
        return index < lastItemIndex && index > currentItemIndex;
      }
    });
  };

  const selectItem = (id: string) => () => {
    const itemsInBetween = (isShiftPressed && getItemsInBetween(id)) || [];
    if (isSelected(id)) {
      const updated = selectedItems.filter(
        (item) => item !== id && !itemsInBetween.includes(item),
      );
      setSelectedItems(updated);
    } else {
      const updated = [...selectedItems, ...itemsInBetween, id];
      setSelectedItems(updated);
    }
  };

  const selectAll = () => {
    setSelectedItems((selectedItems.length === items.length && []) || items);
  };

  const isIndeterminate = useMemo(
    () =>
      (selectedItems.length && selectedItems.length < items.length) || false,
    [items, selectedItems],
  );

  const isAllSelected = useMemo(
    () => (items.length && selectedItems.length === items.length) || false,
    [items, selectedItems],
  );

  return {
    selectItem,
    selectAll,
    isSelected,
    isAllSelected,
    isIndeterminate,
    selectedItems,
  };
};

export default useItemsSelect;
