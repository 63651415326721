import React from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { useParams } from 'react-router-dom';
import { profilesApi } from '../../store/services/ProfilesService';
import ProfileData from './ProfileData';
import { Paths } from '../../router/routes';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Flex, Spinner } from '@chakra-ui/react';
import Wrapper from '../../shared/components/Wrapper';
import ProfileAttachedRoles from './ProfileAttachedRoles';

const Profile: React.FC = () => {
  const { profileId } = useParams();
  const { data, isLoading } = profilesApi.useGetProfileByIdQuery({
    profileId: profileId || '',
  });

  return (
    <MainLayout>
      {isLoading && (
        <Flex w={'100%'} justifyContent={'center'}>
          <Spinner size="xl" />
        </Flex>
      )}
      {data?.profile && (
        <>
          <ProfileData profile={data.profile} company={data.company} />
          <ProfileAttachedRoles profile={data.profile} />
        </>
      )}
      {data?.company?.id && (
        <Wrapper>
          <ButtonLink to={`${Paths.companies}/${data?.company.id}`}>
            Open company
          </ButtonLink>
        </Wrapper>
      )}
    </MainLayout>
  );
};

export default Profile;
