import styled from '@emotion/styled';
import {
  themeColorPicker,
  themeValue,
} from '../../shared/utils/themeSelectors';

export const StyledJSONInput = styled.div`
  margin-top: 1rem;

  .jsoneditor-mode-tree {
    border-color: ${themeColorPicker('gray03')};
    border-radius: ${themeValue('radii')('md')};
  }

  .jsoneditor-menu {
    background: ${themeColorPicker('gray03')};
    border-color: ${themeColorPicker('gray03')};
    border-top-left-radius: ${themeValue('radii')('md')};
    border-top-right-radius: ${themeValue('radii')('md')};
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  grid-gap: 0.5rem;
  margin-top: 1rem;
`;
