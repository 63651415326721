import React, { useEffect, useMemo, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { companiesApi } from '../../store/services/CompaniesService';
import {
  Badge,
  Grid,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import { CompanyInvitationStatus } from '../../shared/types/company';
import Input from '../../shared/components/ui/Input/Input';
import { useDebounce } from '../../shared/hooks/useDebounce';
import { toPrettyDate } from '../../shared/utils/utils';
import SortBlock from '../../shared/components/SortBlock';
import {
  compareByCreation,
  compareByNameAsc,
  compareByNameDesc,
  compareByUpdate,
  sortOptions,
} from './helpers';
import { SortTypes } from '../../shared/constants/mixed';
import { StyledNoPlan } from './styled';

const Companies: React.FC = () => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [defaultSort] = useState('');
  const [sort, setSort] = useState(defaultSort);

  const { data, refetch, isFetching } = companiesApi.useSearchCompaniesQuery({
    query: debouncedQuery,
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [debouncedQuery]);

  const items = useMemo(() => {
    const companies = [...(data?.companies ?? [])];
    if (sort) {
      companies.sort((a, b) => {
        switch (sort) {
          case SortTypes.nameAsc:
            return compareByNameAsc(a, b);
          case SortTypes.nameDesc:
            return compareByNameDesc(a, b);
          case SortTypes.updatedAt:
            return compareByUpdate(a, b);
          case SortTypes.createdAt:
            return compareByCreation(a, b);
          default:
            return compareByNameAsc(a, b);
        }
      });
    }

    return companies;
  }, [sort, data]);

  return (
    <MainLayout>
      <Grid gridTemplateColumns={'1fr 2fr'} mb={4} gridGap={'1rem'}>
        <SortBlock
          value={defaultSort}
          onChange={setSort}
          options={sortOptions}
        />
        <Input name={'query'} placeholder={'Search...'} onChange={setQuery} />
      </Grid>

      {(!isFetching && (
        <Table
          mt={'1rem'}
          backgroundColor={'white'}
          boxShadow="md"
          borderRadius={'md'}
        >
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Employees</Th>
              <Th>Waiting invitations</Th>
              <Th>Created at</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((company) => (
              <Tr key={company.id}>
                <Td>
                  <div>
                    <p>{company.name}</p>
                    {(!!company.billingAccount?.tariffPlan?.name && (
                      <Badge colorScheme={'blue'}>
                        {company.billingAccount?.tariffPlan?.name}
                      </Badge>
                    )) || <StyledNoPlan>Plan is not set</StyledNoPlan>}
                  </div>
                </Td>
                <Td>{company.employeesCount}</Td>
                <Td>
                  {
                    company?.companyInvitations?.filter(
                      (invitation) =>
                        invitation.status === CompanyInvitationStatus.PENDING,
                    ).length
                  }
                </Td>
                <Td>{toPrettyDate(company.createdAt)}</Td>
                <Td>
                  <ButtonLink
                    color={'blue'}
                    to={`${Paths.companies}/${company.id}`}
                  >
                    Open
                  </ButtonLink>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )) || (
        <Grid gap={'1rem'} gridTemplateColumns={'1fr'} mt={'1rem'}>
          <Skeleton w={'100%'} h={'3rem'} />
          <Skeleton w={'100%'} h={'3rem'} />
          <Skeleton w={'100%'} h={'3rem'} />
          <Skeleton w={'100%'} h={'3rem'} />
          <Skeleton w={'100%'} h={'3rem'} />
        </Grid>
      )}
    </MainLayout>
  );
};

export default Companies;
