import React, { useEffect } from 'react';
import { Button, Flex, Grid, Icon } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Tag, TagLabelType, TagType } from '../../shared/types/tag';
import { UpdateTagByIdRequest } from '../../store/responseTypes/tags';
import { tagsApi } from '../../store/services/TagsService';
import { mapableEnum } from '../../shared/utils/utils';
import FormInput from '../../shared/components/FormInput';
import FormSelect from '../../shared/components/FormSelect';
import FormButtonUpdate from '../../shared/components/FormButtonUpdate';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../router/routes';
import { AiOutlineClose } from 'react-icons/ai';
import AlertDialog from '../../shared/components/custom/AlertDialog/AlertDialog';

interface ITagForm {
  tag: Tag;
  setEdit: (T: boolean) => void;
}

const TagForm: React.FC<ITagForm> = ({ tag, setEdit }) => {
  const { register, handleSubmit, reset } = useForm<UpdateTagByIdRequest>();
  const navigate = useNavigate();
  const [updateTagById, { isLoading, isSuccess }] =
    tagsApi.useUpdateTagByIdMutation();
  const [deleteTag, { isLoading: isDeleting, isSuccess: isDeleted }] =
    tagsApi.useDeleteTagByIdMutation();

  useEffect(() => {
    reset({
      label: tag?.label,
      value: tag?.value,
      categoryTagId: tag?.categoryTagId,
      labelType: tag?.labelType,
      type: tag?.type,
    });
  }, [tag]);

  useEffect(() => {
    if (isSuccess) setEdit(false);
  }, [isSuccess]);

  useEffect(() => {
    if (isDeleted) navigate(Paths.tags);
  }, [isDeleted]);

  const onSubmit: SubmitHandler<UpdateTagByIdRequest> = async (data) => {
    await updateTagById({ tagId: tag.id, data });
  };

  const onDelete = () => {
    deleteTag(tag.id);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid rowGap={'1rem'} gridTemplateColumns={'1fr'}>
        <FormInput
          name={'label'}
          ph={'Label i18 (optional)'}
          register={register}
        />
        <FormInput name={'value'} ph={'Value'} register={register} />
        <FormSelect
          register={register}
          ph={'Label Type'}
          name={'labelType'}
          options={mapableEnum(TagLabelType)}
        />
        <FormSelect
          register={register}
          ph={'Type'}
          name={'type'}
          options={mapableEnum(TagType)}
        />
        <Flex gridGap={'1rem'}>
          <FormButtonUpdate isLoading={isLoading} />
          <AlertDialog
            title={`Are you sure you want to DELETE tag "${tag.value}"`}
            confirmLabel={'Delete'}
            confirmColorScheme={'red'}
            onConfirm={onDelete}
            activator={
              <Button colorScheme={'gray'} isLoading={isDeleting}>
                Delete
              </Button>
            }
          />
        </Flex>
      </Grid>
    </form>
  );
};

export default TagForm;
