import React, { useEffect, useMemo } from 'react';
import Wrapper from '../../shared/components/Wrapper';
import { Divider, Text } from '@chakra-ui/react';
import { Company } from '../../shared/types/company';
import { userRolesApi } from '../../store/services/UserRolesService';
import AttachedRoleRow from './components/AttachedRoleRow';
import { StyledRolesWrapper } from '../profiles/styled';

const CompanyAttachedRoles = ({ company }: { company: Company }) => {
  const roles = useMemo(() => company?.attachedRoles ?? [], [company]);

  const [trigger] = userRolesApi.useLazyGetRolesQuery();

  useEffect(() => {
    trigger({});
  }, []);

  return (
    <Wrapper>
      {(!company && <p>Loading company info...</p>) || (
        <>
          <Text mb={'1rem'}>
            <b>Company roles</b>
          </Text>

          <StyledRolesWrapper>
            {(!!roles.length &&
              roles.map((role) => (
                <>
                  <AttachedRoleRow
                    companyId={company.id}
                    role={role}
                    key={role.id}
                  />
                  <Divider />
                </>
              ))) || (
              <>
                <p>No attached roles</p>
                <Divider />
              </>
            )}

            <AttachedRoleRow companyId={company.id} />
          </StyledRolesWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default CompanyAttachedRoles;
