import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { EMPTY_VALUE } from '../../shared/constants';
import { DeliveryProfileRole } from '../../shared/types/deliveryProfile';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import { Delivery } from '../../shared/types/delivery';
import InfoValue from '../../shared/components/InfoValue';
import { toPrettyDate } from '../../shared/utils/utils';
import { DeliveryUsage } from '../../shared/types/deliveryUsage';

interface IDeliveryUsages {
  usages: DeliveryUsage[];
}

const DeliveryUsages: React.FC<IDeliveryUsages> = ({ usages }) => {
  return (
    <Accordion
      backgroundColor={'white'}
      mt={4}
      allowMultiple
      boxShadow="md"
      borderRadius={'md'}>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Usages
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Table>
            <Thead>
              <Tr>
                <Th>Status</Th>
                <Th>Type</Th>
                <Th>Expiry Date</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {usages.length > 0 &&
                usages.map((usage) => (
                  <Tr key={usage.id}>
                    <Td>{usage.status}</Td>
                    <Td>{usage.expirationType}</Td>
                    <Td>
                      {usage.expiryDate && toPrettyDate(usage.expiryDate)}
                    </Td>
                    <Td>
                      <ButtonLink
                        color={'blue'}
                        to={`${Paths.usages}/${usage.id}`}>
                        Open
                      </ButtonLink>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default DeliveryUsages;
