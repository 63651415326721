import { format, parseISO } from 'date-fns';
import { themeValue } from "./themeSelectors";

export const mapableEnum = <T extends {}>(object: T): (keyof T)[] => {
  return Object.values(object) as (keyof T)[];
};

export const toPrettyDate = (date: string | null | undefined, fmt: string = 'MMMM dd, yyyy') => {
  if (!date) return date;
  try {
    return format(parseISO(date), fmt);
  } catch (e) {
    return date;
  }
};

export const unique = (value: unknown, index: number, self: unknown[]) => {
  return self.indexOf(value) === index;
};

export const isSidebar = () => window.innerWidth > parseInt(themeValue('media')('l'), 10)
export const isMobile = () => window.innerWidth <= parseInt(themeValue('media')('l'), 10)