import React from 'react';
import { Profile } from '../../shared/types/profile';
import InfoValue from '../../shared/components/InfoValue';
import { toPrettyDate } from '../../shared/utils/utils';
import { Company } from '../../shared/types/company';
import { StyledSuspendedText } from './styled';

interface IProfileInfo {
  profile: Profile;
  company: Company;
}

const ProfileInfo: React.FC<IProfileInfo> = ({ profile, company }) => {
  return (
    <>
      {!profile?.isActive && (
        <StyledSuspendedText>Profile is suspended</StyledSuspendedText>
      )}
      <InfoValue value={profile?.fullName} label={'Full Name'} />
      <InfoValue value={profile?.email} label={'Email'} />
      <InfoValue value={company?.name} label={'Company'} />
      <InfoValue value={profile?.profession} label={'Profession'} />
      <InfoValue value={profile?.language} label={'Language'} />
      <InfoValue value={toPrettyDate(profile?.createdAt)} label={'Created'} />
    </>
  );
};

export default ProfileInfo;
