import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import InfoValue from '../../shared/components/InfoValue';

interface IDeliveryHtmlPreview {
  data: string;
  title: string;
}

const DeliveryHtmlPreview: React.FC<IDeliveryHtmlPreview> = ({
  data,
  title,
}) => {
  const createMarkup = () => {
    return {
      __html: data,
    };
  };

  return (
    <Accordion
      backgroundColor={'white'}
      mt={4}
      allowMultiple
      boxShadow="md"
      borderRadius={'md'}>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              {title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel p={6}>
          <div dangerouslySetInnerHTML={createMarkup()}></div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default DeliveryHtmlPreview;
