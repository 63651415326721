import React, { useEffect, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import {
  Badge,
  Button,
  Icon,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import InfiniteScroll from '../../shared/components/custom/InfiniteScroll/InfiniteScroll';
import { useListFilters } from '../../shared/hooks/useListFilters';
import SortableTh from '../../shared/components/table/SortableTH';
import { toPrettyDate } from '../../shared/utils/utils';
import {
  StyledActionsRow,
  StyledFiltersWrapper,
  StyledLink,
  StyledRowWithDescription,
} from './styled';
import ResponsiveTable from '../../shared/components/table/ResponsiveTable';
import Card from '../../shared/components/ui/Card/Card';
import SortBlock from '../../shared/components/SortBlock';
import { statusOptions, statusToColor } from './helpers';
import Input from '../../shared/components/ui/Input/Input';
import { useDebounce } from '../../shared/hooks/useDebounce';
import { useSelector } from 'react-redux';
import AlertDialog from '../../shared/components/custom/AlertDialog/AlertDialog';
import {
  SIGNUP_REQUESTS_SORTING_CREATION,
  SIGNUP_REQUESTS_SORTING_FULL_NAME,
  SIGNUP_REQUESTS_SORTING_STATUS,
} from './constants';
import { signupRequestsApi } from '../../store/services/SignupRequestsService';
import {
  selectSignupRequests,
  selectSignupRequestsHasMore,
} from '../../store/slices/signupRequestsSlice';
import { BsCheckLg } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import { SignupRequestStatus } from '../../shared/constants/signupRequestStatus';

const SignupRequests = () => {
  const [limit] = useState(20);
  const [status, setStatus] = useState('');
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [trigger] = signupRequestsApi.useLazyGetRequestsQuery();
  const requests = useSelector(selectSignupRequests);
  const hasMore = useSelector(selectSignupRequestsHasMore);
  const { sorting, sortBy, order, orderBy } = useListFilters();
  const [deleteRequest, { error: deleteError }] =
    signupRequestsApi.useDeleteSignupRequestMutation();
  const [approveRequest, { error: approveError }] =
    signupRequestsApi.useApproveSignupRequestMutation();
  const [rejectRequest, { error: rejectError }] =
    signupRequestsApi.useRejectSignupRequestMutation();

  useEffect(() => {
    const error = (deleteError || approveError || rejectError) as {
      status: number;
      data: { details: string };
    };
    const message = error?.data?.details ?? 'Unexpected error';

    if (error) alert(message);
  }, [deleteError, approveError, rejectError]);

  const onDelete = (requestId: string) => () => {
    deleteRequest({ requestId });
  };

  const onApprove = (requestId: string) => () => {
    approveRequest({ requestId });
  };

  const onReject = (requestId: string) => () => {
    rejectRequest({ requestId });
  };

  const loadRequests = (fresh: boolean = false) => {
    trigger({
      limit,
      offset: (!fresh && requests.length) || 0,
      sorting,
      order,
      query: debouncedQuery,
      status,
    });
  };

  useEffect(() => {
    loadRequests(true);
  }, [debouncedQuery, sorting, order, status]);

  return (
    <MainLayout>
      <StyledFiltersWrapper>
        <Input name={'query'} placeholder={'Search...'} onChange={setQuery} />
        <SortBlock
          value={status}
          onChange={setStatus}
          options={statusOptions}
        />
        <div />
      </StyledFiltersWrapper>

      <InfiniteScroll
        dataLength={requests.length}
        next={loadRequests}
        hasMore={hasMore}
      >
        <Card noPadding>
          <ResponsiveTable>
            <Thead>
              <Tr>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={SIGNUP_REQUESTS_SORTING_FULL_NAME}
                >
                  Name
                </SortableTh>
                <Th>Contacts</Th>
                <Th>Source</Th>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={SIGNUP_REQUESTS_SORTING_STATUS}
                >
                  Status
                </SortableTh>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={SIGNUP_REQUESTS_SORTING_CREATION}
                >
                  Created at
                </SortableTh>
                <Th style={{ width: 0 }} />
              </Tr>
            </Thead>
            <Tbody>
              {requests.map((request) => (
                <Tr key={request.id}>
                  <StyledRowWithDescription>
                    <p>{request.name}</p>
                    <p>{request.companyName}</p>
                  </StyledRowWithDescription>
                  <Td>
                    <StyledLink href={`tel:${request.phoneNumber}`}>
                      {request.phoneNumber}
                    </StyledLink>
                    <StyledLink href={`mailto:${request.email}`}>
                      {request.email}
                    </StyledLink>
                  </Td>
                  <Td>
                    <p>{request.text}</p>
                  </Td>
                  <Td>
                    <Badge colorScheme={statusToColor(request.status)}>
                      {request.status}
                    </Badge>
                  </Td>
                  <Td>{toPrettyDate(request.createdAt)}</Td>
                  <Td>
                    <StyledActionsRow>
                      {request.status !== SignupRequestStatus.approved && (
                        <AlertDialog
                          title={`Are you sure you want to APPROVE request of "${request.name}"`}
                          confirmLabel={'Approve'}
                          confirmColorScheme={'green'}
                          onConfirm={onApprove(request.id)}
                          activator={
                            <Button colorScheme={'green'} title={'Approve'}>
                              <Icon as={BsCheckLg} />
                            </Button>
                          }
                        />
                      )}

                      {request.status !== SignupRequestStatus.rejected && (
                        <AlertDialog
                          title={`Are you sure you want to REJECT request of "${request.name}"`}
                          confirmLabel={'Reject'}
                          confirmColorScheme={'red'}
                          onConfirm={onReject(request.id)}
                          activator={
                            <Button colorScheme={'red'} title={'Reject'}>
                              <Icon as={AiOutlineClose} />
                            </Button>
                          }
                        />
                      )}

                      <AlertDialog
                        title={`Are you sure you want to DELETE request of "${request.name}"`}
                        confirmLabel={'Delete'}
                        confirmColorScheme={'gray'}
                        onConfirm={onDelete(request.id)}
                        activator={<Button colorScheme={'gray'}>Delete</Button>}
                      />
                    </StyledActionsRow>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </ResponsiveTable>
        </Card>
      </InfiniteScroll>
    </MainLayout>
  );
};

export default SignupRequests;
