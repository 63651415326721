import styled from '@emotion/styled';
import { Table, Th } from '@chakra-ui/react';
import { themeColorPicker, themeValue } from '../../utils/themeSelectors';
import { Next, Previous } from 'chakra-paginator';

export const StyledTableContainer = styled.div`
  max-width: 100%;
  display: block;
  overflow-x: auto;
`;

export const StyledTable = styled(Table)`
  font-size: ${themeValue('fontSizes')('sm')};
  td,
  th {
    padding: 0.5rem 1rem;
  }

  @media (max-width: ${themeValue('media')('m')}) {
    td,
    th {
      padding: 0.5rem;
    }
  }
`;

export const StyledThSortable = styled(Th)`
  cursor: pointer;

  @media (max-width: ${themeValue('media')('xl')}) {
    min-width: unset;
  }

  > svg {
    margin-left: 0.5rem;
    margin-bottom: 0.1rem;
  }
`;

export const StyledPaginationPrev = styled(Previous)`
  margin-right: 1rem;
  background: ${themeColorPicker('gray01')};
`;

export const StyledPaginationNext = styled(Next)`
  margin-left: 1rem;
  background: ${themeColorPicker('gray01')};

  &:hover {
    background: ${themeColorPicker('gray03')};
  }
`;
