import { baseApi } from './BaseService';
import { TextLabelResponse } from '../responseTypes/textLabel';

const baseUrl = 'admin/text_labels';

export const textLabelsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLabels: build.query<{ textLabels: TextLabelResponse[] }, {}>({
      query: () => ({
        url: `${baseUrl}/all`,
      }),
    }),
    updateTextLabel: build.mutation<
      string,
      { textLabelId: string; language: string; jsonString: string }
    >({
      query: ({ textLabelId, language, jsonString }) => ({
        url: `${baseUrl}/${textLabelId}`,
        method: 'PUT',
        body: { language, jsonString },
      }),
    }),
  }),
});
