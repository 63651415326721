import React, { useEffect } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Grid,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { tagsApi } from '../../store/services/TagsService';
import { UpdateTagByIdRequest } from '../../store/responseTypes/tags';
import FormInput from '../../shared/components/FormInput';
import { mapableEnum } from '../../shared/utils/utils';
import { TagLabelType, TagType } from '../../shared/types/tag';
import FormSelect from '../../shared/components/FormSelect';

type IPlanModal = {
  activator: React.ReactNode;
};

const AddTagModal = ({ activator }: IPlanModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [createTag, { isLoading: isCreating, isSuccess: isCreated }] =
    tagsApi.useCreateTagMutation();

  const { register, reset, handleSubmit } = useForm<UpdateTagByIdRequest>();

  useEffect(() => {
    if (isOpen) reset({});
  }, [isOpen]);

  const onSubmit: SubmitHandler<UpdateTagByIdRequest> = (args) => {
    createTag(args);
  };

  useEffect(() => {
    if (isCreated) onClose();
  }, [isCreated]);

  return (
    <>
      <div role={'button'} onClick={onOpen}>
        {activator}
      </div>

      <Modal size={'xl'} {...{ onClose, isOpen }}>
        <ModalOverlay />
        <ModalContent mx={'1rem'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Adding tag</ModalHeader>
            <ModalBody>
              <Grid rowGap={'1rem'} gridTemplateColumns={'1fr'}>
                <FormInput
                  name={'label'}
                  ph={'Label i18 (optional)'}
                  register={register}
                />
                <FormInput name={'value'} ph={'Value'} register={register} />
                <FormSelect
                  register={register}
                  ph={'Label Type'}
                  name={'labelType'}
                  options={mapableEnum(TagLabelType)}
                />
                <FormSelect
                  register={register}
                  ph={'Type'}
                  name={'type'}
                  options={mapableEnum(TagType)}
                />
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Flex gridGap={'0.5rem'} justifyContent={'flex-end'}>
                <Button type={'button'} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme={'blue'}
                  type={'submit'}
                  isLoading={isCreating}
                >
                  Save
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddTagModal;
