import styled from '@emotion/styled';
import {
  themeColorPicker,
  themeValue,
} from '../../shared/utils/themeSelectors';

export const StyledInputRow = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr auto;
`;

export const StyledNoPlan = styled.p`
  font-size: ${themeValue('fontSizes')('sm')};
  color: ${themeColorPicker('gray05')};
`;
