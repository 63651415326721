import styled from "@emotion/styled";
import { themeValue } from "../../../utils/themeSelectors";
import { Box } from "@chakra-ui/react";

export const StyledContainer = styled(Box)`
  width: 100%;
  max-width: 74rem;
  padding: 2rem 3.5rem;
  position: relative;
  overflow: auto;
  margin-inline: auto;

  @media (max-width: ${themeValue('media')('m')}) {
    padding: 1.5rem;
  }
`;