import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../shared/constants';

const mainBaseUrl = '/v2';

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  credentials: 'include',
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    if (result.error.status === 401) {
      const refreshResult = await baseQuery(
        { url: `${mainBaseUrl}/profiles/refresh_token`, method: 'POST' },
        api,
        extraOptions,
      );
      if (refreshResult?.meta?.response?.status === 200) {
        result = await baseQuery(args, api, extraOptions);
      } else {
        await baseQuery(
          { url: `${mainBaseUrl}/profiles/logout`, method: 'POST' },
          api,
          extraOptions,
        );
      }
    }
  }

  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Auth',
    'Company',
    'Profile',
    'Tag',
    'Delivery',
    'Usage',
    'Template',
    'EmailNotification',
    'PricingPlan',
    'BillingAccount',
    'SignupRequest',
    'DeletionRequest',
    'UserRoles',
  ],
  endpoints: () => ({}),
});
