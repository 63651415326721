import {
  GetAllCompaniesResponse,
  GetCompanyByIdResponse,
  UpdateCompanyRequest,
} from '../responseTypes/company';
import { baseApi } from './BaseService';
import {
  BillingAccountRequest,
  GetBillingAccountByIdResponse,
} from '../responseTypes/billingAccounts';
import { AttachedUserRole, UserRoleType } from '../../shared/types/userRole';

const baseUrl = 'admin/companies';
const billingUrl = 'admin/billing_accounts';
const attachedRoles = 'admin/attached_roles';

export const companiesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllCompanies: build.query<GetAllCompaniesResponse, void>({
      query: () => ({
        url: baseUrl,
      }),
    }),
    searchCompanies: build.query<GetAllCompaniesResponse, { query: string }>({
      query: ({ query }) => ({
        url: `${baseUrl}/email/search`,
        params: {
          query,
        },
      }),
    }),
    getCompanyById: build.query<GetCompanyByIdResponse, { companyId: string }>({
      query: ({ companyId }) => ({
        url: `${baseUrl}/${companyId}`,
      }),
      providesTags: ['Company'],
    }),
    updateCompanyById: build.mutation<
      GetCompanyByIdResponse,
      { companyId: string; data: UpdateCompanyRequest }
    >({
      query: ({ companyId, data }) => ({
        url: `${baseUrl}/${companyId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Company'],
    }),
    deleteCompanyById: build.mutation<string, { companyId: string }>({
      query: ({ companyId }) => ({
        url: `${baseUrl}/${companyId}`,
        method: 'DELETE',
      }),
    }),
    getCompanyBillingAccount: build.query<
      GetBillingAccountByIdResponse,
      { companyId: string }
    >({
      query: ({ companyId }) => ({
        url: `${billingUrl}/company/${companyId}`,
      }),
      providesTags: ['BillingAccount'],
    }),
    createBillingAccount: build.mutation<
      GetBillingAccountByIdResponse,
      { data: BillingAccountRequest }
    >({
      query: ({ data }) => ({
        url: `${billingUrl}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['BillingAccount', 'Company'],
    }),
    updateBillingAccountById: build.mutation<
      GetBillingAccountByIdResponse,
      { accountId: string; data: BillingAccountRequest }
    >({
      query: ({ accountId, data }) => ({
        url: `${billingUrl}/${accountId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['BillingAccount', 'Company'],
    }),
    deleteBillingAccountById: build.mutation<string, { accountId: string }>({
      query: ({ accountId }) => ({
        url: `${billingUrl}/${accountId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BillingAccount', 'Company'],
    }),
    attachUserRole: build.mutation<
      { attachedRole: AttachedUserRole },
      {
        companyId?: string;
        profileId?: string;
        roleId: string;
        type: UserRoleType;
        customAttributes: Record<string, string>;
      }
    >({
      query: (data) => ({
        url: attachedRoles,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Company', 'Profile'],
    }),
    updateAttachedUserRole: build.mutation<
      { attachedRole: AttachedUserRole },
      {
        attachedRoleId: string;
        customAttributes: Record<string, string>;
      }
    >({
      query: ({ attachedRoleId, customAttributes }) => ({
        url: `${attachedRoles}/${attachedRoleId}`,
        method: 'PUT',
        body: { customAttributes },
      }),
      invalidatesTags: ['Company', 'Profile'],
    }),
    detachUserRole: build.mutation<
      string,
      {
        attachedRoleId: string;
      }
    >({
      query: ({ attachedRoleId }) => ({
        url: `${attachedRoles}/${attachedRoleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Company', 'Profile'],
    }),
  }),
});
