import React from 'react';
import { Flex, Select, Text } from '@chakra-ui/react';

interface IFormSelect {
  name: string;
  register: Function;
  options?: (string | number | boolean)[];
  labeledOptions?: { value: string | number; label: string }[];
  ph: string;
  onChange?: Function;
}

const FormSelect: React.FC<IFormSelect> = ({
  name,
  register,
  options = [],
  labeledOptions = [],
  ph,
  onChange = () => {},
}) => {
  return (
    <Flex>
      <Text w={'15rem'} mr={4}>
        {ph}:
      </Text>
      <Select {...register(name)} onChange={onChange}>
        {(!!labeledOptions.length &&
          labeledOptions.map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))) ||
          options.map((option) => (
            <option value={option.toString()} key={option.toString()}>
              {option.toString()}
            </option>
          ))}
      </Select>
    </Flex>
  );
};

export default FormSelect;
