import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useDisclosure,
  Textarea,
} from '@chakra-ui/react';
import { DeliveryType, Template } from '../../shared/types/template';
import { SubmitHandler, useForm } from 'react-hook-form';
import { templatesApi } from '../../store/services/TemplatesService';
import { useSelector } from 'react-redux';
import {
  selectImages,
  selectSections,
} from '../../store/slices/templatesSlice';
import { StyledDeliveryType, StyledNamesWrapper } from './styled';
import IconSelector from './components/IconSelector';
import SectionsSelector from './components/SectionsSelector';
import DeliveryTypeSelector from './components/DeliveryTypeSelector';

type ITemplateModal = {
  template?: Template | null;
  activator: React.ReactNode;
};

type FormDataType = {
  name: string;
  description?: string;
  sections: string[];
  nameNo: string;
  descriptionNo?: string;
  iconUrl: string;
  deliveryType: DeliveryType;
  order: number;
};

const TemplateEditModal = ({ template = null, activator }: ITemplateModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSections, setSelectedSections] = useState<string[]>([]);
  const [selectedIcon, setSelectedIcon] = useState<string>('');
  const [selectedDeliveryType, setSelectedDeliveryType] =
    useState<DeliveryType>('DEFAULT');
  const [updateTemplateById, { isLoading, isSuccess }] =
    templatesApi.useUpdateTemplateByIdMutation();
  const [createTemplate, { isLoading: isCreating, isSuccess: isCreated }] =
    templatesApi.useCreateTemplateMutation();

  const [loadSections] = templatesApi.useLazyGetSectionsQuery();
  const sections = useSelector(selectSections);

  const [loadImages] = templatesApi.useLazyGetImagesQuery();
  const images = useSelector(selectImages);

  const { register, reset, handleSubmit } = useForm<FormDataType>();

  useEffect(() => {
    if (isOpen) {
      reset({
        name: template?.name || '',
        nameNo: template?.nameNo || '',
        description: template?.description || '',
        descriptionNo: template?.descriptionNo || '',
        order: template?.order || 0,
      });
      setSelectedSections(template?.sections ?? []);
      setSelectedIcon(template?.iconUrl ?? '');
      setSelectedDeliveryType(template?.deliveryType || 'DEFAULT');
      if (!sections.length) loadSections({});
      if (!images.length) loadImages({});
    }
  }, [isOpen, template]);

  const onSubmit: SubmitHandler<FormDataType> = (args) => {
    const data = {
      ...args,
      sections: selectedSections,
      iconUrl: selectedIcon,
      deliveryType: selectedDeliveryType,
    };
    if (template) updateTemplateById({ templateId: template.id, data });
    else createTemplate({ data });
  };

  useEffect(() => {
    if (isCreated || isSuccess) {
      onClose();
    }
  }, [isCreated, isSuccess]);

  return (
    <>
      <div role={'button'} onClick={onOpen}>
        {activator}
      </div>

      <Modal size={'xl'} {...{ onClose, isOpen }}>
        <ModalOverlay />
        <ModalContent mx={'1rem'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              {(!!template && 'Edit template') || 'Create template'}
            </ModalHeader>
            <ModalBody>
              <StyledNamesWrapper>
                <div>
                  <label>Name</label>
                  <Input
                    placeholder={'Enter template name'}
                    mb={'0.5rem'}
                    isRequired
                    {...register('name')}
                  />

                  <label>Description</label>
                  <Textarea
                    placeholder={'Enter template description (optional)'}
                    mb={'0.5rem'}
                    {...register('description')}
                  />
                </div>
                <div>
                  <label>Name NOR</label>
                  <Input
                    placeholder={'Enter NOR template name'}
                    mb={'0.5rem'}
                    isRequired
                    {...register('nameNo')}
                  />

                  <label>Description NOR</label>
                  <Textarea
                    placeholder={'Enter NOR template description (optional)'}
                    mb={'0.5rem'}
                    {...register('descriptionNo')}
                  />
                </div>
              </StyledNamesWrapper>

              <IconSelector
                images={images}
                selectedIcon={selectedIcon}
                setSelectedIcon={setSelectedIcon}
              />

              <SectionsSelector
                sections={sections}
                selectedSections={selectedSections}
                setSelectedSections={setSelectedSections}
              />
              <StyledDeliveryType>
                <label>Delivery Type</label>
                <DeliveryTypeSelector
                  deliveryType={template?.deliveryType || 'DEFAULT'}
                  setSelectedDeliveryType={setSelectedDeliveryType}
                  {...register('deliveryType')}
                />
              </StyledDeliveryType>

              <label>Order</label>
              <Input
                placeholder={'Enter order'}
                mb={'0.5rem'}
                isRequired
                {...register('order')}
              />
            </ModalBody>
            <ModalFooter>
              <Flex gridGap={'0.5rem'} justifyContent={'flex-end'}>
                <Button type={'button'} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme={'blue'}
                  type={'submit'}
                  isLoading={isLoading || isCreating}
                >
                  Save
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TemplateEditModal;
