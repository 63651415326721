import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { PricingPlan } from '../../shared/types/pricingPlan';
import { pricingPlansApi } from '../services/PricingPlansService';

interface IPricingPlansSlice {
  currentLimit: number | null;
  currentOffset: number | null;
  totalEntities: number | null;
  plans: PricingPlan[];
}

const initialState: IPricingPlansSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  plans: [],
};

const slice = createSlice({
  name: 'pricingPlans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      pricingPlansApi.endpoints.getPlans.matchFulfilled,
      (state: IPricingPlansSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { offset } = args;
        if (offset === 0)
          return { ...state, ...payload, plans: payload.tariffPlans };
        return {
          ...state,
          ...payload,
          plans: [...state.plans, ...payload.tariffPlans],
        };
      },
    );
    builder.addMatcher(
      pricingPlansApi.endpoints.createPlan.matchFulfilled,
      (state: IPricingPlansSlice, { payload: { tariffPlan } }) => {
        return {
          ...state,
          plans: [...state.plans, tariffPlan],
        };
      },
    );
    builder.addMatcher(
      pricingPlansApi.endpoints.updatePlanById.matchFulfilled,
      (state: IPricingPlansSlice, { payload: { tariffPlan } }) => {
        return {
          ...state,
          plans: state?.plans?.map(
            (plan) => (plan.id === tariffPlan.id && tariffPlan) || plan,
          ),
        };
      },
    );
    builder.addMatcher(
      pricingPlansApi.endpoints.deletePlanById.matchFulfilled,
      (state: IPricingPlansSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { planId } = args;
        return {
          ...state,
          plans: state?.plans?.filter((plan) => plan.id !== planId),
        };
      },
    );
  },
});

export default slice.reducer;

export const selectPricingPlans = (state: RootState) =>
  state.pricingPlans.plans;
export const selectPricingPlansTotalEntities = (state: RootState) =>
  state.pricingPlans.totalEntities;
export const selectPricingPlansHasMore = (state: RootState) => {
  // const total = state.templates.totalEntities;
  // return (
  //   total === null || (total > 0 && state.templates.templates.length < total)
  // );
  return false;
};
