import React, { useEffect } from 'react';
import { Grid } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { mapableEnum } from '../../shared/utils/utils';
import FormInput from '../../shared/components/FormInput';
import FormSelect from '../../shared/components/FormSelect';
import FormButtonUpdate from '../../shared/components/FormButtonUpdate';
import {
  DeliveryUsage,
  ExpirationType,
  ExpirationUnit,
} from '../../shared/types/deliveryUsage';
import { UpdateUsageByIdRequest } from '../../store/responseTypes/usage';
import { usagesApi } from '../../store/services/UsagesService';
import { getDateByType } from './helpers';
import FormInputNumber from '../../shared/components/FormInputNumber';
import 'react-datepicker/dist/react-datepicker.css';

interface IUsageForm {
  usage: DeliveryUsage;
  setEdit: (T: boolean) => void;
}

const UsageForm: React.FC<IUsageForm> = ({ usage, setEdit }) => {
  const { register, handleSubmit, reset } = useForm<UpdateUsageByIdRequest>();
  const [updateUsageById, { isLoading, isSuccess }] =
    usagesApi.useUpdateUsageByIdMutation();

  useEffect(() => {
    reset({
      expirationType: usage?.expirationType,
      expirationUnit: usage?.expirationUnit,
      expirationLength: usage?.expirationLength,
      description: usage?.description,
      appliesToAll: usage?.appliesToAll,
      isMediaChannelsActive: usage?.isMediaChannelsActive,
      isAreaActive: usage?.isAreaActive,
      publishDate: getDateByType(usage),
    });
  }, [usage]);

  useEffect(() => {
    if (isSuccess) setEdit(false);
  }, [isSuccess]);

  const onSubmit: SubmitHandler<UpdateUsageByIdRequest> = async (data) => {
    const toBool = (i: string | boolean) => {
      if (typeof i === 'boolean') return i;
      return i === 'true';
    };
    let res = {
      ...data,
      appliesToAll: toBool(data.appliesToAll),
      isMediaChannelsActive: toBool(data.isMediaChannelsActive),
      isAreaActive: toBool(data.isAreaActive),
    };

    if (typeof data.expirationLength === 'string' && data.expirationLength) {
      res = { ...res, expirationLength: parseInt(data.expirationLength) };
    }

    await updateUsageById({
      usageId: usage.id,
      data: { ...res, deliveryId: usage.deliveryId },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid rowGap={'1rem'} gridTemplateColumns={'1fr'}>
        <FormSelect
          register={register}
          ph={'Expiration type'}
          name={'expirationType'}
          options={mapableEnum(ExpirationType)}
        />
        <FormSelect
          register={register}
          ph={'Expiration unit'}
          name={'expirationUnit'}
          options={mapableEnum(ExpirationUnit)}
        />
        <FormInputNumber
          name={'expirationLength'}
          ph={'Expiration Length'}
          register={register}
        />
        <FormInput
          name={'description'}
          ph={'Description'}
          register={register}
        />
        <FormSelect
          register={register}
          ph={'MC active'}
          name={'isMediaChannelsActive'}
          options={[true, false]}
        />
        <FormSelect
          register={register}
          ph={'Area active'}
          name={'isAreaActive'}
          options={[true, false]}
        />
        <FormSelect
          register={register}
          ph={'Applies to all'}
          name={'appliesToAll'}
          options={[true, false]}
        />
        <FormButtonUpdate isLoading={isLoading} />
      </Grid>
    </form>
  );
};

export default UsageForm;
