import { TagCreation, TagLabelType } from '../../shared/types/tag';
import { SortOptions } from '../../shared/types/mixed';

export const creationOptions: SortOptions = [
  { value: '', label: 'All creations' },
  { value: TagCreation.CUSTOM, label: 'Show custom' },
  { value: TagCreation.CORIGHT, label: 'Show Coright' },
];
export const labelTypeOptions: SortOptions = [
  { value: '', label: 'All areas' },
  { value: TagLabelType.UR_AREA, label: 'Show area' },
  { value: TagLabelType.UR_MC, label: 'Show media' },
  { value: TagLabelType.DP_ROLE, label: 'Show roles' },
];
