import React from 'react';
import InfoValue from '../../shared/components/InfoValue';
import {
  DeliveryTerms,
  DeliveryTermsClausesType,
} from '../../shared/types/deliveryTerms';
import DeliveryHtmlPreview from './DeliveryHtmlPreview';
import { Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

interface IDeliveryTerms {
  terms: DeliveryTerms;
}

const DeliveryTermsBlock: React.FC<IDeliveryTerms> = ({ terms }) => {
  return (
    <>
      {terms.clausesType === DeliveryTermsClausesType.OWN &&
        terms?.uploadedClausesLink && (
          <Link href={terms?.uploadedClausesLink} isExternal>
            Open terms <ExternalLinkIcon mx="2px" />
          </Link>
        )}
      {terms.clausesType === DeliveryTermsClausesType.CORIGHT &&
        terms?.text && (
          <DeliveryHtmlPreview data={terms.text} title={'Terms'} />
        )}
    </>
  );
};

export default DeliveryTermsBlock;
