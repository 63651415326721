import React from 'react';
import { Flex, Input, Text } from '@chakra-ui/react';

interface IFormInput {
  name: string;
  register: Function;
  ph?: string;
  type?: 'text' | 'number' | 'email';
}

const FormInput: React.FC<IFormInput> = ({
  register,
  name,
  ph = '',
  type = 'text',
}) => {
  return (
    <Flex alignItems={'center'}>
      {!!ph && (
        <Text w={'15rem'} mr={4}>
          {ph}:
        </Text>
      )}
      <Input {...register(name)} placeholder={ph} type={type} />
    </Flex>
  );
};

export default FormInput;
