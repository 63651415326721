import React from 'react';
import { StyledContainer } from "./styled";

const Container = ({ children, ...props }: {children: React.ReactNode}) => {
  return (
    <StyledContainer {...props}>
      {children}
    </StyledContainer>
  );
};

export default Container;