import React from 'react';
import { PricingPlanLimit } from '../../../shared/types/pricingPlan';
import LimitTypeForm from './LimitTypeForm';
import { StyledLimitFormsWrapper } from '../styled';

const LimitTypes = ({
  limits,
  setLimits,
}: {
  limits: PricingPlanLimit[];
  setLimits: Function;
}) => {
  const changeLimit = (index: number) => (args: PricingPlanLimit) => {
    setLimits(
      limits.map((limit, key) => {
        return (index === key && { ...limit, ...args }) || limit;
      }),
    );
  };

  return (
    <StyledLimitFormsWrapper>
      <p>
        <b>Plan limits</b>
      </p>
      {limits.map((limit, index) => (
        <LimitTypeForm
          key={`limit-${index}`}
          limit={limit}
          onChange={changeLimit(index)}
        />
      ))}
    </StyledLimitFormsWrapper>
  );
};

export default LimitTypes;
