import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Auth from '../modules/auth/Auth';
import Dashboard from '../modules/dashboard/Dashboard';
import { Paths } from './routes';
import NotFound from '../shared/components/NotFound';
import Profiles from '../modules/profiles/Profiles';
import Companies from '../modules/companies/Companies';
import Profile from '../modules/profiles/Profile';
import Tags from '../modules/tags/Tags';
import Company from '../modules/companies/Company';
import Tag from '../modules/tags/Tag';
import Deliveries from '../modules/deliveries/Deliveries';
import Templates from '../modules/templates/Templates';
import DeliveryDetails from '../modules/deliveries/DeliveryDetails';
import Usages from '../modules/usages/Usages';
import Usage from '../modules/usages/Usage';
import { useRouteGuard } from './hooks/useRouteGuard';
import EmailNotifications from '../modules/emailNotifications/EmailNotifications';
import { useIsAuthed } from '../shared/hooks/useIsAuthed';
import PricingPlans from '../modules/pricingPlans/PricingPlans';
import SignupRequests from '../modules/signupRequests/SignupRequests';
import DeletionRequests from '../modules/deletionRequests/DeletionRequests';
import Settings from '../modules/settings/Settings';
import UserRoles from '../modules/userRoles/UserRoles';

const RootRouter = () => {
  useRouteGuard();
  const { isAuthed, isLoadingUser } = useIsAuthed();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthed && !isLoadingUser) navigate('/login');
  }, [isAuthed]);

  return (
    <Routes>
      <Route path={Paths.login} element={<Auth />} />
      <Route path={Paths.home} element={<Dashboard />} />
      <Route path={Paths.signupRequests} element={<SignupRequests />} />
      <Route path={Paths.profiles} element={<Profiles />} />
      <Route path={Paths.profiles + '/:profileId'} element={<Profile />} />
      <Route path={Paths.companies} element={<Companies />} />
      <Route path={Paths.companies + '/:companyId'} element={<Company />} />
      <Route path={Paths.tags} element={<Tags />} />
      <Route path={Paths.tags + '/:tagId'} element={<Tag />} />
      <Route path={Paths.deliveries} element={<Deliveries />} />
      <Route path={Paths.templates} element={<Templates />} />
      <Route path={Paths.pricingPlans} element={<PricingPlans />} />
      <Route path={Paths.emailNotifications} element={<EmailNotifications />} />
      <Route path={Paths.userRoles} element={<UserRoles />} />
      <Route path={Paths.deletionRequests} element={<DeletionRequests />} />
      <Route path={Paths.settings} element={<Settings />} />
      <Route
        path={Paths.deliveries + '/:deliveryId'}
        element={<DeliveryDetails />}
      />
      <Route path={Paths.usages} element={<Usages />} />
      <Route path={Paths.usages + '/:usageId'} element={<Usage />} />
      <Route element={<NotFound />} />
    </Routes>
  );
};

export default RootRouter;
