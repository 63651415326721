import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UserRole } from '../../shared/types/userRole';
import { userRolesApi } from '../services/UserRolesService';

interface IUserRolesSlice {
  userRoles: UserRole[];
}

const initialState: IUserRolesSlice = {
  userRoles: [],
};

const slice = createSlice({
  name: 'userRoles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      userRolesApi.endpoints.getRoles.matchFulfilled,
      (state: IUserRolesSlice, { payload }) => {
        return {
          ...state,
          userRoles: [...payload.roles],
        };
      },
    );
    builder.addMatcher(
      userRolesApi.endpoints.createUserRole.matchFulfilled,
      (state: IUserRolesSlice, { payload: { role } }) => {
        return {
          ...state,
          userRoles: [...state.userRoles, role],
        };
      },
    );
    builder.addMatcher(
      userRolesApi.endpoints.updateUserRole.matchFulfilled,
      (state: IUserRolesSlice, { payload: { role } }) => {
        return {
          ...state,
          userRoles: state?.userRoles?.map(
            (userRole) => (userRole.id === role.id && role) || userRole,
          ),
        };
      },
    );
    builder.addMatcher(
      userRolesApi.endpoints.deleteUserRole.matchFulfilled,
      (state: IUserRolesSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { roleId } = args;
        return {
          ...state,
          userRoles: state?.userRoles?.filter((role) => role.id !== roleId),
        };
      },
    );
  },
});

export default slice.reducer;

export const selectUserRoles = (state: RootState) => state.userRoles.userRoles;
