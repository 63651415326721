import React, { ReactElement } from "react";
import { Flex } from '@chakra-ui/react';

interface IWrapper {
  children: ReactElement
}

const Wrapper: React.FC<IWrapper> = ({ children }) => {
  return (
    <Flex
      p={4}
      backgroundColor={'white'}
      w={'100%'}
      mt={4}
      boxShadow="md"
      direction={'column'}
      borderRadius={'md'}>
      {children}
    </Flex>
  );
};

export default Wrapper;
