import { IAuthForm, IAuthResponse } from '../responseTypes/auth';
import { baseApi } from './BaseService';

const baseUrl = 'admin/profiles';

export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<IAuthResponse, IAuthForm>({
      query: (data) => ({
        url: `${baseUrl}/login`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Auth'],
    }),
    logout: build.mutation({
      query: () => ({
        url: `${baseUrl}/logout`,
        method: 'POST',
      }),
      invalidatesTags: ['Auth'],
    }),
    refreshToken: build.mutation({
      query: () => ({
        url: `${baseUrl}/refresh_token`,
        method: 'POST',
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
});
