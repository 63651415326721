import { baseApi } from './BaseService';
import {
  CreateTemplateRequest,
  GetTemplateByIdResponse,
  GetTemplatesResponse,
  TemplatesImagesResponse,
  TemplatesSectionsResponse,
  UpdateTemplateByIdRequest,
} from '../responseTypes/templates';

const baseUrl = 'admin/delivery_templates';

export const templatesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTemplates: build.query<
      GetTemplatesResponse,
      {
        sorting: string;
        order: string;
        limit: number;
        offset: number;
        query?: string;
        creation?: string;
      }
    >({
      query: (params) => ({
        url: baseUrl,
        params,
      }),
    }),
    getTemplateById: build.query<
      GetTemplateByIdResponse,
      { templateId: string }
    >({
      query: ({ templateId }) => ({
        url: `${baseUrl}/${templateId}`,
      }),
      providesTags: ['Template'],
    }),
    createTemplate: build.mutation<
      GetTemplateByIdResponse,
      { data: CreateTemplateRequest }
    >({
      query: ({ data }) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Template'],
    }),
    updateTemplateById: build.mutation<
      GetTemplateByIdResponse,
      { templateId: string; data: UpdateTemplateByIdRequest }
    >({
      query: ({ templateId, data }) => ({
        url: `${baseUrl}/${templateId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Template'],
    }),
    deleteTemplateById: build.mutation<string, { templateId: string }>({
      query: ({ templateId }) => ({
        url: `${baseUrl}/${templateId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Template'],
    }),
    getSections: build.query<TemplatesSectionsResponse, {}>({
      query: () => ({
        url: `${baseUrl}/sections`,
        method: 'GET',
      }),
    }),
    getImages: build.query<TemplatesImagesResponse, {}>({
      query: () => ({
        url: `${baseUrl}/images`,
        method: 'GET',
      }),
    }),
  }),
});
