import React, { useContext, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from '../../router/routes';
import {
  StyledLogoutWrapper,
  StyledNavLinks,
  StyledProfileBox,
  StyledSidebar,
  StyledSidebarBackdrop,
  StyledUserName,
} from './styled';
import { Avatar, Button, Divider, Spacer, Spinner } from '@chakra-ui/react';
import NavLink from './NavLink';
import {
  BsBuilding,
  BsFillCalendar2CheckFill,
  BsFillEnvelopeFill,
  BsFillFileEarmarkLock2Fill,
  BsFillFileEarmarkTextFill,
  BsFillPeopleFill,
  BsHash,
} from 'react-icons/bs';
import { useAppSelector } from '../../store/hooks';
import { selectCurrentProfile } from '../../store/slices/authSlice';
import { AppContext } from '../context/AppContext';
import { isSidebar } from '../utils/utils';
import useLogout from '../hooks/useLogout';
import { ImPriceTag } from 'react-icons/im';
import { BiSolidUserPlus } from 'react-icons/bi';
import { AiOutlineUserDelete } from 'react-icons/ai';
import { MdOutlineSettings } from 'react-icons/md';
import { LuUserCog } from 'react-icons/lu';

const Sidebar = () => {
  const user = useAppSelector(selectCurrentProfile);
  const { sidebar, setSidebar } = useContext(AppContext);
  const { logout, isLoggingOut } = useLogout();

  const logoutCall = () => {
    logout(null);
  };

  const hide = () => {
    setSidebar(false);
  };

  const updateSize = () => {
    setSidebar(isSidebar());
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <StyledSidebar isOpen={sidebar}>
        <Link to={Paths.home}>
          <StyledProfileBox>
            {(user?.email && <Avatar size={'xs'} name={user?.email} />) || (
              <Spinner size={'xs'} />
            )}
            <StyledUserName noOfLines={1}>
              {user?.email ?? 'loading profile...'}
            </StyledUserName>
          </StyledProfileBox>
        </Link>
        <StyledNavLinks>
          <NavLink
            to={Paths.signupRequests}
            label={'Signup requests'}
            icon={BiSolidUserPlus}
          />
          <NavLink
            to={Paths.profiles}
            label={'Profiles'}
            icon={BsFillPeopleFill}
          />
          <NavLink to={Paths.companies} label={'Companies'} icon={BsBuilding} />
          <NavLink to={Paths.tags} label={'Tags'} icon={BsHash} />
          <NavLink
            to={Paths.deliveries}
            label={'Deliveries'}
            icon={BsFillFileEarmarkTextFill}
          />
          <NavLink
            to={Paths.usages}
            label={'Usages'}
            icon={BsFillCalendar2CheckFill}
          />
          <NavLink
            to={Paths.templates}
            label={'Templates'}
            icon={BsFillFileEarmarkLock2Fill}
          />
          <NavLink
            to={Paths.pricingPlans}
            label={'Pricing plans'}
            icon={ImPriceTag}
          />
          <NavLink
            to={Paths.emailNotifications}
            label={'Email notifications'}
            icon={BsFillEnvelopeFill}
          />
          <NavLink
            to={Paths.deletionRequests}
            label={'Deletion requests'}
            icon={AiOutlineUserDelete}
          />
          <NavLink to={Paths.userRoles} label={'User roles'} icon={LuUserCog} />
          <Divider />
          <NavLink
            to={Paths.settings}
            label={'Settings'}
            icon={MdOutlineSettings}
          />
        </StyledNavLinks>
        <Spacer />
        <StyledLogoutWrapper>
          <Button onClick={logoutCall} isLoading={isLoggingOut} size={'sm'}>
            Logout
          </Button>
        </StyledLogoutWrapper>
      </StyledSidebar>
      <StyledSidebarBackdrop isOpen={sidebar} onClick={hide} />
    </>
  );
};

export default Sidebar;
