import React from 'react';
import {
  AlertDialogOverlay,
  AlertDialog as ChakraDialog,
  AlertDialogContent,
  AlertDialogHeader,
  Button,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from '@chakra-ui/react';

type IAlertDialog = {
  title: string;
  text?: string;
  onConfirm: Function;
  activator: React.ReactNode;
  cancelLabel?: string;
  confirmLabel?: string;
  confirmColorScheme?: string;
};

const AlertDialog = ({
  title,
  text = '',
  onConfirm,
  activator,
  cancelLabel = 'Cancel',
  confirmLabel = 'Yes',
  confirmColorScheme = 'red',
}: IAlertDialog) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  const confirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      <div onClick={onOpen} role={'button'}>
        {activator}
      </div>

      <ChakraDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            {!!text && <AlertDialogBody>{text}</AlertDialogBody>}

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {cancelLabel}
              </Button>
              <Button colorScheme={confirmColorScheme} onClick={confirm} ml={3}>
                {confirmLabel}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </ChakraDialog>
    </>
  );
};

export default AlertDialog;
