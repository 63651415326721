import React, { useEffect, useMemo } from 'react';
import { Button, Flex, Grid, Spacer } from '@chakra-ui/react';
import FormSelect from '../../../shared/components/FormSelect';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { AttachedUserRole, UserRoleType } from '../../../shared/types/userRole';
import { useSelector } from 'react-redux';
import { selectUserRoles } from '../../../store/slices/userRoleSlice';
import { companiesApi } from '../../../store/services/CompaniesService';
import FormInput from '../../../shared/components/FormInput';

type CustomAttribute = { key: string; value: string };

const AttachedRoleRow = ({
  companyId = '',
  profileId = '',
  role = undefined,
}: {
  companyId?: string;
  profileId?: string;
  role?: AttachedUserRole;
}) => {
  const allRoles = useSelector(selectUserRoles);

  const [attachUserRole, { isLoading }] =
    companiesApi.useAttachUserRoleMutation();

  const [updateAttachedUserRole, { isLoading: isSaving }] =
    companiesApi.useUpdateAttachedUserRoleMutation();

  const [deleteAttachedUserRole, { isLoading: isDeleting }] =
    companiesApi.useDetachUserRoleMutation();

  const rolesOptions = useMemo(() => {
    return allRoles.map(({ id, name }) => ({ value: id, label: name }));
  }, [allRoles]);

  const { register, handleSubmit, reset, control } = useForm<{
    roleId: string;
    customAttributes: CustomAttribute[];
  }>();

  const {
    fields: attributes,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'customAttributes',
  });

  const onSubmit: SubmitHandler<{
    roleId: string;
    customAttributes: CustomAttribute[];
  }> = (args) => {
    if (role) {
      const customAttributes: Record<string, string> = {};
      args.customAttributes.forEach(({ key, value }) => {
        customAttributes[key] = value;
      });
      updateAttachedUserRole({
        attachedRoleId: role.id,
        customAttributes,
      });
    } else {
      const payload: {
        type: UserRoleType;
        customAttributes: Record<string, string>;
        roleId: string;
        companyId?: string;
        profileId?: string;
      } = {
        type: (!!companyId && UserRoleType.COMPANY) || UserRoleType.PROFILE,
        customAttributes: {},
        roleId: args.roleId,
      };

      if (companyId) payload.companyId = companyId;
      else if (profileId) payload.profileId = profileId;

      attachUserRole(payload);
    }
  };

  useEffect(() => {
    if (role) {
      reset({
        roleId: role.roleId,
        customAttributes: Object.keys(role.customAttributes).map((key) => ({
          key,
          value: role.customAttributes[key],
        })),
      });
    } else {
      reset({
        roleId: allRoles[0]?.id ?? '',
      });
    }
  }, [role, allRoles]);

  const addAttribute = () => {
    append({ key: '', value: '' });
  };

  const removeAttribute = (index: number) => () => {
    remove(index);
  };

  const deleteRole = () => {
    if (role) deleteAttachedUserRole({ attachedRoleId: role.id })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {(!role && (
        <Grid gap={'1rem'} gridTemplateColumns={'1fr auto'}>
          <FormSelect
            register={register}
            ph={'Role'}
            name={'roleId'}
            labeledOptions={rolesOptions}
          />
          <Button
            colorScheme="orange"
            type={'submit'}
            isLoading={isLoading || isSaving}
            w={'max-content'}
          >
            Attach role
          </Button>
        </Grid>
      )) || (
        <Grid gap={'1rem'}>
          <div>
            <p>
              <b>{role?.role?.name}</b>
            </p>
            <p>{role?.role?.description}</p>

            {!!attributes.length && (
              <Grid gap={'0.5rem'} my={'1rem'}>
                {/* @ts-ignore */}
                {attributes.map((attr, index) => (
                  <Grid
                    key={index}
                    gap={'1rem'}
                    gridTemplateColumns={'1fr 1fr auto'}
                  >
                    <FormInput
                      register={register}
                      ph={'Key'}
                      name={`customAttributes.${index}.key`}
                    />
                    <FormInput
                      register={register}
                      ph={'Value'}
                      name={`customAttributes.${index}.value`}
                    />
                    <Button
                      colorScheme="red"
                      type={'button'}
                      w={'max-content'}
                      onClick={removeAttribute(index)}
                    >
                      Delete
                    </Button>
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
          <Flex gap={'0.5rem'}>
            <Button
              colorScheme="orange"
              type={'submit'}
              isLoading={isLoading || isSaving}
              w={'max-content'}
              size={'sm'}
            >
              Save role
            </Button>
            <Button
              size={'sm'}
              colorScheme="gray"
              type={'button'}
              w={'max-content'}
              onClick={addAttribute}
            >
              Add attribute
            </Button>
            <Spacer />
            <Button
              colorScheme="red"
              type={'button'}
              isLoading={isDeleting}
              w={'max-content'}
              size={'sm'}
              onClick={deleteRole}
            >
              Delete role
            </Button>
          </Flex>
        </Grid>
      )}
    </form>
  );
};

export default AttachedRoleRow;
