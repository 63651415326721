import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainLayout from '../../shared/components/MainLayout';
import { usagesApi } from '../../store/services/UsagesService';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import { Flex } from '@chakra-ui/react';
import InfoValue from '../../shared/components/InfoValue';
import { DeliveryUsage } from '../../shared/types/deliveryUsage';
import { toPrettyDate } from '../../shared/utils/utils';
import UsageInfo from './UsageInfo';
import Wrapper from '../../shared/components/Wrapper';
import UsageData from './UsageData';

const Usage = () => {
  const { usageId } = useParams();
  const [usage, setUsage] = useState<DeliveryUsage | null>(null);

  const { data } = usagesApi.useGetUsageByIdQuery({
    usageId: usageId || '',
  });

  useEffect(() => {
    if (!!data?.usage) setUsage(data.usage);
  }, [data]);

  return (
    <MainLayout>
      {usage && (
        <>
          <UsageData usage={usage} />
          <Wrapper>
            <ButtonLink to={`${Paths.deliveries}/${usage.deliveryId}`}>
              Open delivery
            </ButtonLink>
          </Wrapper>
        </>
      )}
    </MainLayout>
  );
};

export default Usage;
