import { extendTheme, theme } from "@chakra-ui/react"

import {
  LARGE_SCREEN,
  MEDIUM_SCREEN,
  SMALL_SCREEN,
  XLARGE_SCREEN,
  XSMALL_SCREEN,
} from '../constants/screenBreakpoints';

export const defaultTheme = extendTheme({
  fonts: {
    ...theme.fonts,
    heading: '"Inter-Medium", sans-serif',
    headingBold: '"Inter-SemiBold", sans-serif',
    body: '"Inter-Regular", sans-serif',
    mono: 'Menlo, monospace',
    sign: 'brush-script-mt',
  },
  fontSizes: {
    ...theme.fontSizes,
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.19rem', // 19px
    '2xl': '1.31rem', // 21px
    '3xl': '1.5rem', // 24px
    '4xl': '1.63rem', // 26px
    '5xl': '2rem', // 32px
    '6xl': '2.25rem', // 36px
    '7xl': '3.125rem', // 50px
    '8xl': '3.75rem', // 60px
  },
  colors: {
    ...theme.colors,
    white01: '#ffffff',
    black01: '#282828',
    lightGreen01: '#CAF3B4',
    green01: '#00ac56',
    green02: '#00994d',
    green03: '#008040',
    green04: '#008615',
    green05: '#E3FFDA',
    green06: '#008615',
    green07: '#007E14',
    green08: '#CEF3C2',
    gray01: '#dedede',
    gray02: '#c4c4c4',
    gray03: '#bbbbbb', // placeholder
    gray04: '#EBEBEB', // border
    gray05: '#818181', // text
    gray06: '#f7f7f7', // bg
    gray07: '#6E6E6E',
    gray08: '#DFDFDF',
    gray09: '#F8F8F6',
    gray10: '#6A6A6A',
    gray11: '#8F8F8F',
    red01: '#f13524',
    red02: '#DF1300',
    red03: '#EA1300',
    red04: '#FFDBD8',
    blue01: '#1834CB',
    blue02: '#D6EBFF',
    blue03: '#2267EE',
    blue04: '#DDDDF9',
    blue05: '#ECF6FF',
    orange01: '#FFEAC3',
    orange02: '#CC6301',
    beige01: '#e3ffda',
    beige02: '#b6e7b2',
  },
  shadows: {
    ...theme.shadows,
    sm: ' 0 0 5px 5px rgba(0, 0, 0, 0.03)',
    md: '0 0 7px 7px rgba(0, 0, 0, 0.03)',
    lg: '0 0 10px 10px rgba(0, 0, 0, 0.03)',
    xl: '0 0 20px 10px rgba(0, 0, 0, 0.05)',
    card: '0 0 40px 0 rgba(0, 0, 0, 0.07)',
    avatar: '-2px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  radii: {
    ...theme.radii,
    xs: '0.125rem',
    sm: '0.25rem',
    md: '0.5rem',
    lg: '1rem',
    xl: '1.25rem',
  },
  media: {
    xs: `${XSMALL_SCREEN}px`,
    s: `${SMALL_SCREEN}px`,
    m: `${MEDIUM_SCREEN}px`,
    l: `${LARGE_SCREEN}px`,
    xl: `${XLARGE_SCREEN}px`,
  },
});

const customTheme = {
  ...theme,
  ...defaultTheme,
};

export default customTheme;
