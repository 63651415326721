import { baseApi } from './BaseService';
import {
  CreateUserRoleRequest,
  UpdateUserRoleRequest,
  UserRoleByIdResponse,
  UserRolesResponse,
} from '../responseTypes/userRoles';

const baseUrl = 'admin/roles';

export const userRolesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.query<UserRolesResponse, {}>({
      query: (params) => ({
        url: `${baseUrl}`,
        params,
      }),
      providesTags: ['UserRoles'],
    }),

    createUserRole: build.mutation<
      UserRoleByIdResponse,
      { data: CreateUserRoleRequest }
    >({
      query: ({ data }) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['UserRoles'],
    }),

    updateUserRole: build.mutation<
      UserRoleByIdResponse,
      { roleId: string; data: UpdateUserRoleRequest }
    >({
      query: ({ roleId, data }) => ({
        url: `${baseUrl}/${roleId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['UserRoles'],
    }),

    deleteUserRole: build.mutation<string, { roleId: string }>({
      query: ({ roleId }) => ({
        url: `${baseUrl}/${roleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserRoles'],
    }),
  }),
});
