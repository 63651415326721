import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deliveriesApi } from '../../store/services/DeliveriesService';
import MainLayout from '../../shared/components/MainLayout';
import { Delivery } from '../../shared/types/delivery';
import DeliveryProfiles from './DeliveryProfiles';
import DeliveryUsages from './DeliveryUsages';
import DeliveryHtmlPreview from './DeliveryHtmlPreview';
import DeliveryMainInfo from './DeliveryMainInfo';
import DeliveryFees from './DeliveryFees';
import DeliveryTermsBlock from './DeliveryTermsBlock';

const DeliveryDetails = () => {
  const { deliveryId } = useParams();
  const [deliveryData, setDeliveryData] = useState<Delivery | null>(null);
  const { data } = deliveriesApi.useGetDeliveryByIdQuery({
    deliveryId: deliveryId || '',
  });

  useEffect(() => {
    if (!!data) setDeliveryData(data.delivery);
  }, [data]);

  return (
    <MainLayout>
      {!!deliveryData && (
        <>
          <DeliveryMainInfo delivery={deliveryData} />
          <DeliveryTermsBlock terms={deliveryData.terms} />
          <DeliveryProfiles deliveryProfiles={deliveryData.deliveryProfiles} />
          <DeliveryFees feeLines={deliveryData.feeLines} />
          <DeliveryUsages usages={deliveryData.usages} />
          <DeliveryHtmlPreview
            data={deliveryData.deliveryBrief.text}
            title={'Brief'}
          />
          <DeliveryHtmlPreview
            data={deliveryData.deliveryProcess.text}
            title={'Specifications'}
          />
        </>
      )}
    </MainLayout>
  );
};

export default DeliveryDetails;
