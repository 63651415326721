import { SortOptions } from "../../shared/types/mixed";
import { DeliveryStatus } from "../../shared/types/delivery";

export const statusOptions: SortOptions = [
  { value: '', label: 'Show all' },
  { value: DeliveryStatus.DRAFT, label: 'Show draft' },
  { value: DeliveryStatus.APPROVED, label: 'Show approved' },
  { value: DeliveryStatus.WITHDRAWN, label: 'Show withdrawn' },
  { value: DeliveryStatus.AWAITING_APPROVAL, label: 'Show awaiting' },
  { value: DeliveryStatus.SIGNATURE_REQUEST_EXPIRED, label: 'Show expired' },
];