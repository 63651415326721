import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import { CompanyInvitation, CompanyProfile } from '../../shared/types/company';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import { format, parseISO } from 'date-fns';
import { Delivery } from '../../shared/types/delivery';
import { DeliveryResponse } from '../../store/responseTypes/delivery';

interface ICompanyAccordion {
  companyProfiles: CompanyProfile[];
  companyInvitations: CompanyInvitation[];
  companyDeliveries: DeliveryResponse[];
}

const CompanyAccordion: React.FC<ICompanyAccordion> = ({
  companyProfiles,
  companyInvitations,
  companyDeliveries,
}) => {
  return (
    <Accordion
      backgroundColor={'white'}
      mt={4}
      allowMultiple
      boxShadow="md"
      borderRadius={'md'}
    >
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Company Profiles
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Table>
            <Thead>
              <Tr>
                <Th>Status</Th>
                <Th>Email</Th>
                <Th>Role</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {companyProfiles.map((profile) => (
                <Tr key={profile.id}>
                  <Td>{profile.fullName}</Td>
                  <Td>{profile.email}</Td>
                  <Td>{profile.role}</Td>
                  <Td>
                    <ButtonLink
                      color={'blue'}
                      to={`${Paths.profiles}/${profile.profileId}`}
                    >
                      Open
                    </ButtonLink>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Company Invitations
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Table>
            <Thead>
              <Tr>
                <Th>Status</Th>
                <Th>Email</Th>
                <Th>Role</Th>
                <Th>Send date</Th>
                <Th>End Date</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {companyInvitations.map((invitation) => (
                <Tr key={invitation.id}>
                  <Td>{invitation.status}</Td>
                  <Td>{invitation.email}</Td>
                  <Td>{invitation.role}</Td>
                  <Td>
                    {format(parseISO(invitation.sentDate), 'MMMM dd, yyyy')}
                  </Td>
                  <Td>
                    {format(
                      parseISO(invitation.requestExpiryDate),
                      'MMMM dd, yyyy',
                    )}
                  </Td>
                  <Td>
                    <ButtonLink
                      color={'blue'}
                      to={`${Paths.profiles}/${invitation.inviterProfileId}`}
                    >
                      Open sender
                    </ButtonLink>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Contracts
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Table>
            <Thead>
              <Tr>
                <Th>Status</Th>
                <Th>Title</Th>
                <Th>Creation date</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {companyDeliveries.map((delivery) => (
                <Tr key={delivery.id}>
                  <Td>{delivery.status}</Td>
                  <Td>{delivery.title}</Td>
                  <Td>
                    {format(parseISO(delivery.createdAt), 'MMMM dd, yyyy')}
                  </Td>
                  <Td>
                    <ButtonLink
                      color={'blue'}
                      to={`${Paths.deliveries}/${delivery.id}`}
                    >
                      View contract
                    </ButtonLink>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default CompanyAccordion;
