import { SortOptions } from '../../shared/types/mixed';
import { TariffPlanLimitType } from './constants';

export const limitTypeOptions: SortOptions = [
  { value: 'all', label: 'Show all' },
  { value: TariffPlanLimitType.PROJECTS, label: 'Projects' },
  { value: TariffPlanLimitType.COMPANY_PROFILES, label: 'Company profiles' },
  { value: TariffPlanLimitType.DELIVERIES, label: 'Contracts' },
];

export const tariffPlanToName = (type: TariffPlanLimitType) =>
  ({
    [TariffPlanLimitType.PROJECTS]: 'Projects',
    [TariffPlanLimitType.COMPANY_PROFILES]: 'Company profiles',
    [TariffPlanLimitType.DELIVERIES]: 'Contracts',
    [TariffPlanLimitType.MATERIALS_AMOUNT]: 'Amount of materials',
    [TariffPlanLimitType.ATTACHMENTS_AMOUNT]: 'Amount of attachments',
    [TariffPlanLimitType.MEGABYTES_TOTAL]: 'Megabytes total',
    [TariffPlanLimitType.PDF_DELIVERIES]: 'PDF contracts',
    [TariffPlanLimitType.LEGACY_DELIVERIES]: 'Legacy contracts',
    [TariffPlanLimitType.DASHBOARD]: 'Dashboard',
    [TariffPlanLimitType.RIGHTS_EXPIRATION_REMINDER]:
      'Rights expiration reminders',
    [TariffPlanLimitType.ATTACHMENTS_MEGABYTES]: 'Attachments megabytes',
    [TariffPlanLimitType.SHARED_DRAFT_COLLABORATION]:
      'Shared draft collaboration',
    [TariffPlanLimitType.DELIVERY_TEMPLATES]: 'Contract templates',
    [TariffPlanLimitType.PRIORITIZED_FEATURE_REQUESTS]:
      'Prioritized feature requests',
    [TariffPlanLimitType.PRIORITIZED_CUSTOMER_SUPPORT]:
      'Prioritized customer support',
  }[type]);
