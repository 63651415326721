import React from 'react';
import InfoValue from '../../shared/components/InfoValue';
import { toPrettyDate } from '../../shared/utils/utils';
import { Delivery } from '../../shared/types/delivery';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import { Box } from '@chakra-ui/react';
import Wrapper from '../../shared/components/Wrapper';

interface IDeliveryMainInfo {
  delivery: Delivery;
}

const DeliveryMainInfo: React.FC<IDeliveryMainInfo> = ({ delivery }) => {
  return (
    <Wrapper>
      <>
        <Box mt={2}>
          <ButtonLink
            colorScheme={'blue'}
            to={`${Paths.profiles}/${delivery.creatorProfileId}`}>
            Open creator
          </ButtonLink>
        </Box>
        <InfoValue value={delivery.creatorCompany?.name} label={'Company'} />
        <InfoValue value={delivery.id} label={'Id'} />
        <InfoValue value={delivery.status} label={'Status'} />
        <InfoValue value={delivery.title} label={'Title'} />
        <InfoValue value={delivery.endClient} label={'End Client'} />
        <InfoValue
          value={toPrettyDate(delivery.createdAt)}
          label={'Creation date'}
        />
        <InfoValue
          value={toPrettyDate(delivery.lastActionDate)}
          label={'Last action date'}
        />
        <InfoValue
          value={delivery.lastActorFullName}
          label={'Last action name'}
        />
        <InfoValue value={delivery.terms.clausesType} label={'Terms type'} />
      </>
    </Wrapper>
  );
};

export default DeliveryMainInfo;
