import React from 'react';
import { FormControl, FormLabel, Switch } from '@chakra-ui/react';

interface IEditModeSwitcher {
  edit: boolean;
  toggleEditMode:
    | ((event: React.ChangeEvent<HTMLInputElement>) => void)
    | undefined;
}

const EditModeSwitcher: React.FC<IEditModeSwitcher> = ({
  edit,
  toggleEditMode,
}) => {
  return (
    <FormControl display="flex" alignItems="center" mb={4}>
      <FormLabel htmlFor="email-alerts" mb="0">
        Edit mode
      </FormLabel>
      <Switch isChecked={edit} onChange={toggleEditMode} />
    </FormControl>
  );
};

export default EditModeSwitcher;
