export enum TagType {
  TAG = 'TAG',
  CATEGORY = 'CATEGORY',
  OTHER = 'OTHER',
}

export enum TagLabelType {
  DP_ROLE = 'COMPANY_ROLE',
  UR_AREA = 'UR_AREA',
  UR_MC = 'UR_MC',
}

export enum TagCreation {
  CORIGHT = 'CORIGHT',
  CUSTOM = 'CUSTOM',
}

export type Tag = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdById: string;
  updatedById: string;
  type: TagType;
  labelType: TagLabelType;
  creation: TagCreation;
  label: string | null;
  value: string | null;
  companyId?: string;
  categoryTagId: string | null;
  createdByFullName: string;
  updatedByFullName: string;
};

export type TagWithCompany = Tag & { companyId: string };
