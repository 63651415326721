import styled from '@emotion/styled';
import { Button, Flex, Grid, Td } from '@chakra-ui/react';
import {
  themeColorPicker,
  themeValue,
} from '../../shared/utils/themeSelectors';

export const StyledActionsRow = styled(Flex)`
  align-items: center;
  grid-gap: 0.5rem;
  justify-content: flex-end;
`;

export const StyledRowWithDescription = styled(Td)`
  max-width: 15rem;

  p {
    font-size: ${themeValue('fontSizes')('xs')};
    color: ${themeColorPicker('gray05')};
  }

  p:first-of-type {
    font-size: initial;
    color: initial;
  }
`;

export const StyledLink = styled.a`
  color: ${themeColorPicker('blue03')};
  display: block;
`;

export const StyledFiltersWrapper = styled(Grid)`
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr auto 1fr auto;

  @media (max-width: ${themeValue('media')('m')}) {
    grid-template-columns: 1fr;

    > div:nth-of-type(2) {
      display: none;
    }
  }
`;

export const StyledNamesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: ${themeValue('media')('m')}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledIconsSelector = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: ${themeValue('media')('m')}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const StyledIconWrapper = styled.div<{ selected: boolean }>`
  border-radius: ${themeValue('radii')('sm')};
  background: ${({ selected }) =>
    themeColorPicker((selected && 'blue01') || 'gray04')};
  overflow: hidden;
  height: 5rem;
  padding: 1rem;
  cursor: pointer;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

export const StyledSectionButton = styled(Button)<{ selected: boolean }>`
  background: ${({ selected }) =>
    themeColorPicker((selected && 'blue01') || 'gray04')};
  color: ${({ selected }) =>
    themeColorPicker((selected && 'white01') || 'black01')};

  &:hover {
    background: ${({ selected }) =>
      themeColorPicker((selected && 'blue03') || 'gray03')};
  }

  @media (max-width: ${themeValue('media')('m')}) {
    font-size: ${themeValue('fontSizes')('sm')};
  }
`;
