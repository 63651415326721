import React, { useEffect } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { Button, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { toPrettyDate } from '../../shared/utils/utils';
import { StyledActionsRow, StyledFiltersWrapper } from './styled';
import ResponsiveTable from '../../shared/components/table/ResponsiveTable';
import Card from '../../shared/components/ui/Card/Card';
import UserRoleModal from './UserRoleModal';
import { useSelector } from 'react-redux';

import AlertDialog from '../../shared/components/custom/AlertDialog/AlertDialog';
import { userRolesApi } from '../../store/services/UserRolesService';
import { selectUserRoles } from '../../store/slices/userRoleSlice';

const UserRoles = () => {
  const [trigger] = userRolesApi.useLazyGetRolesQuery();
  const roles = useSelector(selectUserRoles);
  const [deleteRole] = userRolesApi.useDeleteUserRoleMutation();

  const onDelete = (roleId: string) => () => {
    deleteRole({ roleId });
  };

  useEffect(() => {
    trigger({});
  }, []);

  return (
    <MainLayout>
      <StyledFiltersWrapper>
        <div />
        <UserRoleModal activator={<Button colorScheme="blue">Create</Button>} />
      </StyledFiltersWrapper>

      <Card noPadding>
        <ResponsiveTable>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Date</Th>
              <Th>Attributes</Th>
              <Th style={{ width: 0 }} />
            </Tr>
          </Thead>
          <Tbody>
            {roles.map((role) => (
              <Tr key={role.id}>
                <Td>
                  <div>
                    <p>
                      <b>{role.name}</b>
                    </p>
                    <p>{role.description}</p>
                  </div>
                </Td>
                <Td>
                  Created at{' '}
                  <b>{toPrettyDate(role.createdAt, 'MMM dd, yyyy hh:mm')}</b>
                </Td>
                <Td>
                  {Object.keys(role.globalAttributes).length}
                </Td>
                <Td>
                  <StyledActionsRow>
                    <UserRoleModal
                      role={role}
                      activator={<Button size={'sm'}>Edit</Button>}
                    />
                    <AlertDialog
                      title={`Are you sure you want to this role`}
                      confirmLabel={'Delete'}
                      confirmColorScheme={'red'}
                      onConfirm={onDelete(role.id)}
                      activator={
                        <Button size={'sm'} colorScheme={'red'}>
                          Delete
                        </Button>
                      }
                    />
                  </StyledActionsRow>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </ResponsiveTable>
      </Card>
    </MainLayout>
  );
};

export default UserRoles;
