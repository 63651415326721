import React, { useEffect } from 'react';
import { Grid, Switch } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Profile } from '../../shared/types/profile';
import { UpdateProfileByIdRequest } from '../../store/responseTypes/profile';
import { profilesApi } from '../../store/services/ProfilesService';
import { Language } from '../../shared/types/language';
import { mapableEnum } from '../../shared/utils/utils';
import FormInput from '../../shared/components/FormInput';
import FormButtonUpdate from '../../shared/components/FormButtonUpdate';
import FormSelect from '../../shared/components/FormSelect';
import { StyledSwitch } from './styled';

interface IProfileForm {
  profile: Profile;
  setEdit: (T: boolean) => void;
}

const ProfileForm: React.FC<IProfileForm> = ({ profile, setEdit }) => {
  const { register, handleSubmit, reset } = useForm<UpdateProfileByIdRequest>();
  const [updateProfileById, { isLoading, isSuccess }] =
    profilesApi.useUpdateProfileByIdMutation();

  useEffect(() => {
    reset({
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      language: profile?.language,
      profession: profile?.profession,
      companyName: profile?.companyName,
      isActive: !profile?.isActive,
    });
  }, [profile]);

  useEffect(() => {
    if (isSuccess) setEdit(false);
  }, [isSuccess]);

  const onSubmit: SubmitHandler<UpdateProfileByIdRequest> = async (data) => {
    await updateProfileById({
      profileId: profile.id,
      data: {
        ...data,
        fullName: `${data.firstName} ${data.lastName}`.trim(),
        isActive: !data.isActive,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid rowGap={'1rem'} gridTemplateColumns={'1fr'}>
        <FormInput name={'firstName'} ph={'First name'} register={register} />
        <FormInput name={'lastName'} ph={'Last name'} register={register} />
        <FormSelect
          register={register}
          ph={'Language'}
          name={'language'}
          options={mapableEnum(Language)}
        />
        <StyledSwitch>
          <p>Suspended</p>
          <Switch colorScheme={'red'} {...register('isActive')} />
        </StyledSwitch>
        <FormButtonUpdate isLoading={isLoading} />
      </Grid>
    </form>
  );
};

export default ProfileForm;
