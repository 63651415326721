import React, { useEffect, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { Button, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import InfiniteScroll from '../../shared/components/custom/InfiniteScroll/InfiniteScroll';
import { useListFilters } from '../../shared/hooks/useListFilters';
import SortableTh from '../../shared/components/table/SortableTH';
import { toPrettyDate } from '../../shared/utils/utils';
import { StyledActionsRow, StyledFiltersWrapper } from './styled';
import ResponsiveTable from '../../shared/components/table/ResponsiveTable';
import Card from '../../shared/components/ui/Card/Card';
import SortBlock from '../../shared/components/SortBlock';
import { creationOptions } from './helpers';
import Input from '../../shared/components/ui/Input/Input';
import { useDebounce } from '../../shared/hooks/useDebounce';
import {
  TEMPLATES_SORTING_CREATED_AT,
  TEMPLATES_SORTING_CREATION,
  TEMPLATES_SORTING_NAME,
} from './constants';
import TemplateEditModal from './TemplateEditModal';
import { templatesApi } from '../../store/services/TemplatesService';
import { useSelector } from 'react-redux';
import {
  selectTemplates,
  selectTemplatesHasMore,
} from '../../store/slices/templatesSlice';
import AlertDialog from '../../shared/components/custom/AlertDialog/AlertDialog';

const Templates = () => {
  const [limit] = useState(20);
  const [creation, setCreation] = useState('all');
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [trigger] = templatesApi.useLazyGetTemplatesQuery();
  const templates = useSelector(selectTemplates);
  const hasMore = useSelector(selectTemplatesHasMore);
  const { sorting, sortBy, order, orderBy } = useListFilters();
  const [deleteTemplateById] = templatesApi.useDeleteTemplateByIdMutation();

  const onDelete = (templateId: string) => () => {
    deleteTemplateById({ templateId });
  };

  const loadTemplates = (fresh: boolean = false) => {
    trigger({
      limit,
      offset: (!fresh && templates.length) || 0,
      sorting,
      order,
      query: debouncedQuery,
      creation,
    });
  };

  useEffect(() => {
    loadTemplates(true);
  }, [debouncedQuery, sorting, order, creation]);

  return (
    <MainLayout>
      <StyledFiltersWrapper>
        <Input name={'query'} placeholder={'Search...'} onChange={setQuery} />
        <SortBlock
          value={creation}
          onChange={setCreation}
          options={creationOptions}
        />
        <div />
        <TemplateEditModal activator={<Button colorScheme="blue">Create</Button>} />
      </StyledFiltersWrapper>

      <InfiniteScroll
        dataLength={templates.length}
        next={loadTemplates}
        hasMore={hasMore}
      >
        <Card noPadding>
          <ResponsiveTable>
            <Thead>
              <Tr>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={TEMPLATES_SORTING_NAME}
                >
                  Name
                </SortableTh>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={TEMPLATES_SORTING_CREATION}
                >
                  Type
                </SortableTh>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={TEMPLATES_SORTING_CREATED_AT}
                >
                  Created at
                </SortableTh>
                <Th style={{ width: 0 }} />
              </Tr>
            </Thead>
            <Tbody>
              {templates.map((template) => (
                <Tr key={template.id}>
                  <Td>{template.name}</Td>
                  <Td>{template.creation}</Td>
                  <Td>{toPrettyDate(template.createdAt)}</Td>
                  <Td>
                    <StyledActionsRow>
                      <TemplateEditModal
                        template={template}
                        activator={<Button>Edit</Button>}
                      />
                      <AlertDialog
                        title={`Are you sure you want to delete template "${template.name}"`}
                        confirmLabel={'Delete'}
                        confirmColorScheme={'red'}
                        onConfirm={onDelete(template.id)}
                        activator={<Button colorScheme={'red'}>Delete</Button>}
                      />
                    </StyledActionsRow>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </ResponsiveTable>
        </Card>
      </InfiniteScroll>
    </MainLayout>
  );
};

export default Templates;
