import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { DeliveryResponse } from "../responseTypes/delivery";
import { deliveriesApi } from "../services/DeliveriesService";

interface IDeliveriesSlice {
  currentLimit: number | null,
  currentOffset: number | null,
  totalEntities: number | null,
  deliveries: DeliveryResponse[],
}

const initialState: IDeliveriesSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  deliveries: [],
}

const slice = createSlice({
  name: 'deliveries',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addMatcher(
      deliveriesApi.endpoints.getAllDeliveries.matchFulfilled,
      (state: IDeliveriesSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { offset } = args
        if (offset === 0) return { ...payload, deliveries: payload.deliveries }
        return { ...payload, deliveries: [...state.deliveries, ...payload.deliveries] }
      },
    )
  },
});

export default slice.reducer;

export const selectDeliveries = (state: RootState) => state.deliveries.deliveries;
export const selectDeliveriesTotalEntities = (state: RootState) => state.deliveries.totalEntities;
export const selectDeliveriesHasMore = (state: RootState) => {
  const total = state.deliveries.totalEntities
  return total === null || (total > 0 && state.deliveries.deliveries.length < total)
};
