import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IProfile, Roles } from "../../shared/types/authProfile";
import { authApi } from '../services/AuthService';
import { profilesApi } from "../services/ProfilesService";

interface IAuthSlice {
  profile: IProfile | null;
  roles: Roles[] | null;
  isLoadingUser: boolean;
}

const initialState: IAuthSlice = {
  roles: null,
  profile: null,
  isLoadingUser: true,
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedOut: () => {
      return { ...initialState, isLoadingUser: false }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.profile = payload.profile;
        state.roles = payload.roles;
        state.isLoadingUser = false;
      },
    ).addMatcher(
      profilesApi.endpoints.getCurrentUserProfile.matchFulfilled,
      (state, { payload }) => {
        state.profile = payload.profile;
        state.isLoadingUser = false;
      }
    ).addMatcher(
      authApi.endpoints.logout.matchFulfilled,
      () => {
        return { ...initialState, isLoadingUser: false }
      }
    )
  },
});

export default slice.reducer;

export const selectCurrentProfile = (state: RootState) => state.auth.profile;
export const selectIsLoadingUser = (state: RootState) => state.auth.isLoadingUser;
export const { loggedOut } = slice.actions