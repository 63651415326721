import React, { useEffect, useMemo, useState } from 'react';
import { deliveriesApi } from '../../store/services/DeliveriesService';
import MainLayout from '../../shared/components/MainLayout';
import { Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import InfiniteScroll from "../../shared/components/custom/InfiniteScroll/InfiniteScroll";
import { useListFilters } from "../../shared/hooks/useListFilters";
import { useSelector } from "react-redux";
import { selectDeliveries, selectDeliveriesHasMore } from "../../store/slices/deliveriesSlice";
import SortableTh from "../../shared/components/table/SortableTH";
import { DELIVERY_SORTING_CREATION_DATE, DELIVERY_SORTING_STATUS, DELIVERY_SORTING_TITLE } from "./constants";
import { toPrettyDate } from "../../shared/utils/utils";
import { StyledActionsRow, StyledEndClient, StyledFiltersWrapper } from './styled';
import ResponsiveTable from "../../shared/components/table/ResponsiveTable";
import Card from "../../shared/components/ui/Card/Card";
import SortBlock from "../../shared/components/SortBlock";
import { statusOptions } from "./helpes";
import Input from '../../shared/components/ui/Input/Input';
import { useDebounce } from '../../shared/hooks/useDebounce';

const Deliveries = () => {
  const [limit] = useState(20);
  const [status, setStatus] = useState('');
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [trigger]  = deliveriesApi.useLazyGetAllDeliveriesQuery();
  const deliveries = useSelector(selectDeliveries)
  const hasMore = useSelector(selectDeliveriesHasMore)
  const { sorting, sortBy, order, orderBy } = useListFilters()

  const loadDeliveries = (reload: boolean = false) => {
    trigger({
      limit,
      offset: !reload && deliveries.length || 0,
      sorting,
      order,
      query: debouncedQuery
    })
  }

  useEffect(() => {
    loadDeliveries(true)
  }, [debouncedQuery, sorting, order]);

  const filteredDeliveries = useMemo(
    () => status && deliveries.filter(delivery => delivery.status === status) || deliveries,
    [status, deliveries])

  useEffect(() => {
    if (filteredDeliveries.length < limit) loadDeliveries()
  }, [filteredDeliveries])

  return (
    <MainLayout>

      <StyledFiltersWrapper>
        <SortBlock
          value={status}
          onChange={setStatus}
          options={statusOptions}
        />
        <div />
        <Input name={'query'} placeholder={'Search...'} onChange={setQuery} />
      </StyledFiltersWrapper>

      <InfiniteScroll dataLength={deliveries.length} next={loadDeliveries} hasMore={hasMore}>
        <Card noPadding>
          <ResponsiveTable>
            <Thead>
              <Tr>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={DELIVERY_SORTING_STATUS}
                >
                  Status
                </SortableTh>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={DELIVERY_SORTING_TITLE}
                >
                  Title
                </SortableTh>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={DELIVERY_SORTING_CREATION_DATE}
                >
                  Creation date
                </SortableTh>
                <Th style={{ width: 0 }} />
              </Tr>
            </Thead>
            <Tbody>
              {filteredDeliveries.map((delivery) => (
                <Tr key={delivery.id}>
                  <Td>{delivery.status}</Td>
                  <Td>
                    <div>
                      <p>{delivery.title}</p>
                      <StyledEndClient>for {delivery.endClient}</StyledEndClient>
                    </div>
                  </Td>
                  <Td>{toPrettyDate(delivery.createdAt)}</Td>
                  <Td>
                    <StyledActionsRow>
                      <ButtonLink
                        color={'blue'}
                        to={`${Paths.deliveries}/${delivery.id}`}>
                        Show more
                      </ButtonLink>
                      <ButtonLink
                        color={'blue'}
                        to={`${Paths.companies}/${delivery.creatorCompanyId}`}>
                        Company
                      </ButtonLink>
                    </StyledActionsRow>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </ResponsiveTable>
        </Card>
      </InfiniteScroll>
    </MainLayout>
  );
};

export default Deliveries;
