import React, { useEffect } from 'react';
import { Company } from '../../shared/types/company';
import { Button, Flex, Grid } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UpdateCompanyRequest } from '../../store/responseTypes/company';
import { companiesApi } from '../../store/services/CompaniesService';
import FormButtonUpdate from '../../shared/components/FormButtonUpdate';
import FormInput from '../../shared/components/FormInput';
import AlertDialog from '../../shared/components/custom/AlertDialog/AlertDialog';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../router/routes';

interface ICompanyForm {
  company: Company;
  setEdit: (T: boolean) => void;
}

const CompanyForm: React.FC<ICompanyForm> = ({ company, setEdit }) => {
  const { register, handleSubmit, reset } = useForm<UpdateCompanyRequest>();
  const navigate = useNavigate();
  const [updateCompanyById, { isLoading, isSuccess }] =
    companiesApi.useUpdateCompanyByIdMutation();
  const [deleteCompany, { isLoading: isDeleting, isSuccess: isDeleted }] =
    companiesApi.useDeleteCompanyByIdMutation();

  useEffect(() => {
    reset({
      name: company?.name,
      address: company?.address,
      postalCode: company?.postalCode,
      city: company?.city,
      country: company?.country,
      phoneNumber: company?.phoneNumber,
      website: company?.website,
    });
  }, [company]);

  useEffect(() => {
    if (isSuccess) setEdit(false);
  }, [isSuccess]);

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = async (data) => {
    await updateCompanyById({ companyId: company.id, data });
  };

  useEffect(() => {
    if (isDeleted) navigate(Paths.companies);
  }, [isDeleted]);

  const onDelete = () => {
    if (company) deleteCompany({ companyId: company.id });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid rowGap={'1rem'} gridTemplateColumns={'1fr'}>
        <FormInput name={'name'} ph={'Name'} register={register} />
        <FormInput name={'address'} ph={'Address'} register={register} />
        <FormInput name={'postalCode'} ph={'Postal Code'} register={register} />
        <FormInput name={'city'} ph={'City'} register={register} />
        <FormInput name={'country'} ph={'Country'} register={register} />
        <FormInput
          name={'phoneNumber'}
          ph={'Phone Number'}
          register={register}
        />
        <FormInput name={'website'} ph={'Website'} register={register} />
        <Flex gridGap={'1rem'}>
          <FormButtonUpdate isLoading={isLoading} />
          <AlertDialog
            title={`Are you sure you want to DELETE tag "${company?.name}"`}
            confirmLabel={'Delete'}
            confirmColorScheme={'red'}
            onConfirm={onDelete}
            activator={
              <Button colorScheme={'gray'} isLoading={isDeleting}>
                Delete
              </Button>
            }
          />
        </Flex>
      </Grid>
    </form>
  );
};

export default CompanyForm;
