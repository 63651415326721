import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from "react";
import { Paths } from "../../router/routes";
import { profilesApi } from "../../store/services/ProfilesService";
import { skipToken } from "@reduxjs/toolkit/query";
import useLogout from "./useLogout";
import { useIsAuthed } from "./useIsAuthed";

export const useRedirectIfNotAuthed = () => {
  const navigate = useNavigate();
  const { isAuthed } = useIsAuthed();
  const { logout } = useLogout();

  const userShouldBeLoaded = useMemo(() => !isAuthed, [isAuthed])

  const { error } = profilesApi.useGetCurrentUserProfileQuery(
    !userShouldBeLoaded && skipToken || undefined
  )

  useEffect(() => {
    if (error) {
      logout({});
      navigate(Paths.login)
    }
  }, [error])
}