import React, { useEffect, useMemo, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { Box, Button, Flex, Spacer, Spinner, Text } from '@chakra-ui/react';
import { textLabelsApi } from '../../store/services/TextLabelsService';
import { StyledButtonsWrapper, StyledJSONInput } from './styled';
import ReactJsonEditor from 'jsoneditor-for-react';
import { useSelector } from 'react-redux';
import { selectTextLabels } from '../../store/slices/textLabelSlice';
import { useDebounce } from '../../shared/hooks/useDebounce';

const Settings = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');

  const [jsonString, setJsonString] = useState<string>('');
  const debouncedQuery = useDebounce(jsonString, 600);

  const onChange = (value: object) => {
    setJsonString(JSON.stringify(value));
  };

  const labels = useSelector(selectTextLabels);
  const [getLabels] = textLabelsApi.useLazyGetLabelsQuery();

  useEffect(() => {
    getLabels({});
  }, []);

  const languages = useMemo(() => {
    return labels.map((label) => label.language);
  }, [labels]);

  useEffect(() => {
    if (!selectedLanguage && languages.length > 0) {
      setSelectedLanguage(languages[0]);
    }
  }, [languages]);

  const language = useMemo(() => {
    return labels.find((lang) => lang.language === selectedLanguage);
  }, [labels, selectedLanguage]);

  const [updateLabel, { isLoading }] =
    textLabelsApi.useUpdateTextLabelMutation();

  const selectLanguage = (lang: string) => () => {
    getLabels({});
    setSelectedLanguage(lang);
  };

  useEffect(() => {
    if (language) {
      updateLabel({
        textLabelId: language.id,
        language: selectedLanguage,
        jsonString: debouncedQuery,
      });
    }
  }, [debouncedQuery]);

  return (
    <MainLayout>
      <Box
        backgroundColor={'white'}
        padding={'1rem'}
        boxShadow="md"
        borderRadius={'md'}
      >
        <Text>Translations</Text>

        <StyledButtonsWrapper>
          {languages.map((lang) => (
            <Button
              colorScheme={(selectedLanguage === lang && 'blue') || 'gray'}
              onClick={selectLanguage(lang)}
              key={lang}
            >
              {lang}
            </Button>
          ))}
          <Spacer />
          {isLoading && <Spinner />}
        </StyledButtonsWrapper>
        {(language && (
          <StyledJSONInput>
            {/*@ts-ignore*/}
            <ReactJsonEditor values={JSON.parse(language.jsonString)} onChange={onChange} />
          </StyledJSONInput>
        )) || (
          <Flex justifyContent={'center'} h={'4rem'}>
            <Spinner />
          </Flex>
        )}
      </Box>
    </MainLayout>
  );
};

export default Settings;
