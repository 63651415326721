import { Profile } from './profile';
import { Tag, TagLabelType } from './tag';

export type RoleInAgreement = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdById: string;
  updatedById: string;
  createdByFullName: string;
  updatedByFullName: string;
  tagId: string;
  tag: Tag & { labelType: TagLabelType.DP_ROLE };
  deliveryProfileId: string;
  value: string;
};

export enum DeliveryProfileStatus {
  DRAFT = 'DRAFT',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  WITHDRAWN = 'WITHDRAWN',
  SIGNATURE_REQUEST_EXPIRED = 'SIGNATURE_REQUEST_EXPIRED',
}

export enum DeliveryProfileRole {
  CREATOR = 'CREATOR',
  APPROVER = 'APPROVER',
  OTHER = 'OTHER',
}

export type DeliveryProfile = Pick<
  Profile,
  'fullName' | 'companyName' | 'email'
> & {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdById: string;
  updatedById: string;
  createdByFullName: string;
  updatedByFullName: string;
  deliveryId: string;
  profileId: string;
  status: DeliveryProfileStatus;
  role: DeliveryProfileRole;
  sentDate: string;
  approvedDate: string;
  ipAddress: string;
  rejectedDate: string;
  withdrawnDate: string;
  requestExpiredDate: string;
  roleInAgreement: RoleInAgreement[];
};
