import { SortOptions } from '../../shared/types/mixed';
import { SignupRequestStatus } from '../../shared/constants/signupRequestStatus';

export const statusOptions: SortOptions = [
  { value: '', label: 'Show all' },
  { value: SignupRequestStatus.awaiting, label: 'Awaiting approval' },
  { value: SignupRequestStatus.approved, label: 'Approved' },
  { value: SignupRequestStatus.rejected, label: 'Rejected' },
];

export const statusToColor = (status: SignupRequestStatus) =>
  ({
    [SignupRequestStatus.awaiting]: 'orange',
    [SignupRequestStatus.approved]: 'green',
    [SignupRequestStatus.rejected]: 'red',
  }[status]);
