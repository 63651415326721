import React from 'react';
import { Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Company } from '../../shared/types/company';
import InfoValue from '../../shared/components/InfoValue';
import { toPrettyDate } from "../../shared/utils/utils";

interface ICompanyInfo {
  company: Company;
}

const CompanyInfo: React.FC<ICompanyInfo> = ({ company }) => {
  return (
    <>
      <InfoValue value={company?.name} label={'Name'} />
      <InfoValue
        value={company?.organizationalNumber}
        label={'Organisation number'}
      />
      <InfoValue value={company?.address} label={'Address'} />
      <InfoValue value={company?.postalCode} label={'Postal Code'} />
      <InfoValue value={company?.city} label={'City'} />
      <InfoValue value={company?.country} label={'Country'} />
      <InfoValue value={company?.phoneNumber} label={'Phone Number'} />
      <InfoValue
        value={
          company?.website && (
            <Link href={company?.website} isExternal>
              {company?.website} <ExternalLinkIcon mx="2px" />
            </Link>
          )
        }
        label={'Website'}
      />
      <InfoValue value={toPrettyDate(company?.createdAt)} label={'Created'} />
    </>
  );
};

export default CompanyInfo;
