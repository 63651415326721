import { baseApi } from './BaseService';
import {
  GetAllUsagesResponse,
  GetUsageByIdResponse,
  UpdateUsageByIdRequest,
} from '../responseTypes/usage';

const baseUrl = 'admin/usages';

export const usagesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllUsages: build.query<GetAllUsagesResponse, {
      sorting: string,
      order: string,
      limit: number,
      offset: number,
      query?: string,
    }>({
      query: (params) => ({
        url: `${baseUrl}/paginated`,
        params
      }),
    }),
    getUsageById: build.query<GetUsageByIdResponse, { usageId: string }>({
      query: ({ usageId }) => ({
        url: `${baseUrl}/${usageId}`,
      }),
      providesTags: ['Usage'],
    }),
    updateUsageById: build.mutation<
      GetUsageByIdResponse,
      { usageId: string; data: UpdateUsageByIdRequest }
    >({
      query: ({ usageId, data }) => ({
        url: `${baseUrl}/${usageId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Usage'],
    }),
  }),
});
