import { DeliveryProcess } from './deliveryProcess';
import { DeliveryBrief } from './deliveryBrief';
import { DeliveryPreview } from './deliveryPreview';
import { DeliveryCreatorProfile } from './deliveryCreatorProfile';
import { DeliveryCompany } from './deliveryCompany';
import { DeliveryFeeLines } from './deliveryFee';
import { DeliveryUsage } from './deliveryUsage';
import { DeliverySection } from './deliverySection';
import { DeliveryProfile } from './deliveryProfile';
import { DeliveryRequest } from './deliveryRequest';
import { DeliveryTerms } from './deliveryTerms';

export enum DeliveryStatus {
  DRAFT = 'DRAFT',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  WITHDRAWN = 'WITHDRAWN',
  REPLACED = 'REPLACED',
  SIGNATURE_REQUEST_EXPIRED = 'SIGNATURE_REQUEST_EXPIRED',
}

export type Delivery = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdById: string;
  updatedById: string;
  createdByFullName: string;
  updatedByFullName: string;
  projectId: string;
  lastActorFullName: string;
  lastActionDate: string;
  status: DeliveryStatus;
  deliveryProcess: DeliveryProcess;
  deliveryBrief: DeliveryBrief;
  deliveryPreview: DeliveryPreview;
  creatorProfile: DeliveryCreatorProfile;
  creatorProfileId: string;
  creatorCompany: DeliveryCompany;
  creatorCompanyId: string;
  companiesWithAccess: DeliveryCompany[];
  feeLines: DeliveryFeeLines;
  usages: DeliveryUsage[];
  deliverySections: DeliverySection[];
  deliveryProfiles: DeliveryProfile[];
  deliveryRequests: DeliveryRequest[];
  terms: DeliveryTerms;
  description: string;
  title: string;
  principal: string;
  endClient: string;
};
