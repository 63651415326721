import React from 'react';
import { Button } from '@chakra-ui/react';

interface IFormButtonUpdate {
  isLoading: boolean;
}

const FormButtonUpdate: React.FC<IFormButtonUpdate> = ({ isLoading }) => {
  return (
    <Button
      colorScheme="orange"
      type={'submit'}
      isLoading={isLoading}
      w={'max-content'}>
      Update
    </Button>
  );
};

export default FormButtonUpdate;
