import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import RootRouter from './router/RootRouter';

// Theme
import theme from './shared/styles/theme';
import globalTheme from './shared/styles/global'
import { Global } from '@emotion/react';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Global styles={globalTheme} />
      <BrowserRouter>
        <RootRouter />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
