import React from 'react';
import { Flex } from '@chakra-ui/react';
import { StyledSectionButton } from '../styled';

const SectionsSelector = ({
  sections,
  selectedSections,
  setSelectedSections,
}: {
  sections: string[];
  selectedSections: string[];
  setSelectedSections: Function;
}) => {
  const isSelected = (section: string) => selectedSections.includes(section);

  const selectSection = (section: string) => () => {
    if (isSelected(section)) {
      setSelectedSections(selectedSections.filter((sect) => sect !== section));
    } else {
      setSelectedSections([...selectedSections, section]);
    }
  };

  return (
    <>
      <p style={{ marginBottom: '0.5rem' }}>Select available sections</p>
      <Flex gridGap={'0.5rem'} wrap={'wrap'}>
        {sections.map((section, i) => (
          <StyledSectionButton
            key={`${section}-${i}`}
            onClick={selectSection(section)}
            selected={isSelected(section)}
          >
            {section}
          </StyledSectionButton>
        ))}
      </Flex>
    </>
  );
};

export default SectionsSelector;
