import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { EMPTY_VALUE } from '../constants';

interface IInfoValue {
  label: string;
  value: string | number | React.ReactNode | undefined;
}

const InfoValue: React.FC<IInfoValue> = ({ label, value }) => {
  return (
    <Flex mt={4}>
      <Text fontWeight={500} pr={2}>
        {label}:
      </Text>
      {value || EMPTY_VALUE}
    </Flex>
  );
};

export default InfoValue;
