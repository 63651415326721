import { SortOptions } from '../../shared/types/mixed';
import { TemplateCreation } from '../../shared/types/template';

export const creationOptions: SortOptions = [
  { value: 'all', label: 'Show all' },
  { value: TemplateCreation.CORIGHT, label: "Show Coright's" },
  { value: TemplateCreation.CUSTOM, label: 'Show custom' },
];

export const deliveryTypeOptions: SortOptions = [
  { value: 'DEFAULT', label: 'Default' },
  { value: 'USAGE_RIGHTS', label: 'Usage rights' },
  { value: 'APPROVED_OUTSIDE', label: 'Approved outside' },
  { value: 'PDF', label: 'PDF' },
];
