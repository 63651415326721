import { SortOptions } from '../../shared/types/mixed';
import { SortTypes } from '../../shared/constants/mixed';
import { Company } from '../../shared/types/company';

export const sortOptions: SortOptions = [
  { value: '', label: 'Default' },
  { value: SortTypes.nameAsc, label: 'Name A-Z' },
  { value: SortTypes.nameDesc, label: 'Name Z-A' },
  { value: SortTypes.createdAt, label: 'Newest' },
  { value: SortTypes.updatedAt, label: 'Recent' },
];

export const compareByNameAsc = (a: Company, b: Company) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

export const compareByNameDesc = (a: Company, b: Company) => {
  if (a.name > b.name) return -1;
  if (a.name < b.name) return 1;
  return 0;
};

export const compareByCreation = (a: Company, b: Company) => {
  if (a.createdAt > b.createdAt) return -1;
  if (a.createdAt < b.createdAt) return 1;
  return 0;
};

export const compareByUpdate = (a: Company, b: Company) => {
  if (a.updatedAt > b.updatedAt) return -1;
  if (a.updatedAt < b.updatedAt) return 1;
  return 0;
};
