import React  from 'react';
import { Tag } from '../../shared/types/tag';
import TagForm from './TagForm';
import TagInfo from './TagInfo';
import Wrapper from '../../shared/components/Wrapper';
import { useEditMode } from '../../shared/hooks/useEditMode';
import EditModeSwitcher from '../../shared/components/EditModeSwitcher';

interface ITagData {
  tag: Tag;
}

const TagData: React.FC<ITagData> = ({ tag }) => {
  const { edit, toggleEditMode, setEdit } = useEditMode();

  return (
    <Wrapper>
      <>
        <EditModeSwitcher toggleEditMode={toggleEditMode} edit={edit} />
        {(edit && <TagForm tag={tag} setEdit={setEdit} />) || (
          <TagInfo tag={tag} />
        )}
      </>
    </Wrapper>
  );
};

export default TagData;
