import { baseApi } from './BaseService';
import {
  DeletionRequest,
  DeletionRequestsResponse,
  DeletionRequestTypes,
} from '../../shared/types/deletionRequests';

const baseUrl = 'admin/deletion_requests';

export const deletionRequestsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDeletionRequests: build.query<
      DeletionRequestsResponse,
      {
        limit: number;
        offset: number;
        query?: string;
        status?: string;
        type?: DeletionRequestTypes;
      }
    >({
      query: (params) => ({
        url: `${baseUrl}`,
        params,
      }),
    }),
    processDeletionRequest: build.mutation<
      { request: DeletionRequest },
      { requestId: string }
    >({
      query: ({ requestId }) => ({
        url: `${baseUrl}/${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: ['DeletionRequest'],
    }),
  }),
});
