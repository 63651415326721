export enum DeletionRequestTypes {
  profile = 1,
  company = 2,
}

export enum DeletionRequestStatus {
  PENDING = 1,
  PROCESSING = 2,
  PROCESSED = 3,
}
export type DeletionRequest = {
  id: string;
  assignedEmail: string;
  createdAt: string;
  idToDelete: string;
  linkedCompanyId: string;
  reason: string;
  status: DeletionRequestStatus;
  type: DeletionRequestTypes;
  updatedAt: string;
};

export type DeletionRequestsResponse = {
  currentLimit: number;
  currentOffset: number;
  totalEntities: number;
  requests: DeletionRequest[];
};
