import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useDisclosure,
  Textarea,
  Switch,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { StyledNamesWrapper, StyledSwitchesWrapper } from './styled';
import { pricingPlansApi } from '../../store/services/PricingPlansService';
import { PricingPlan, PricingPlanLimit } from '../../shared/types/pricingPlan';
import LimitTypes from './components/LimitTypes';
import { defaultPlans } from './constants';
import FormInput from '../../shared/components/FormInput';

type IPlanModal = {
  plan?: PricingPlan | null;
  activator: React.ReactNode;
};

type FormDataType = {
  name: string;
  description?: string;
  nameNo: string;
  descriptionNo?: string;
  isActive: boolean;
  isPublic: boolean;
  cost: string;
  position: number;
  limits: PricingPlanLimit[];
};

const PlanEditModal = ({ plan = null, activator }: IPlanModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updatePlanById, { isLoading, isSuccess }] =
    pricingPlansApi.useUpdatePlanByIdMutation();
  const [createPlan, { isLoading: isCreating, isSuccess: isCreated }] =
    pricingPlansApi.useCreatePlanMutation();
  const [limits, setLimits] = useState<PricingPlanLimit[]>([]);

  useEffect(() => {
    setLimits(
      defaultPlans.map((limit) => {
        return (
          plan?.limits?.find((planLimit) => planLimit.type === limit.type) ||
          limit
        );
      }),
    );
  }, []);

  const { register, reset, handleSubmit } = useForm<FormDataType>();

  useEffect(() => {
    if (isOpen) {
      reset({
        name: plan?.name ?? '',
        nameNo: plan?.nameNo ?? '',
        cost: plan?.cost ?? '0',
        description: plan?.description ?? '',
        descriptionNo: plan?.descriptionNo ?? '',
        isActive: plan?.isActive ?? false,
        isPublic: plan?.isPublic ?? false,
        position: plan?.position ?? 0,
      });
    }
  }, [isOpen, plan]);

  const onSubmit: SubmitHandler<FormDataType> = (args) => {
    const data = { ...args, limits };
    if (plan)
      updatePlanById({
        planId: plan.id,
        data: { ...data, position: Number(data.position) },
      });
    else createPlan({ data: { ...data, position: Number(data.position) } });
  };

  useEffect(() => {
    if (isCreated || isSuccess) onClose();
  }, [isCreated, isSuccess]);

  return (
    <>
      <div role={'button'} onClick={onOpen}>
        {activator}
      </div>

      <Modal size={'xl'} {...{ onClose, isOpen }}>
        <ModalOverlay />
        <ModalContent mx={'1rem'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              {(!!plan && 'Edit plan') || 'Create plan'}
            </ModalHeader>
            <ModalBody>
              <StyledNamesWrapper>
                <div>
                  <label>Name</label>
                  <Input
                    placeholder={'Enter plan name'}
                    mb={'0.5rem'}
                    isRequired
                    {...register('name')}
                  />

                  <label>Description</label>
                  <Textarea
                    placeholder={'Enter plan description (optional)'}
                    mb={'0.5rem'}
                    {...register('description')}
                  />
                </div>
                <div>
                  <label>Name NOR</label>
                  <Input
                    placeholder={'Enter NOR plan name'}
                    mb={'0.5rem'}
                    isRequired
                    {...register('nameNo')}
                  />

                  <label>Description NOR</label>
                  <Textarea
                    placeholder={'Enter NOR plan description (optional)'}
                    mb={'0.5rem'}
                    {...register('descriptionNo')}
                  />
                </div>
              </StyledNamesWrapper>
              <StyledNamesWrapper>
                <div>
                  <label>Cost</label>
                  <FormInput name={'cost'} register={register} />
                </div>
                <div>
                  <label>Order position</label>
                  <FormInput
                    type={'number'}
                    name={'position'}
                    register={register}
                  />
                </div>
              </StyledNamesWrapper>

              <LimitTypes limits={limits} setLimits={setLimits} />

              <StyledSwitchesWrapper>
                <div>
                  <Switch mr={'1rem'} {...register('isActive')} />
                  <label>Active</label>
                </div>
                <div>
                  <Switch mr={'1rem'} {...register('isPublic')} />
                  <label>Public</label>
                </div>
              </StyledSwitchesWrapper>
            </ModalBody>
            <ModalFooter>
              <Flex gridGap={'0.5rem'} justifyContent={'flex-end'}>
                <Button type={'button'} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme={'blue'}
                  type={'submit'}
                  isLoading={isLoading || isCreating}
                >
                  Save
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PlanEditModal;
