import React from 'react';
import { Link, useMatch, useLocation } from 'react-router-dom';
import { Text, Icon, Flex } from '@chakra-ui/react';
import { BsFillRecordFill } from 'react-icons/bs';
import { Paths } from '../../router/routes';

// TODO: fix any type

interface INavLink {
  to: Paths;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
}

const NavLink: React.FC<INavLink> = ({ to, label, icon }) => {
  const { pathname } = useLocation();

  const match = pathname.includes(to);

  return (
    <Link to={to}>
      <Flex alignItems={'center'}>
        <Icon
          as={icon || BsFillRecordFill}
          mr={4}
          opacity={(match && 1) || 0.5}
        />
        <Text
          color={(match && 'gray.900') || 'gray.500'}
          fontWeight={(match && 500) || 400}>
          {label}
        </Text>
      </Flex>
    </Link>
  );
};

export default NavLink;
