import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import InfoValue from '../../shared/components/InfoValue';
import { DeliveryFeeLines } from '../../shared/types/deliveryFee';

interface IDeliveryFees {
  feeLines: DeliveryFeeLines;
}

const DeliveryFees: React.FC<IDeliveryFees> = ({ feeLines }) => {
  return (
    <Accordion
      backgroundColor={'white'}
      mt={4}
      allowMultiple
      boxShadow="md"
      borderRadius={'md'}
    >
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Fees
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Table>
            <Thead>
              <Tr>
                <Th>Description</Th>
                <Th>Price</Th>
                <Th>Quantity</Th>
                <Th>Tax</Th>
                <Th>Discount</Th>
                <Th>Comment</Th>
              </Tr>
            </Thead>
            <Tbody>
              {feeLines?.fees?.length &&
                feeLines.fees.map((fee) => (
                  <Tr key={fee.id}>
                    <Td>{fee.description}</Td>
                    <Td>{fee.price}</Td>
                    <Td>{fee.quantity}</Td>
                    <Td>{fee.tax}</Td>
                    <Td>{fee.discount}</Td>
                    <Td>{fee.comment}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
          <SimpleGrid gap={4}>
            <InfoValue value={feeLines?.taxes ?? 0} label={'Taxes'} />
            <InfoValue value={feeLines?.total ?? 0} label={'Total'} />
            <InfoValue value={feeLines?.subtotal ?? 0} label={'Sub total'} />
          </SimpleGrid>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default DeliveryFees;
