import React from 'react';
import { Tag } from '../../shared/types/tag';
import InfoValue from "../../shared/components/InfoValue";
import { toPrettyDate } from "../../shared/utils/utils";

interface ITagInfo {
  tag: Tag;
}

const TagInfo: React.FC<ITagInfo> = ({ tag }) => {
  return (
    <>
      <InfoValue value={tag?.type} label={'Type'} />
      <InfoValue value={tag?.creation} label={'Creation'} />
      <InfoValue value={tag?.labelType} label={'Label type'} />
      <InfoValue value={tag?.label} label={'Label'} />
      <InfoValue value={tag?.value} label={'Value'} />
      <InfoValue value={toPrettyDate(tag?.createdAt)} label={'Created'} />
      <InfoValue value={toPrettyDate(tag?.updatedAt)} label={'Updated'} />
    </>
  );
};

export default TagInfo;
