import { baseApi } from './BaseService';
import {
  CreateEmailNotificationRequest,
  GetEmailNotificationByIdResponse,
  GetEmailNotificationsResponse, UpdateEmailNotificationRequest
} from "../responseTypes/emailNotifications";

const baseUrl = 'admin/email_notifications';

export const emailNotificationsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query<
      GetEmailNotificationsResponse,
      {
        limit: number;
        offset: number;
        query?: string;
      }
    >({
      query: (params) => ({
        url: `${baseUrl}/paginated`,
        params,
      }),
    }),

    createNotification: build.mutation<
      GetEmailNotificationByIdResponse,
      { data: CreateEmailNotificationRequest }
    >({
      query: ({ data }) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['EmailNotification'],
    }),
    updateNotificationById: build.mutation<
      GetEmailNotificationByIdResponse,
      { emailNotificationId: string; data: UpdateEmailNotificationRequest }
    >({
      query: ({ emailNotificationId, data }) => ({
        url: `${baseUrl}/${emailNotificationId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['EmailNotification'],
    }),
    deleteNotificationById: build.mutation<string, { emailNotificationId: string }>({
      query: ({ emailNotificationId }) => ({
        url: `${baseUrl}/${emailNotificationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EmailNotification'],
    }),
  }),
});
