import React from 'react';
import { isSidebar } from "../utils/utils";

export type IAppContext = {
  sidebar: boolean;
  setSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

export const defaultState = {
  sidebar: isSidebar(),
  setSidebar: () => {},
};

export const AppContext = React.createContext<IAppContext>(defaultState);

export const AppContextProvider = AppContext.Provider;
