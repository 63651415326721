export enum EmailNotificationTypes {
  DELIVERY_APPROVED = 'DELIVERY_APPROVED',
  DELIVERY_APPROVED_VIEWER = 'DELIVERY_APPROVED_VIEWER' ,
  WITHDRAWN = 'WITHDRAWN',
  CHANGED_PASSWORD = 'CHANGED_PASSWORD',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  USAGE_ABOUT_TO_EXPIRE = 'USAGE_ABOUT_TO_EXPIRE',
  USAGE_EXPIRED = 'USAGE_EXPIRED',
  DELIVERY_APPROVAL_REQUEST = 'DELIVERY_APPROVAL_REQUEST',
  DELIVERY_VIEW_REQUEST = 'DELIVERY_VIEW_REQUEST',
  NON_USER_ACCESS = 'NON_USER_ACCESS',
  ONE_DAY_REMINDER = 'ONE_DAY_REMINDER',
  THREE_DAYS_REMINDER = 'THREE_DAYS_REMINDER',
  DONE_EDITING_CONTRACT = 'DONE_EDITING_CONTRACT',
  IGNORED_REMINDER = 'IGNORED_REMINDER',
  SIGNATURE_REJECTED = 'SIGNATURE_REJECTED',
  SIGNATURE_REQUEST_EXPIRED = 'SIGNATURE_REQUEST_EXPIRED',
  PROFILE_ACCESS_INVITATION = 'PROFILE_ACCESS_INVITATION',
  COMPANY_ACCESS_INVITATION = 'COMPANY_ACCESS_INVITATION',
  COMPANY_INVITATION = 'COMPANY_INVITATION',
  REQUEST_ACCESS = 'REQUEST_ACCESS'
}

export type EmailNotification = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdById: string;
  updatedById: string;
  createdByFullName: string;
  updatedByFullName: string;
  type: EmailNotificationTypes,
  sentDate: string,
  templateId: string
  email: string
  data: string
};
