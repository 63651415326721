export const API_URL = `${
  process.env.REACT_APP_BASE_API_URL || '' || 'https://api.dev.coright.no'
}`;

export const EMPTY_VALUE = '———';

export const All = 'All';

export const ASC = 'ASC';
export const DESC = 'DESC';

export const SORTING_DEFAULT = 'default';

export const PROFILES_SORTING_CREATION_DATE = 'creation_date';
export const PROFILES_SORTING_FULL_NAME = 'full_name';
export const PROFILES_SORTING_EMAIL = 'email';

export const TAGS_SORTING_CREATION_DATE = 'creation_date';
