import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UsageResponse } from '../responseTypes/usage';
import { usagesApi } from '../services/UsagesService';

interface IUsagesSlice {
  currentLimit: number | null,
  currentOffset: number | null,
  totalEntities: number | null,
  usages: UsageResponse[],
}

const initialState: IUsagesSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  usages: [],
}

const slice = createSlice({
  name: 'usages',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addMatcher(
      usagesApi.endpoints.getAllUsages.matchFulfilled,
      (state: IUsagesSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { offset } = args
        if (offset === 0) return { ...payload, usages: payload.usages }
        return { ...payload, usages: [...state.usages, ...payload.usages] }
      },
    )
  },
});

export default slice.reducer;

export const selectUsages = (state: RootState) => state.usages.usages;
export const selectUsagesTotalEntities = (state: RootState) => state.usages.totalEntities;
export const selectUsagesHasMore = (state: RootState) => {
  const total = state.usages.totalEntities
  return total === null || (total > 0 && state.usages.usages.length < total)
};
