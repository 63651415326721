import React, { useEffect, useMemo, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import {
  Badge,
  Button,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import InfiniteScroll from '../../shared/components/custom/InfiniteScroll/InfiniteScroll';
import { useListFilters } from '../../shared/hooks/useListFilters';
import SortableTh from '../../shared/components/table/SortableTH';
import { toPrettyDate } from '../../shared/utils/utils';
import {
  StyledActionsRow,
  StyledFiltersWrapper,
  StyledRowWithDescription,
  StyledTr,
} from './styled';
import ResponsiveTable from '../../shared/components/table/ResponsiveTable';
import Card from '../../shared/components/ui/Card/Card';
import Input from '../../shared/components/ui/Input/Input';
import { useDebounce } from '../../shared/hooks/useDebounce';
import { useSelector } from 'react-redux';
import AlertDialog from '../../shared/components/custom/AlertDialog/AlertDialog';
import { SORTING_CREATED_AT } from './constants';
import { pricingPlansApi } from '../../store/services/PricingPlansService';
import {
  selectPricingPlans,
  selectPricingPlansHasMore,
} from '../../store/slices/pricingPlansSlice';
import PlanEditModal from './PlanEditModal';

const PricingPlans = () => {
  const [limit] = useState(20);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [trigger] = pricingPlansApi.useLazyGetPlansQuery();
  const plans = useSelector(selectPricingPlans);
  const hasMore = useSelector(selectPricingPlansHasMore);
  const { sorting, sortBy, order, orderBy } = useListFilters();
  const [deletePlanById] = pricingPlansApi.useDeletePlanByIdMutation();
  const [makeDefault] = pricingPlansApi.useMakePlanDefaultMutation();
  const [isWithHidden, setIsWithHidden] = useState(false);

  const availablePlans = useMemo(
    () => (!isWithHidden && plans.filter((plan) => !plan.isDeleted)) || plans,
    [plans, isWithHidden],
  );
  const toggleHidden = () => {
    setIsWithHidden(!isWithHidden);
  };

  const onDelete = (planId: string) => () => {
    deletePlanById({ planId });
  };

  const onDefault = (planId: string) => () => {
    makeDefault({ planId });
  };

  const loadPlans = (fresh: boolean = false) => {
    trigger({
      limit,
      offset: (!fresh && plans.length) || 0,
      sorting,
      order,
      query: debouncedQuery,
    });
  };

  useEffect(() => {
    loadPlans(true);
  }, [debouncedQuery, sorting, order]);

  return (
    <MainLayout>
      <StyledFiltersWrapper>
        <Input name={'query'} placeholder={'Search...'} onChange={setQuery} />
        <Button
          colorScheme={(isWithHidden && 'red') || 'gray'}
          onClick={toggleHidden}
        >
          {(!isWithHidden && 'Show removed') || 'Hide removed'}
        </Button>
        <div />
        <PlanEditModal activator={<Button colorScheme="blue">Create</Button>} />
      </StyledFiltersWrapper>

      <InfiniteScroll
        dataLength={plans.length}
        next={loadPlans}
        hasMore={hasMore}
      >
        <Card noPadding>
          <ResponsiveTable>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Cost</Th>
                <Th>Status</Th>
                <Th>Visibility</Th>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={SORTING_CREATED_AT}
                >
                  Created at
                </SortableTh>
                <Th style={{ width: 0 }} />
              </Tr>
            </Thead>
            <Tbody>
              {availablePlans.map((plan) => (
                <StyledTr key={plan.id} isDeleted={plan.isDeleted}>
                  <StyledRowWithDescription>
                    <p>
                      {plan.name}{' '}
                      {plan.position !== null && `(#${plan.position})`}
                    </p>
                    <Text noOfLines={1}>{plan.description}</Text>
                  </StyledRowWithDescription>
                  <Td>{plan.cost}</Td>
                  <Td>
                    <Badge colorScheme={(plan.isActive && 'green') || 'gray'}>
                      {(plan.isActive && 'Active') || 'Inactive'}
                    </Badge>
                  </Td>
                  <Td>
                    <Badge colorScheme={(plan.isPublic && 'blue') || 'gray'}>
                      {(plan.isPublic && 'Public') || 'Hidden'}
                    </Badge>
                  </Td>
                  <Td>{toPrettyDate(plan.createdAt)}</Td>
                  <Td>
                    <StyledActionsRow>
                      <AlertDialog
                        title={`Are you sure you want to set plan "${plan.name}" as default?`}
                        confirmLabel={'Set as default'}
                        confirmColorScheme={'green'}
                        onConfirm={onDefault(plan.id)}
                        activator={
                          <Button
                            size={'sm'}
                            colorScheme={(plan.isDefault && 'green') || 'gray'}
                          >
                            {(plan.isDefault && 'Default') || 'Set as default'}
                          </Button>
                        }
                      />
                      <PlanEditModal
                        activator={<Button size={'sm'}>Edit</Button>}
                        plan={plan}
                      />
                      <AlertDialog
                        title={`Are you sure you want to delete plan "${plan.name}"`}
                        confirmLabel={'Delete'}
                        confirmColorScheme={'red'}
                        onConfirm={onDelete(plan.id)}
                        activator={
                          <Button size={'sm'} colorScheme={'red'}>
                            Delete
                          </Button>
                        }
                      />
                    </StyledActionsRow>
                  </Td>
                </StyledTr>
              ))}
            </Tbody>
          </ResponsiveTable>
        </Card>
      </InfiniteScroll>
    </MainLayout>
  );
};

export default PricingPlans;
