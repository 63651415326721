import React, { useEffect, useMemo, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import { usagesApi } from '../../store/services/UsagesService';
import { toPrettyDate } from '../../shared/utils/utils';
import SortBlock from "../../shared/components/SortBlock";
import { expirationTypeOptions, statusOptions } from "./helpers";
import { useDebounce } from '../../shared/hooks/useDebounce';
import { useSelector } from 'react-redux';
import { useListFilters } from '../../shared/hooks/useListFilters';
import { selectUsages } from '../../store/slices/usagesSlice';
import { selectUsagesHasMore } from '../../store/slices/usagesSlice';
import Input from '../../shared/components/ui/Input/Input';
import { StyledFiltersWrapper, StyledActionsRow } from './styled';
import Card from '../../shared/components/ui/Card/Card';
import ResponsiveTable from '../../shared/components/table/ResponsiveTable';
import InfiniteScroll from '../../shared/components/custom/InfiniteScroll/InfiniteScroll';
import SortableTh from '../../shared/components/table/SortableTH';
import { USAGES_SORTING_EXPIRATION_DATE } from './constants';

const Usages = () => {
  const [limit] = useState(20);
  const [status, setStatus] = useState('');
  const [expirationType, setExpirationType] = useState('');
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [trigger]  = usagesApi.useLazyGetAllUsagesQuery();
  const usages = useSelector(selectUsages)
  const hasMore = useSelector(selectUsagesHasMore)
  const { sorting, sortBy, order, orderBy } = useListFilters()

  const loadUsages = (reload: boolean = false) => {
    trigger({
      limit,
      offset: !reload && usages.length || 0,
      sorting,
      order,
      query: debouncedQuery
    })
  }

  useEffect(() => {
    loadUsages(true)
  }, [debouncedQuery, sorting, order]);

  const filteredUsages = useMemo(() => {
    let filtered = usages;
    if (status) filtered = filtered.filter(usage => usage.status === status)
    if (expirationType) filtered = filtered.filter(usage => usage.expirationType === expirationType)

    return filtered
  }, [expirationType, status, usages]);

  useEffect(() => {
    if (filteredUsages.length < limit) loadUsages()
  }, [filteredUsages]);

  return (
    <MainLayout>

      <StyledFiltersWrapper>
        <SortBlock
          value={status}
          onChange={setStatus}
          options={statusOptions}
        />
        <SortBlock
          value={expirationType}
          onChange={setExpirationType}
          options={expirationTypeOptions}
        />
        <div />
        <Input name={'query'} placeholder={'Search...'} onChange={setQuery} />
      </StyledFiltersWrapper>

      <InfiniteScroll dataLength={usages.length} next={loadUsages} hasMore={hasMore}>
        <Card noPadding>
          <ResponsiveTable>
            <Thead>
              <Tr>
                <Th>Status</Th>
                <Th>Type</Th>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={USAGES_SORTING_EXPIRATION_DATE}
                >
                  Status
                </SortableTh>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {filteredUsages.map((usage) => (
                <Tr key={usage.id}>
                  <Td>{usage.status}</Td>
                  <Td>{usage.expirationType}</Td>
                  <Td>{usage.expiryDate && toPrettyDate(usage.expiryDate)}</Td>
                  <Td style={{ width: 0 }}>
                    <StyledActionsRow>
                      <ButtonLink color={'blue'} to={`${Paths.usages}/${usage.id}`}>
                        Open
                      </ButtonLink>
                      <ButtonLink
                        color={'blue'}
                        to={`${Paths.deliveries}/${usage.deliveryId}`}
                      >
                        Show contract
                      </ButtonLink>
                    </StyledActionsRow>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </ResponsiveTable>
        </Card>
      </InfiniteScroll>
    </MainLayout>
  );
};

export default Usages;
