import styled from "@emotion/styled";
import { Flex, Grid, Text } from '@chakra-ui/react';
import { themeColorPicker, themeValue } from "../../shared/utils/themeSelectors";

export const StyledActionsRow = styled(Flex)`
  align-items: center;
  grid-gap: 0.5rem;
`;

export const StyledEndClient = styled(Text)`
  font-size: ${themeValue('fontSizes')('sm')};
  color: ${themeColorPicker('gray05')};
`;

export const StyledFiltersWrapper = styled(Grid)`
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: auto 1fr 1fr;

  @media (max-width: ${themeValue('media')('m')}) {
    grid-template-columns: auto 1fr;

    > div:nth-of-type(2) {
      display: none;
    }
  }
`;