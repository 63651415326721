import React, { ChangeEvent } from 'react';
import { StyledInput } from './styled';

type IInput = {
  name: string;
  placeholder?: string;
  onChange: Function;
}

const Input = ({ name, placeholder, onChange }: IInput) => {
  const changeValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange(target.value)
  }

  return (
    <StyledInput onChange={changeValue} placeholder={placeholder} name={name} />
  );
};

export default Input;