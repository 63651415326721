import React, { useEffect, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { Button, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import InfiniteScroll from '../../shared/components/custom/InfiniteScroll/InfiniteScroll';
import { toPrettyDate } from '../../shared/utils/utils';
import { StyledActionsRow, StyledFiltersWrapper } from './styled';
import ResponsiveTable from '../../shared/components/table/ResponsiveTable';
import Card from '../../shared/components/ui/Card/Card';
import Input from '../../shared/components/ui/Input/Input';
import { useDebounce } from '../../shared/hooks/useDebounce'
import EditNotificationModal from './EditNotificationModal';
import { useSelector } from 'react-redux';

import AlertDialog from '../../shared/components/custom/AlertDialog/AlertDialog';
import { emailNotificationsApi } from "../../store/services/EmailNotificationsService";
import { selectEmailNotifications, selectEmailNotificationsHasMore } from "../../store/slices/emailNotificationsSlice";

const EmailNotifications = () => {
  const [limit] = useState(20);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [trigger] = emailNotificationsApi.useLazyGetNotificationsQuery();
  const notifications = useSelector(selectEmailNotifications);
  const hasMore = useSelector(selectEmailNotificationsHasMore);
  const [deleteNotificationById] = emailNotificationsApi.useDeleteNotificationByIdMutation();

  const onDelete = (emailNotificationId: string) => () => {
    deleteNotificationById({ emailNotificationId });
  };

  const loadNotifications = (fresh: boolean = false) => {
    trigger({
      limit,
      offset: (!fresh && notifications.length) || 0,
      query: debouncedQuery,
    });
  };

  useEffect(() => {
    loadNotifications(true);
  }, [debouncedQuery]);

  return (
    <MainLayout>
      <StyledFiltersWrapper>
        <Input name={'query'} placeholder={'Search...'} onChange={setQuery} />
        <div />
        <EditNotificationModal activator={<Button colorScheme="blue">Create</Button>} />
      </StyledFiltersWrapper>

      <InfiniteScroll
        dataLength={notifications.length}
        next={loadNotifications}
        hasMore={hasMore}
      >
        <Card noPadding>
          <ResponsiveTable>
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Type</Th>
                <Th>Date</Th>
                <Th style={{ width: 0 }} />
              </Tr>
            </Thead>
            <Tbody>
              {notifications.map((notification) => (
                <Tr key={notification.id}>
                  <Td>{notification.email}</Td>
                  <Td>{notification.type}</Td>
                  <Td>
                    <p>Created at <b>{toPrettyDate(notification.createdAt,  'MMM dd, yyyy hh:mm')}</b></p>
                    <p>Sent at <b>{toPrettyDate(notification.sentDate, 'MMM dd, yyyy hh:mm')}</b></p>
                  </Td>
                  <Td>
                    <StyledActionsRow>
                      <EditNotificationModal
                        notification={notification}
                        activator={<Button size={'sm'}>Edit</Button>}
                      />
                      <AlertDialog
                        title={`Are you sure you want to this notification`}
                        confirmLabel={'Delete'}
                        confirmColorScheme={'red'}
                        onConfirm={onDelete(notification.id)}
                        activator={<Button size={'sm'} colorScheme={'red'}>Delete</Button>}
                      />
                    </StyledActionsRow>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </ResponsiveTable>
        </Card>
      </InfiniteScroll>
    </MainLayout>
  );
};

export default EmailNotifications;
