export enum UserRoleType {
  ATTACHED_ROLE_TYPE_NOT_SET = 0,
  COMPANY = 1,
  PROFILE = 2,
  USER = 3,
}

export type UserRole = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  globalAttributes: Record<string, string>;
  description: string;
};

export type AttachedUserRole = {
  id: string;
  createdAt: string;
  updatedAt: string;
  roleId: string;
  type: UserRoleType;
  customAttributes: Record<string, string>;
  companyId: string;
  userId: string;
  profileId: string;
  role: UserRole;
};
