import React, { useEffect, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { Button, Icon, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import InfiniteScroll from '../../shared/components/custom/InfiniteScroll/InfiniteScroll';
import { toPrettyDate } from '../../shared/utils/utils';
import { StyledActionsRow, StyledFiltersWrapper, StyledReason } from './styled';
import ResponsiveTable from '../../shared/components/table/ResponsiveTable';
import Card from '../../shared/components/ui/Card/Card';
import Input from '../../shared/components/ui/Input/Input';
import { useDebounce } from '../../shared/hooks/useDebounce';
import { useSelector } from 'react-redux';

import AlertDialog from '../../shared/components/custom/AlertDialog/AlertDialog';
import { deletionRequestsApi } from '../../store/services/DeletionRequestsService';
import {
  selectDeletionRequests,
  selectDeletionRequestsHasMore,
} from '../../store/slices/deletionRequestsSlice';
import { statusToIcon, statusToLabel, typeToLabel } from './helpers';
import { DeletionRequestStatus, DeletionRequestTypes } from '../../shared/types/deletionRequests';
import { Link } from 'react-router-dom';
import { Paths } from '../../router/routes';

const DeletionRequests = () => {
  const [limit] = useState(20);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [trigger] = deletionRequestsApi.useLazyGetDeletionRequestsQuery();
  const requests = useSelector(selectDeletionRequests);
  const hasMore = useSelector(selectDeletionRequestsHasMore);
  const [processRequest] =
    deletionRequestsApi.useProcessDeletionRequestMutation();

  const onProcess = (requestId: string) => () => {
    processRequest({ requestId });
  };

  const loadRequests = (fresh: boolean = false) => {
    trigger({
      limit,
      offset: (!fresh && requests.length) || 0,
      query: debouncedQuery,
    });
  };

  useEffect(() => {
    loadRequests(true);
  }, [debouncedQuery]);

  const isCompany = (requestType: DeletionRequestTypes) => {
    return requestType === DeletionRequestTypes.company
  }

  return (
    <MainLayout>
      <StyledFiltersWrapper>
        <Input name={'query'} placeholder={'Search...'} onChange={setQuery} />
        <div />
      </StyledFiltersWrapper>

      <InfiniteScroll
        dataLength={requests.length}
        next={loadRequests}
        hasMore={hasMore}
      >
        <Card noPadding>
          <ResponsiveTable>
            <Thead>
              <Tr>
                <Th>Info</Th>
                <Th>Type</Th>
                <Th>Status</Th>
                <Th>Date</Th>
                <Th style={{ width: 0 }} />
              </Tr>
            </Thead>
            <Tbody>
              {requests.map((request) => (
                <Tr key={request.id}>
                  <Td>
                    <div>
                      <Text>{request.assignedEmail}</Text>
                      <StyledReason>{request.reason}</StyledReason>
                    </div>
                  </Td>
                  <Td>{typeToLabel(request.type)}</Td>
                  <Td>
                    <Icon as={statusToIcon(request.status)} mr={'0.5rem'} />
                    <span>{statusToLabel(request.status)}</span>
                  </Td>
                  <Td>
                    <p>
                      Created at{' '}
                      <b>
                        {toPrettyDate(request.createdAt, 'MMM dd, yyyy hh:mm')}
                      </b>
                    </p>
                  </Td>
                  <Td>
                    <StyledActionsRow>
                      {request.status !== DeletionRequestStatus.PROCESSED && (
                        <>
                          <Link to={`${isCompany(request.type) && Paths.companies || Paths.profiles}/${request.idToDelete}`} target={'_blank'}>
                            <Button size={'sm'}>
                              Show {isCompany(request.type) && 'company' || 'profile'}
                            </Button>
                          </Link>
                          <AlertDialog
                            title={`Are you sure you want to process this request`}
                            confirmLabel={'Process'}
                            confirmColorScheme={'green'}
                            onConfirm={onProcess(request.id)}
                            activator={
                              <Button
                                size={'sm'}
                                isLoading={
                                  request.status ===
                                  DeletionRequestStatus.PROCESSING
                                }
                                colorScheme={'green'}
                              >
                                Process
                              </Button>
                            }
                          />
                        </>
                      )}
                    </StyledActionsRow>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </ResponsiveTable>
        </Card>
      </InfiniteScroll>
    </MainLayout>
  );
};

export default DeletionRequests;
