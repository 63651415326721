import React  from 'react';
import { DeliveryUsage } from '../../shared/types/deliveryUsage';
import { useEditMode } from '../../shared/hooks/useEditMode';
import Wrapper from '../../shared/components/Wrapper';
import EditModeSwitcher from '../../shared/components/EditModeSwitcher';
import UsageInfo from './UsageInfo';
import UsageForm from './UsageForm';

interface IUsageData {
  usage: DeliveryUsage;
}

const UsageData: React.FC<IUsageData> = ({ usage }) => {
  const { edit, toggleEditMode, setEdit } = useEditMode();

  return (
    <Wrapper>
      <>
        <EditModeSwitcher toggleEditMode={toggleEditMode} edit={edit} />
        {(edit && <UsageForm usage={usage} setEdit={setEdit} />) || (
          <UsageInfo usage={usage} />
        )}
      </>
    </Wrapper>
  );
};

export default UsageData;
