import { Tag, TagLabelType } from './tag';

export enum ExpirationType {
  ACTUAL_PUBLISH_DATE = 'ACTUAL_PUBLISH_DATE',
  ESTIMATED_PUBLISH_DATE = 'ESTIMATED_PUBLISH_DATE',
  UNKNOWN_PUBLISH_DATE = 'UNKNOWN_PUBLISH_DATE',
  UNLIMITED = 'UNLIMITED',
}

export enum UsageStatus {
  AWAITING = 'AWAITING',
  IN_POWER = 'IN_POWER',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export enum ExpirationUnit {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
}

export type UsageTagContainer = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdById: string;
  updatedById: string;
  createdByFullName: string;
  updatedByFullName: string;
  tagId: string;
  tag: Tag;
  usageId: string;
  value: string;
  labelType: TagLabelType.UR_MC | TagLabelType.UR_AREA;
};

export type UsageMediaChannel = UsageTagContainer & {
  labelType: TagLabelType.UR_MC;
};
export type UsageArea = UsageTagContainer & {
  labelType: TagLabelType.UR_AREA;
}

export type DeliveryUsage = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdById: string;
  updatedById: string;
  createdByFullName: string;
  updatedByFullName: string;
  estimatedPublishDate: string;
  actualPublishDate: string;
  orangeReminderDate: string;
  expirationType: ExpirationType;
  expiryDate: string;
  order: number;
  deliveryId: string;
  description: string;
  other: string;
  appliesToAll: boolean;
  isMediaChannelsActive: boolean;
  isAreaActive: boolean;
  isAgreed: boolean;
  mediaChannels: UsageMediaChannel[];
  area: UsageArea[]
  status?: UsageStatus;
  expirationUnit?: ExpirationUnit;
  agreedPublishDate?: string | null;
  expirationLength?: number;
  oldMediaChannels?: string[];
  oldArea?: string[];
};
