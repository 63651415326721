import { baseApi } from './BaseService';
import {
  GetAllDeliveriesResponse,
  GetDeliveryByIdResponse,
} from '../responseTypes/delivery';

const baseUrl = 'admin/deliveries';

export const deliveriesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllDeliveries: build.query<
      GetAllDeliveriesResponse,
      {
        sorting: string;
        order: string;
        limit: number;
        offset: number;
        query?: string;
        type?: string;
        companyId?: string;
      }
    >({
      query: (params) => ({
        url: baseUrl,
        params,
      }),
    }),
    getDeliveryById: build.query<
      GetDeliveryByIdResponse,
      { deliveryId: string }
    >({
      query: ({ deliveryId }) => ({
        url: `${baseUrl}/${deliveryId}`,
      }),
      providesTags: ['Delivery'],
    }),
  }),
});
