import { defaultTheme } from './theme';

const globalStyles = {
  body: {
    backgroundColor: defaultTheme.colors.gray06,
  },
  'input:-webkit-autofill': {
    transition: 'background-color 5000s ease-in-out 0s',
  },
  'input:-webkit-autofill:hover': {
    transition: 'background-color 5000s ease-in-out 0s',
  },
  'input:-webkit-autofill:focus': {
    transition: 'background-color 5000s ease-in-out 0s',
  },
  'input:-webkit-autofill:active': {
    transition: 'background-color 5000s ease-in-out 0s',
  },
  '.js-focus-visible :focus:not([data-focus-visible-added])': {
    outline: 'none',
    boxShadow: 'none',
  },
};

export default globalStyles;
