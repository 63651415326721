import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { baseApi } from './services/BaseService';
import auth from './slices/authSlice';
import profiles from './slices/profilesSlice';
import tags from './slices/tagsSlice';
import deliveries from './slices/deliveriesSlice';
import usages from './slices/usagesSlice';
import templates from './slices/templatesSlice';
import emailNotifications from './slices/emailNotificationsSlice';
import pricingPlans from './slices/pricingPlansSlice';
import signupRequests from './slices/signupRequestsSlice';
import deletionRequests from './slices/deletionRequestsSlice';
import textLabel from './slices/textLabelSlice';
import userRoles from './slices/userRoleSlice';

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  auth,
  profiles,
  tags,
  deliveries,
  usages,
  templates,
  emailNotifications,
  pricingPlans,
  signupRequests,
  deletionRequests,
  textLabel,
  userRoles,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(baseApi.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
