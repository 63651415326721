import React, { useContext } from 'react';
import { Button } from "@chakra-ui/react";
import { StyledHeader } from "./styled";
import { AppContext } from "../../../context/AppContext";
import { HamburgerIcon } from "@chakra-ui/icons";

const Header = () => {
  const { sidebar, setSidebar } = useContext(AppContext);

  const toggleMenu = () => {
    setSidebar(!sidebar)
  }

  return (
    <StyledHeader>
      <Button onClick={toggleMenu}>
        <HamburgerIcon />
      </Button>
    </StyledHeader>
  );
};

export default Header;