import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { TagWithCompany } from '../../shared/types/tag';
import { tagsApi } from '../services/TagsService';

interface ITagsSlice {
  currentLimit: number | null;
  currentOffset: number | null;
  totalEntities: number | 0;
  tags: TagWithCompany[];
}

const initialState: ITagsSlice = {
  currentLimit: 1,
  currentOffset: 10,
  totalEntities: 0,
  tags: [],
};

const slice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      tagsApi.endpoints.getAllTags.matchFulfilled,
      (state: ITagsSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { offset } = args;
        if (offset === 0) return { ...payload, tags: payload.tags };
        return { ...payload, tags: [...state.tags, ...payload.tags] };
      },
    );
    builder.addMatcher(
      tagsApi.endpoints.createTag.matchFulfilled,
      (state: ITagsSlice, { payload: { tag } }) => {
        return {
          ...state,
          tags: [tag, ...state.tags],
        };
      },
    );
    builder.addMatcher(
      tagsApi.endpoints.deleteTagById.matchFulfilled,
      (state: ITagsSlice, { meta }) => {
        const tagId = meta.arg.originalArgs;
        return {
          ...state,
          tags: state.tags.filter((tag) => tag.id !== tagId),
        };
      },
    );
  },
});

export default slice.reducer;

export const selectTags = (state: RootState) => state.tags.tags;
export const selectTagsTotalEntities = (state: RootState) =>
  state.tags.totalEntities;
export const selectTagsHasMore = (state: RootState) => {
  const total = state.tags.totalEntities;
  return total === null || (total > 0 && state.tags.tags.length < total);
};
