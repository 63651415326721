import React from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { useParams } from 'react-router-dom';
import { Flex, Spinner } from "@chakra-ui/react";
import { tagsApi } from "../../store/services/TagsService";
import TagData from "./TagData";

const Tag: React.FC = () => {
  const { tagId } = useParams();
  const { data, isLoading } = tagsApi.useGetTagByIdQuery({
    tagId: tagId || '',
  });

  return (
    <MainLayout>
      {isLoading && (
        <Flex w={'100%'} justifyContent={'center'}>
          <Spinner size="xl" />
        </Flex>
      )}
      {data?.tag && <TagData tag={data.tag} /> }
    </MainLayout>
  );
};

export default Tag;
