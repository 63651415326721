import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SignupRequest } from '../../shared/types/signupRequest';
import { signupRequestsApi } from '../services/SignupRequestsService';

interface ISignupRequestsSlice {
  currentLimit: number | null;
  currentOffset: number | null;
  totalEntities: number | null;
  signupRequests: SignupRequest[];
}

const initialState: ISignupRequestsSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  signupRequests: [],
};

const slice = createSlice({
  name: 'signupRequests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      signupRequestsApi.endpoints.getRequests.matchFulfilled,
      (state: ISignupRequestsSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { offset } = args;
        if (offset === 0)
          return { ...state, ...payload, templates: payload.signupRequests };
        return {
          ...state,
          ...payload,
          signupRequests: [...state.signupRequests, ...payload.signupRequests],
        };
      },
    );
  },
});

export default slice.reducer;

export const selectSignupRequests = (state: RootState) =>
  state.signupRequests.signupRequests;
export const selectTemplatesTotalEntities = (state: RootState) =>
  state.signupRequests.totalEntities;
export const selectSignupRequestsHasMore = (state: RootState) => {
  const total = state.signupRequests.totalEntities;
  return (
    total === null ||
    (total > 0 && state.signupRequests.signupRequests.length < total)
  );
};
