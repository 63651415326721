import { baseApi } from './BaseService';
import {
  GetAllTagsResponse,
  GetTagByIdResponse,
  UpdateTagByIdRequest,
} from '../responseTypes/tags';

const baseUrl = 'admin/tags';

export const tagsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllTags: build.query<
      GetAllTagsResponse,
      {
        limit: number;
        offset: number;
        sorting: string;
        order: string;
        creation?: string;
        labelType?: string;
      }
    >({
      query: (params) => ({
        url: `${baseUrl}/paginated`,
        params: { ...params, label_type: params.labelType },
      }),
    }),
    getTagById: build.query<GetTagByIdResponse, { tagId: string }>({
      query: ({ tagId }) => ({
        url: `${baseUrl}/${tagId}`,
      }),
      providesTags: ['Tag'],
    }),
    createTag: build.mutation<GetTagByIdResponse, UpdateTagByIdRequest>({
      query: (data) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Tag'],
    }),
    updateTagById: build.mutation<
      GetTagByIdResponse,
      { tagId: string; data: UpdateTagByIdRequest }
    >({
      query: ({ tagId, data }) => ({
        url: `${baseUrl}/${tagId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Tag'],
    }),
    deleteTagById: build.mutation<string, string>({
      query: (tagId) => ({
        url: `${baseUrl}/${tagId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tag'],
    }),
  }),
});
