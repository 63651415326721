import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { DeletionRequest } from '../../shared/types/deletionRequests';
import { deletionRequestsApi } from '../services/DeletionRequestsService';

interface IDeletionRequestsSlice {
  currentLimit: number | null;
  currentOffset: number | null;
  totalEntities: number | null;
  deletionRequests: DeletionRequest[];
}

const initialState: IDeletionRequestsSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  deletionRequests: [],
};

const slice = createSlice({
  name: 'deletionRequests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      deletionRequestsApi.endpoints.getDeletionRequests.matchFulfilled,
      (state: IDeletionRequestsSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { offset } = args;
        if (offset === 0)
          return {
            ...state,
            ...payload,
            deletionRequests: payload.requests,
          };
        return {
          ...state,
          ...payload,
          deletionRequests: [...state.deletionRequests, ...payload.requests],
        };
      },
    );
    builder.addMatcher(
      deletionRequestsApi.endpoints.processDeletionRequest.matchFulfilled,
      (state: IDeletionRequestsSlice, { payload: { request } }) => {
        return {
          ...state,
          deletionRequests: state?.deletionRequests?.map(
            (req) => (req.id === request.id && request) || req,
          ),
        };
      },
    );
  },
});

export default slice.reducer;

export const selectDeletionRequests = (state: RootState) =>
  state.deletionRequests.deletionRequests;
export const selectDeletionRequestsTotalEntities = (state: RootState) =>
  state.deletionRequests.totalEntities;
export const selectDeletionRequestsHasMore = (state: RootState) => {
  const total = state.deletionRequests.totalEntities;
  return (
    total === null ||
    (total > 0 && state.deletionRequests.deletionRequests?.length < total)
  );
};
