import theme from "../styles/theme";

export const themeValue = (namespace: string) => (placeholder: string) => {
  const {
    // @ts-ignore
    [namespace]: { [placeholder]: result },
  } = theme;

  return result;
};

export const opacityToHex = (opacity: number) =>
  Math.round(opacity * 255)
    .toString(16)
    .padStart(2, '0');

export const hexColorOpacity = (hexColor: string, opacity = 1) => hexColor.concat(opacityToHex(opacity));

export const themeColorPicker =
  (themeColor: string, opacity = 1) => {
    const {
      // @ts-ignore
      colors: { [themeColor]: result },
    } = theme;
    return hexColorOpacity(result, opacity);
  };
