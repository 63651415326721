import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { profilesApi } from '../../store/services/ProfilesService';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormInput from '../../shared/components/FormInput';
import FormSelect from '../../shared/components/FormSelect';
import { pricingPlansApi } from '../../store/services/PricingPlansService';
import { useSelector } from 'react-redux';
import { selectPricingPlans } from '../../store/slices/pricingPlansSlice';
import { StyledInputsWrapper } from './styled';
import { SORTING_CREATED_AT } from '../pricingPlans/constants';
import { ASC } from '../../shared/constants';

type FormFields = { email: string; tariffPlanId: string };

const InviteUser = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inviteUser, { isLoading, isSuccess, isError }] =
    profilesApi.useInviteUserMutation();
  const toast = useToast();
  const [trigger] = pricingPlansApi.useLazyGetPlansQuery();
  const plans = useSelector(selectPricingPlans);

  const { register, handleSubmit, reset } = useForm<FormFields>({
    defaultValues: {
      email: '',
      tariffPlanId: '',
    },
  });

  useEffect(() => {
    trigger({
      limit: 20,
      offset: 0,
      sorting: SORTING_CREATED_AT,
      order: ASC,
      query: '',
    });
  }, []);

  const plansOptions = useMemo(() => {
    return plans
      .filter((plan) => !plan.isDeleted && plan.isActive)
      .map((plan) => ({ value: plan.id, label: plan.name }));
  }, [plans]);

  useEffect(() => {
    const defaultPlan = plans.find((plan) => plan.isDefault);
    if (defaultPlan) reset({ tariffPlanId: defaultPlan.id });
  }, [plans, isOpen]);

  const sendInvite: SubmitHandler<FormFields> = async (args) => {
    const { email, tariffPlanId } = args;
    await inviteUser({ email, tariffPlanId });
  };

  const closeAndClear = () => {
    onClose();
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Success.',
        description: 'Invitation has been sent.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      closeAndClear();
    }
    if (isError) {
      toast({
        title: 'Error.',
        description: 'Something went wrong. Check email validity',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }, [isSuccess, isError]);

  return (
    <Flex w={'100%'} justifyContent={'end'}>
      <Button
        onClick={onOpen}
        fontWeight={400}
        colorScheme={'blue'}
        w={'max-content'}
      >
        Invite user
      </Button>

      <Modal onClose={closeAndClear} size={'md'} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent mx={'1rem'}>
          <form onSubmit={handleSubmit(sendInvite)}>
            <ModalHeader>Invite user</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <StyledInputsWrapper>
                <FormInput ph={'Email'} {...{ register, name: 'email' }} />
                <FormSelect
                  ph={'Tariff plan'}
                  {...{ register, name: 'tariffPlanId' }}
                  labeledOptions={plansOptions}
                />
              </StyledInputsWrapper>
            </ModalBody>
            <ModalFooter>
              <Flex w={'100%'} justifyContent={'center'}>
                <Button
                  type={'button'}
                  onClick={closeAndClear}
                  mr={2}
                  colorScheme={'red'}
                >
                  Close
                </Button>
                <Button
                  type={'submit'}
                  colorScheme={'green'}
                  isLoading={isLoading}
                >
                  Send
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default InviteUser;
